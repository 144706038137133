import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from '../styles';

const Collapse = ({ collapseHeader, children, isParent = false }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion expanded={expanded} sx={isParent ? styles.collapseParent : styles.collapseChild}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-expanded={expanded}
        aria-label="show more"
        sx={styles.accordionSummary}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? 'Hide ' : 'Show '}
        {collapseHeader}
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetails}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default Collapse;
