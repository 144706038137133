// Dependencies
import React from 'react';
import clsx from 'clsx';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const NutritionRow = ({ viewableMeals, classes }) => {
  return (
    <TableRow>
      <TableCell className={classes.iconCell} />
      <TableCell className={classes.rowLabel}>Nutrition</TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`nutrition.${meal.versionId}`}
          className={clsx(meal.inactive && classes.greyed)}
        >
          <div>
            <span className={classes.listLabel}>Fat</span>
            <span className={classes.listContent}>{meal.nutrition.fat}</span>
          </div>
          <div>
            <span className={classes.listLabel}>Carbs</span>
            <span className={classes.listContent}>{meal.nutrition.carbs}</span>
          </div>
          <div>
            <span className={classes.listLabel}>Sodium</span>
            <span className={classes.listContent}>{meal.nutrition.sodium}</span>
          </div>
          <div>
            <span className={classes.listLabel}>Protein</span>
            <span className={classes.listContent}>{meal.nutrition.protein}</span>
          </div>
          <div>
            <span className={classes.listLabel}>Calories</span>
            <span className={classes.listContent}>{meal.nutrition.calories}</span>
          </div>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default NutritionRow;
