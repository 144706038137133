import React from 'react';
import NumberFormat from 'react-number-format';

// Example: https://material-ui.com/components/text-fields/#FormattedInputs.js

// This is a component based on the react-number-format library that has been tuned for use in our app

const NumberFormatter = React.forwardRef(
  ({ className, disabled, id, name, onChange, value = 0 }, ref) => {
    const handleFocus = (event) => event.target.select();
    return (
      <NumberFormat
        allowNegative={false}
        className={className}
        decimalScale={2}
        defaultValue={0}
        disabled={disabled}
        fixedDecimalScale
        getInputRef={ref}
        name={name}
        onChange={onChange}
        onFocus={handleFocus}
        prefix="$"
        value={value}
        id={id}
      />
    );
  }
);

NumberFormatter.displayName = 'NumberFormatter';

export default NumberFormatter;
