import React, { useContext } from 'react';

import { Button } from '@mui/material';
import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';

const SaveOrCancelButtons = ({
  behavioralDiscount = {},
  behavioralDiscountId = '',
  classes,
  handleFormSave,
}) => {
  const { formState, actions } = useContext(BehavioralDiscountFormContext);

  return (
    <div className={classes.saveOrCancelSection}>
      <span className={classes.buttonContainer}>
        <Button
          color="primary"
          onClick={() =>
            behavioralDiscountId ? [actions.revertForm(behavioralDiscount)] : [actions.resetForm()]
          }
        >
          {behavioralDiscountId ? 'undo changes' : 'clear form'}
        </Button>
        <Button
          disabled={
            !!(
              !formState.name ||
              !formState.promotionText ||
              !formState.description ||
              (!formState.valueCents &&
                !formState.valuePercent &&
                !(formState.discountTarget === 'pickable_item')) ||
              (formState.discountDuration === 'menu' && !formState.menuEndsAt) ||
              (formState.triggerType === 'meal' && !formState.triggerMenuSelectionIds.length > 0) ||
              !formState.disclaimer ||
              formState.formValidation.posting ||
              formState.formValidation.errors
            )
          }
          variant="contained"
          color="primary"
          onClick={handleFormSave}
        >
          Save
        </Button>
      </span>
    </div>
  );
};

export default SaveOrCancelButtons;
