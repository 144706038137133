import React from 'react';

import BannerSelector from './BannerSelector';
import BannerPreview from './BannerPreview';

const BannerSection = ({ classes, previewImage = null, filename = '' }) => {
  return (
    <div className={classes.root}>
      <h6 className={classes.header}>Banner Display</h6>
      <BannerPreview previewImage={previewImage} />
      <BannerSelector filename={filename} previewImage={previewImage} />
    </div>
  );
};

export default BannerSection;
