import {
  FVC_CHANGE_TYPE_ADD_SUBTRACT_QUANTITY,
  FVC_OPERATION_TYPES,
  FVC_VOLUME_TYPES,
  FVC_BALANCING_TYPES_DEFAULT,
} from 'lib/constants';
import React, { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import GeographyInput from '../GeographyInput';
import MealSelector from '../MealSelector';
import PackingFacilitySelector from '../PackingFacilitySelector';
import BalancerSelector from '../BalancerSelector';

const AddSubtractQuantity = ({ onChange, value = null }) => {
  const [shipDate, setShipDate] = useState(value?.shipDate);
  const [packingFacility, setPackingFacility] = useState(value?.packingFacility);
  const [geography, setGeography] = useState(value?.geography || []);
  const [meal, setMeal] = useState(value?.meal);
  const [selectedOperation, setOperation] = useState(value?.selectedOperation);
  const [selectedVolumeType, setVolumeType] = useState(value?.selectedVolumeType);
  const [quantity, setQuantity] = useState(value?.quantity);
  const [balancer, setBalancer] = useState(value?.balancer || FVC_BALANCING_TYPES_DEFAULT);
  const [explanation, setExplanation] = useState(value?.explanation || '');

  const geographyError = geography.some((g) => g.errorMessage);

  useEffect(() => {
    const isFormReady =
      shipDate &&
      packingFacility &&
      meal &&
      balancer &&
      explanation &&
      selectedOperation &&
      selectedVolumeType &&
      quantity !== null &&
      !geographyError;
    const changeRequestData = isFormReady
      ? {
          changeType: FVC_CHANGE_TYPE_ADD_SUBTRACT_QUANTITY,
          shipDate,
          packingFacility,
          geography,
          meal,
          selectedOperation,
          selectedVolumeType,
          quantity,
          balancer,
          explanation,
        }
      : value;
    onChange(changeRequestData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shipDate,
    packingFacility,
    geography,
    meal,
    quantity,
    balancer,
    explanation,
    selectedOperation,
    selectedVolumeType,
    geographyError,
    onChange,
  ]);

  useEffect(() => {
    setShipDate(value?.shipDate);
    setPackingFacility(value?.packingFacility);
    setGeography(value?.geography || []);
    setMeal(value?.meal);
    setQuantity(value?.quantity);
    setOperation(value?.selectedOperation);
    setVolumeType(value?.selectedVolumeType);
    setBalancer(value?.balancer || FVC_BALANCING_TYPES_DEFAULT);
    setExplanation(value?.explanation || '');
  }, [value]);

  const handleQuantityChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setQuantity(parseInt(e.target.value, 10));
    }
  };

  return (
    <>
      <TextField
        id="shipDate"
        label="On Production Day"
        type="date"
        value={shipDate || ''}
        onChange={(e) => setShipDate(e.target.value)}
        fullWidth
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <PackingFacilitySelector
        id="packingFacility"
        value={packingFacility}
        onChange={setPackingFacility}
      />
      <MealSelector
        id="meal"
        packingFacility={packingFacility}
        shipDate={shipDate}
        geography={geography}
        value={meal}
        onChange={setMeal}
      />
      <GeographyInput
        id="geography"
        packingFacility={packingFacility}
        shipDate={shipDate}
        meal={meal}
        value={geography}
        onChange={setGeography}
      />
      <Divider />
      <ToggleButtonGroup exclusive size="large">
        {FVC_OPERATION_TYPES.map((operation) => {
          return (
            <ToggleButton
              onClick={() => setOperation(operation)}
              selected={selectedOperation && operation.name === selectedOperation.name}
              key={operation.value}
              value={operation}
            >
              {operation.name}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <TextField
        id="quantity"
        label="Quantity"
        type="text"
        value={quantity ?? ''}
        onChange={(e) => handleQuantityChange(e)}
        variant="outlined"
        fullWidth
      />
      <ToggleButtonGroup exclusive size="large">
        {FVC_VOLUME_TYPES.map((vt) => {
          return (
            <ToggleButton
              onClick={() => setVolumeType(vt)}
              selected={selectedVolumeType && vt.value === selectedVolumeType.value}
              key={vt.value}
              value={vt}
            >
              {vt.display_name}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <BalancerSelector id="balancer" value={balancer} onChange={setBalancer} />
      <Divider />
      <TextField
        id="explanation"
        label="Explanation"
        value={explanation}
        onChange={(e) => setExplanation(e.target.value)}
        multiline
        rows={4}
        variant="outlined"
        fullWidth
      />
    </>
  );
};

export default AddSubtractQuantity;
