import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Material Components
import Card from '@mui/material/Card';

import Dropdown from '../../shared/Dropdown';
import SectionHeader from '../shared/SectionHeader';

const ProductType = ({ classes, formState, handleFormState }) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);

  const selectProductTypes = useMemo(() => {
    return formOptions.productTypes.map((productType) => ({
      label: productType.name,
      value: productType.id,
      key: productType.id,
    }));
  }, [formOptions.productTypes]);

  const handleProductTypeChange = (event) => {
    const { value } = event.target;
    handleFormState({ target: { name: 'productTypeId', value } });
  };

  return (
    <Card className={classes.productTypeCard}>
      <SectionHeader text="Product Type" />

      <Dropdown
        label="Product Type"
        name="productTypeId"
        onChange={handleProductTypeChange}
        options={selectProductTypes}
        value={formState.productTypeId}
        required
      />
    </Card>
  );
};

export default ProductType;
