// Dependencies
import React from 'react';

// Material Components
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { USER_ERROR, DELIVERY_ERROR } from 'lib/constants';

const FormTypeButton = ({
  classes,
  incidentType,
  handleTypeChange,
  disableDeliveryError = false,
}) => {
  const isDeliveryError = incidentType === DELIVERY_ERROR;
  const isUserError = incidentType === USER_ERROR;

  const { buttonActive, buttonInactive } = classes;
  const deliveryButtonClass = isDeliveryError ? buttonActive : buttonInactive;
  const userButtonClass = isUserError ? buttonActive : buttonInactive;

  return (
    <ButtonGroup className={classes.buttonGroup}>
      <Button
        name="incidentType"
        value={DELIVERY_ERROR}
        className={deliveryButtonClass}
        onClick={handleTypeChange}
        disabled={disableDeliveryError}
      >
        Delivery Error
      </Button>
      <Button
        name="incidentType"
        value={USER_ERROR}
        className={userButtonClass}
        onClick={handleTypeChange}
      >
        User Error
      </Button>
    </ButtonGroup>
  );
};

export default FormTypeButton;
