import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CircularProgress, Container, Paper, Typography } from '@mui/material';

import { fetchGridData } from 'redux/menugrid/menus/actions';

import MenuSelectionsContainer from '../MenuSelectionsContainer';
import ErrorDialog from '../ErrorDialog';
import SnackBar from '../SnackBar';
import CsvButton from '../CsvButton';
import CsvMessage from '../CsvMessage';

const MenusApp = ({ menus, menuType, displayGroup, stagingGroup, classes }) => {
  const dispatch = useDispatch();

  const { error, fetching } = useSelector((state) => state.menugrid.menus);
  const quarterStart = useSelector((state) => state.menugrid.quarter.start);
  const csvStatus = useSelector((state) => state.menugrid.csvs.message);

  useEffect(() => {
    dispatch(fetchGridData(quarterStart, displayGroup));
  }, [dispatch, quarterStart, displayGroup]);

  if (fetching) {
    return (
      <Container className={classes.loading}>
        <CircularProgress size={200} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container className={classes.loading}>
        <Typography color="primary">Error: Menus not found</Typography>
      </Container>
    );
  }

  if (menus.length === 0) {
    return (
      <Container className={classes.loading}>
        <Typography color="primary">No menus exist for this quarter</Typography>
      </Container>
    );
  }

  return (
    <div>
      <ErrorDialog displayGroup={displayGroup} />

      <Paper className={classes.root} elevation={0}>
        <MenuSelectionsContainer
          menus={menus}
          menuType={menuType}
          displayGroup={displayGroup}
          stagingGroup={stagingGroup}
        />
      </Paper>

      <SnackBar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />

      <div className={classes.csvRow}>
        <CsvButton sheetName="production-sheet" displayGroup={displayGroup} />
        |
        <CsvButton sheetName="staging-group-production-sheet" displayGroup={displayGroup} />
      </div>
      <CsvMessage csvStatus={csvStatus} />
    </div>
  );
};

export default MenusApp;
