import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { broadcastScrollSizeToParent } from 'lib/utils';

// Material Components
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';

import { cloneDeep } from 'lodash';

import BeforeYouCookInstruction from './BeforeYouCookInstruction';
import CustomizeItStep from './CustomizeItStep';
import RecipeStep from './RecipeStep';
import SaveChangesButton from '../shared/SaveChangesButton';
import SectionHeader from '../shared/SectionHeader';
import Utensil from './Utensil';

const RecipeStepsTab = ({ classes, setDirty, dirty = false }) => {
  const { meal } = useSelector((state) => state.mealEditForm.meal);

  const mealDup = cloneDeep(meal);

  const [formState, setFormState] = useState({
    utensils: mealDup.utensils,
    beforeYouCookInstructions: mealDup.beforeYouCookInstructions,
    pdf: null,
    recipeSteps: mealDup.recipeSteps,
    customizeItStep: mealDup.customizeItStep,
  });

  useEffect(() => {
    const clonedMeal = cloneDeep(meal);
    setFormState({
      utensils: clonedMeal.utensils,
      beforeYouCookInstructions: clonedMeal.beforeYouCookInstructions,
      pdf: null,
      recipeSteps: clonedMeal.recipeSteps,
      customizeItStep: clonedMeal.customizeItStep,
    });
  }, [meal]);

  // If being used in an iframe, set the width and height
  // so we don't have a double scrollbar situation.
  useEffect(() => {
    broadcastScrollSizeToParent();
  }, []);

  const handleFormState = (attribute, value) => {
    if (!dirty) {
      setDirty(true);
    }
    setFormState({ ...formState, [attribute]: value });
  };

  const onFileChange = (event) => {
    const { name } = event.target;
    const file = event.target.files[0];

    handleFormState(name, file);
  };

  const addBeforeYouCookInstruction = () => {
    const newInstruction = {};
    const newInstructionsState = formState.beforeYouCookInstructions;
    newInstructionsState.push(newInstruction);
    setFormState({ ...formState, beforeYouCookInstructions: newInstructionsState });
  };

  const renderBeforeYouCookInstructions = () => {
    return formState.beforeYouCookInstructions.map((byci, index) => {
      const key = `byci-${index}`;
      return (
        <BeforeYouCookInstruction
          key={key}
          classes={classes}
          formState={formState}
          handleFormState={handleFormState}
          index={index}
          instruction={byci}
        />
      );
    });
  };

  const renderCustomizeItStep = () => {
    if (formState.customizeItStep || meal.hasOptions) {
      return (
        <CustomizeItStep
          classes={classes}
          customizeItStep={formState.customizeItStep}
          handleFormState={handleFormState}
        />
      );
    }
    return null;
  };

  const addRecipeStep = () => {
    const newRecipeStep = {};
    const newRecipeStepsState = formState.recipeSteps;
    newRecipeStepsState.push(newRecipeStep);
    setFormState({ ...formState, recipeSteps: newRecipeStepsState });
  };

  const renderRecipeSteps = () => {
    return formState.recipeSteps.map((step, index) => {
      const key = `recipe-step-${index}`;
      return (
        <RecipeStep
          key={key}
          classes={classes}
          formState={formState}
          handleFormState={handleFormState}
          index={index}
          recipeStep={step}
          ingredients={meal.ingredientAssignments}
        />
      );
    });
  };

  const addUtensil = () => {
    const newUtensil = { quantity: 1 };
    const newUtensilsState = formState.utensils;
    newUtensilsState.push(newUtensil);
    setFormState({ ...formState, utensils: newUtensilsState });
  };

  const renderUtensils = () => {
    return formState.utensils.map((utensil, index) => {
      const key = `utensil-${index}`;
      return (
        <Utensil
          key={key}
          classes={classes}
          formState={formState}
          handleFormState={handleFormState}
          index={index}
          utensil={utensil}
        />
      );
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <SectionHeader text="PDF" />
          <span className={classes.fileName}>
            {formState.pdf ? formState.pdf.name : 'No File Selected'}
          </span>
          {/* eslint-disable-next-line */}
          <label htmlFor="pdf-upload">
            <Input
              accept="application/pdf"
              id="pdf-upload"
              name="pdf"
              type="file"
              className={classes.input}
              onChange={onFileChange}
            />
            <Button variant="outlined" component="span" className={classes.outlinedButton}>
              Choose File
            </Button>
          </label>
          <p>{meal.pdfAuditInfo}</p>
        </Paper>

        <Paper className={classes.paperFlexContainer}>
          <div className={classes.headerWrapper}>
            <SectionHeader text="Before/While You Cook" />
          </div>
          <Grid className={classes.flexContainer}>{renderBeforeYouCookInstructions()}</Grid>
          <Button
            variant="outlined"
            className={classes.addButton}
            onClick={addBeforeYouCookInstruction}
          >
            <AddIcon className={classes.addIcon} />
            Before You Cook Instruction
          </Button>
        </Paper>

        <Paper className={classes.paperFlexContainer}>
          <div className={classes.headerWrapper}>
            <SectionHeader text="Equipment" />
          </div>
          <Grid className={classes.flexContainer}>{renderUtensils()}</Grid>
          <Button variant="outlined" className={classes.addButton} onClick={addUtensil}>
            <AddIcon className={classes.addIcon} />
            Equipment
          </Button>
        </Paper>

        {renderCustomizeItStep()}

        <Paper className={classes.paperFlexContainer}>
          <div className={classes.headerWrapper}>
            <SectionHeader text="Recipe Steps" />
          </div>
          <Grid className={classes.flexContainer}>{renderRecipeSteps()}</Grid>
          <Button variant="outlined" className={classes.addButton} onClick={addRecipeStep}>
            <AddIcon className={classes.addIcon} />
            Step
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <SaveChangesButton mealParams={formState} mealId={meal.id} saveRecipeSteps />
      </Grid>
    </>
  );
};

export default RecipeStepsTab;
