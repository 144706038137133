import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Alert, Button, Grid, Modal, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { broadcastScrollTopToParent, cleanArray } from 'lib/utils';
import { getFormOptions } from 'redux/mealEditForm/actions';
import { getMeal, clearSuccessAndErrorMessages } from 'redux/mealEditForm/meal/actions';

import { FeatureFlag, Loading } from 'components/shared';
import PresenceIndicator from 'components/shared/Presence/PresenceIndicator';
import BasicsTab from './BasicsTab';
import CookingFactsTab from './CookingFactsTab';
import DietaryTab from './DietaryTab';
import IngredientsTab from './IngredientsTab';
import MealClassificationTab from './MealClassificationTab';
import MediaTab from './MediaTab';
import RecipeCategoriesTab from './RecipeCategoriesTab';
import RecipeStepsTab from './RecipeStepsTab';
import ReplacementsTab from './ReplacementsTab';
import MealLabelPdfsTab from './MealLabelPdfsTab';

const MealEditForm = ({ classes }) => {
  const error = useSelector(
    (state) => state.mealEditForm.meal.error || state.mealEditForm.formOptions.error
  );
  const errorMessages = useSelector((state) =>
    cleanArray([state.mealEditForm.meal.errorMessage, state.mealEditForm.formOptions.errorMessage])
  );
  const fetching = useSelector(
    (state) => state.mealEditForm.meal.fetching || state.mealEditForm.formOptions.fetching
  );
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);
  const { meal, success } = useSelector((state) => state.mealEditForm.meal);
  const successMessages = useSelector((state) =>
    cleanArray([state.mealEditForm.meal.successMessage])
  );

  const dispatch = useDispatch();

  const { mealId } = useParams();

  const [currentTab, setCurrentTab] = useState(0);
  const [nextTab, setNextTab] = useState(0);
  const [dirty, setDirty] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const tabs = {
    0: BasicsTab,
    1: RecipeCategoriesTab,
    2: MediaTab,
    3: CookingFactsTab,
    4: DietaryTab,
    5: IngredientsTab,
    6: ReplacementsTab,
    7: RecipeStepsTab,
    8: MealClassificationTab,
    9: MealLabelPdfsTab,
  };

  const handleSetCurrentTab = (_event, newTabValue) => {
    if (dirty) {
      setModalOpen(true);
      setNextTab(newTabValue);
    } else if (newTabValue !== null) {
      dispatch(getMeal(mealId));
      setCurrentTab(newTabValue);
    }
  };

  const handleDiscardChanges = () => {
    setModalOpen(false);
    setDirty(false);
    dispatch(getMeal(mealId));
    setCurrentTab(nextTab);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const renderTabContent = () => {
    const TabComponent = tabs[currentTab];
    return meal && formOptions ? <TabComponent dirty={dirty} setDirty={setDirty} /> : null;
  };

  const renderErrorMessages = () => {
    return errorMessages.map((message) => {
      return (
        <Alert key={message} severity="error" variant="filled">
          {message}
        </Alert>
      );
    });
  };

  const renderSuccessMessages = () => {
    return successMessages.map((message) => {
      return (
        <Alert key={message} severity="success" variant="filled">
          {message}{' '}
          <a className={classes.viewMealLink} href={meal.adminUrl} target="_parent">
            View Meal
          </a>
        </Alert>
      );
    });
  };

  const renderLiveMealAlert = () => {
    if (meal.onLiveMenu) {
      return (
        <Alert key="Live Meal" severity="warning" variant="filled">
          This meal is on a live menu. Use caution when editing.
        </Alert>
      );
    }
    return null;
  };

  const renderModal = () => {
    return (
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Paper className={classes.modal}>
          <h3>Be sure to save your changes before switching to another tab.</h3>
          <div className={classes.buttonRow}>
            <Button color="primary" onClick={handleCloseModal} variant="outlined">
              OK
            </Button>
            <Button color="secondary" onClick={handleDiscardChanges} variant="outlined">
              Discard Changes
            </Button>
          </div>
        </Paper>
      </Modal>
    );
  };

  const renderForm = () => {
    return (
      <Grid container spacing={3} className={classes.container}>
        <PresenceIndicator channelName={`${mealId}-meal-edit-form`} />
        <Grid item xs={12}>
          <ToggleButtonGroup
            color="primary"
            exclusive
            onChange={handleSetCurrentTab}
            value={currentTab}
          >
            <ToggleButton value={0}>The Basics</ToggleButton>
            <ToggleButton value={1}>Recipe Categories</ToggleButton>
            <ToggleButton value={2}>Media</ToggleButton>
            <ToggleButton value={3}>Cooking Facts</ToggleButton>
            <ToggleButton value={4}>Dietary</ToggleButton>
            <ToggleButton data-testid="ingredients-tab" value={5}>
              Ingredients
            </ToggleButton>
            <ToggleButton value={6}>Replacements</ToggleButton>
            <ToggleButton value={7}>Recipe Steps</ToggleButton>
            <ToggleButton value={8}>Meal Classification</ToggleButton>
            <FeatureFlag flag="meal_label_pdf_tab">
              <ToggleButton value={9}>Meal Label PDFs</ToggleButton>
            </FeatureFlag>
          </ToggleButtonGroup>
        </Grid>

        {fetching ? (
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Loading />
          </Grid>
        ) : (
          renderTabContent()
        )}
      </Grid>
    );
  };

  useEffect(() => {
    if (success || error) {
      setDirty(false);
      broadcastScrollTopToParent();
    }
  }, [success, error]);

  useEffect(() => {
    if (success) {
      dispatch(getMeal(mealId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    if (meal === null) {
      dispatch(getMeal(mealId));
    }
  }, [meal, mealId, dispatch]);

  useEffect(() => {
    if (formOptions === null) {
      dispatch(getFormOptions());
    }
  }, [formOptions, dispatch]);

  useEffect(() => {
    dispatch(clearSuccessAndErrorMessages());
  }, [currentTab, dispatch]);

  return (
    <>
      {meal && renderLiveMealAlert()}
      {success && renderSuccessMessages()}
      {error && renderErrorMessages()}
      {renderModal()}
      {renderForm()}
    </>
  );
};

export default MealEditForm;
