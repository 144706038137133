import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Skeleton from '@mui/material/Skeleton';

const LoadingMenu = ({ classes, numberOfTables, rowsPerTable }) =>
  [...Array(numberOfTables)].map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Paper key={index} className={classes.assortmentCard} square elevation={0} border={1}>
      <Toolbar>
        <div>
          <span>
            <Skeleton variant="rectangular" width={150} height={40} />
          </span>
        </div>
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell size="small">
              <Skeleton variant="text" width={100} height={25} />
            </TableCell>
            <TableCell size="small">
              <Skeleton variant="text" width={60} height={25} />
            </TableCell>
            <TableCell size="small">
              <Skeleton variant="text" width={60} height={25} />
            </TableCell>
            <TableCell size="small">
              <Skeleton variant="text" width={60} height={25} />
            </TableCell>
            <TableCell size="small">
              <Skeleton variant="text" width={60} height={25} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(rowsPerTable)].map((rowItem, rowIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={rowIndex}>
              <TableCell size="small" component="th" scope="row">
                <Skeleton variant="text" width={300} height={25} />
              </TableCell>
              <TableCell size="small">
                <Skeleton variant="text" height={20} />
              </TableCell>
              <TableCell size="small">
                <Skeleton variant="text" height={20} />
              </TableCell>
              <TableCell size="small">
                <Skeleton variant="text" height={20} />
              </TableCell>
              <TableCell size="small">
                <Skeleton variant="text" height={20} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  ));

export default LoadingMenu;
