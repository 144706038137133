import React from 'react';

import { centsToDollarString } from 'lib/helpers/money';

// Components
import Paper from '@mui/material/Paper';

const MealsAndCharges = ({ incidentable, classes }) => {
  return (
    <>
      <Paper className={classes.root} variant="outlined">
        <div className={classes.header}>Basket And Charges</div>
        {incidentable.mealSelections.map((mealSelection) => {
          return (
            <MealItem mealSelection={mealSelection} classes={classes} key={mealSelection.title} />
          );
        })}
        <hr className={classes.divider} />
        <div>
          {incidentable.receipt.map((charge) => {
            return <ChargeItem charge={charge} classes={classes} key={charge.title} />;
          })}
        </div>
        <hr className={classes.divider} />
        <div>
          {incidentable.charges.map((charge) => {
            return <ChargeItem charge={charge} classes={classes} key={charge.title} />;
          })}
        </div>
      </Paper>

      <Paper className={classes.root} variant="outlined">
        <div className={classes.header}>Past Credits And Refunds</div>
        {incidentable.pastCreditsAndRefunds.map((creditOrRefund) => {
          return (
            <CreditOrRefundItem
              creditOrRefund={creditOrRefund}
              classes={classes}
              key={creditOrRefund.title}
            />
          );
        })}
      </Paper>
    </>
  );
};

const mealOptionsText = (mealOptions) => {
  const optionsList = mealOptions
    .map((mealOptionSelection) => {
      const priceModifier =
        mealOptionSelection.priceModifier > 0
          ? `(+${centsToDollarString(mealOptionSelection.priceModifier)})`
          : '';
      return `${mealOptionSelection.displayName} ${priceModifier}`;
    })
    .join(', ');

  return optionsList ? `${optionsList} -` : '';
};

const MealItem = ({ mealSelection, classes }) => {
  return (
    <div className={classes.mealItem}>
      <div>
        <div className={classes.mealTitle}>{mealSelection.title}</div>
        <div className={classes.mealInfo}>
          {`${centsToDollarString(mealSelection.pricePerUnit)} -
            ${mealOptionsText(mealSelection.mealOptions)}
            ${mealSelection.totalUnits} ${mealSelection.unitName}${
            mealSelection.totalUnits > 1 ? 's' : ''
          }`}
        </div>
      </div>
      {mealSelection.totalDiscountedPrice ? (
        <div className={classes.totals}>
          <div className={classes.totalStrikethrough}>
            {centsToDollarString(mealSelection.totalPrice)}
          </div>
          <div className={classes.totalFixedWidth}>
            {centsToDollarString(mealSelection.totalDiscountedPrice)}
          </div>
        </div>
      ) : (
        <div className={classes.mealTotalPrice}>
          {centsToDollarString(mealSelection.totalPrice)}
        </div>
      )}
    </div>
  );
};

const ChargeItem = ({ charge, classes }) => {
  return (
    <div className={classes.chargeItem}>
      <div className={classes.chargeTitle}>{charge.title}</div>
      {charge.discountedValue ? (
        <div className={classes.totals}>
          <div className={classes.totalStrikethrough}>{centsToDollarString(charge.value)}</div>
          <div className={classes.totalFixedWidth}>
            {centsToDollarString(charge.discountedValue)}
          </div>
        </div>
      ) : (
        <div className={classes.charge}>{centsToDollarString(charge.value)}</div>
      )}
    </div>
  );
};

const CreditOrRefundItem = ({ creditOrRefund, classes }) => {
  return (
    <div className={classes.creditOrRefundItem}>
      <div className={classes.creditOrRefundTitle}>{creditOrRefund.title}</div>
      <div className={classes.creditOrRefund}>{centsToDollarString(creditOrRefund.value)}</div>
    </div>
  );
};

export default MealsAndCharges;
