import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Link from '@mui/material/Link';

import { humanizeDashCase } from 'lib/utils';
import { handleCsvExport } from 'redux/menugrid/csvs/actions';

const CsvButton = ({ sheetName, displayGroup, classes }) => {
  const quarterStart = useSelector((state) => state.menugrid.quarter.start);

  const dispatch = useDispatch();

  const exportCsv = () => {
    dispatch(handleCsvExport({ sheetName, quarterStart, displayGroup }));
  };

  return (
    <div>
      <Link component="button" className={classes.root} onClick={exportCsv} underline="none">
        Export {humanizeDashCase(sheetName)}
      </Link>
    </div>
  );
};

export default CsvButton;
