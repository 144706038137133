import React from 'react';
import { ListBase } from 'react-admin';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import StateCountCards from './StateCountCards';
import CompoundIngredientHubBarChart from './CompoundIngredientHubBarChart';
import CompoundIngredientsList from './CompoundIngredientsList';

const CompoundIngredientDashboard = () => {
  return (
    <ListBase>
      <Container>
        <Stack spacing={2} alignItems="center">
          <StateCountCards />
          <CompoundIngredientHubBarChart />
          <CompoundIngredientsList />
        </Stack>
      </Container>
    </ListBase>
  );
};

export default CompoundIngredientDashboard;
