// Dependencies
import React, { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

// Components
import { Loading } from 'components/shared';
import IncidentGrid from './IncidentGrid';

const IncidentManager = ({ onGetIncidentable, classes, incidentable = null }) => {
  const { incidentableId, incidentableType } = useParams();

  // Contains a hash of formStates. Need the initial 0 key
  // to render the initial single form.
  const [formStates, setFormStates] = useState({ 0: {} });

  const [cancelState, setCancelState] = useState({
    cancel: false,
    reason: '',
    reasonDescription: '',
  });

  // This callback updates a single formState in formStates.
  const updateFormState = useCallback(
    (formId, formState) => {
      setFormStates({ ...formStates, [formId]: formState });
    },
    [formStates, setFormStates]
  );

  useEffect(() => {
    if (incidentable === null) {
      onGetIncidentable(incidentableId, incidentableType);
    }
  }, [onGetIncidentable, incidentable, incidentableId, incidentableType]);

  // Rendering
  if (incidentable === null) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <IncidentGrid
        cancelState={cancelState}
        incidentable={incidentable}
        formStates={formStates}
        setCancelState={setCancelState}
        setFormStates={setFormStates}
        updateFormState={updateFormState}
      />
    </div>
  );
};

export default IncidentManager;
