import React from 'react';
import { Grid } from '@mui/material';
import { ArrayField, SingleFieldList, WithRecord } from 'react-admin';

const FacilityDataList = ({ source, label, dataKey }) => (
  <ArrayField source={source} label={label} sortable={false}>
    <SingleFieldList linkType={false} sx={{ flexWrap: 'nowrap' }}>
      <WithRecord
        render={(record) => (
          <Grid container direction="row">
            <Grid item>
              <span className="primaryText">{record.packingFacility}:</span>
            </Grid>
            <Grid item>
              <span className="secondaryText">{record[dataKey]}</span>
            </Grid>
          </Grid>
        )}
      />
    </SingleFieldList>
  </ArrayField>
);

export default FacilityDataList;
