import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { forLifeText } from 'lib/helpers/behavioralDiscountTool/defaultTextHelper';
import { range } from 'lib/helpers/arrays';
import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';
import { FeatureFlag } from 'components/shared';

const BaseAttributeSectionUpdated = ({
  classes,
  handleServiceTypeChange,
  handleDiscountFrequencyChange,
  onFirstMenuChange,
  userCount,
}) => {
  const { formState, actions } = useContext(BehavioralDiscountFormContext);
  const [showMaxRedemptions, setShowMaxRedemptions] = useState(false);

  useEffect(() => {
    if (formState.maxRedemptions) {
      setShowMaxRedemptions(true);
    } else {
      setShowMaxRedemptions(false);
    }
  }, [formState.maxRedemptions, actions]);

  const formattedMenu = (endsAt) => {
    return new Date(endsAt).toDateString();
  };

  const maxRedemptionCeiling = () => {
    let maxInput;
    if (formState.durationInWeeks === forLifeText || formState.durationInWeeks >= 10) {
      maxInput = 10;
    } else {
      maxInput = formState.durationInWeeks;
    }
    return maxInput;
  };

  const handleToggleMaxRedemptions = () => {
    if (showMaxRedemptions) {
      actions.setFormState('maxRedemptions', '');
    } else {
      actions.setFormState('maxRedemptions', maxRedemptionCeiling());
    }
  };

  // ensure maxRedemptions does not exceed duration
  const handleDurationChange = (value) => {
    actions.setFormState('durationInWeeks', value);
    if (!showMaxRedemptions || formState.maxRedemptions <= value) return;
    if (value === forLifeText) {
      actions.setFormState('maxRedemptions', 10);
    } else {
      actions.setFormState('maxRedemptions', value);
    }
  };

  const firstChargeMenus = formState.formData.menus;
  const findFirstMenuIndex = firstChargeMenus.findIndex(
    (firstChargeMenu) => firstChargeMenu.ends_at === formState.menuEndsAt
  );
  const lastChargeMenus = firstChargeMenus.slice(findFirstMenuIndex);

  const lastChargeMenuIndex = firstChargeMenus.findIndex(
    (lastMenu) => lastMenu.ends_at === formState.lastMenuEndsAt
  );

  useEffect(() => {
    if (formState.discountDuration === 'menu') {
      const durationValue = lastChargeMenuIndex - findFirstMenuIndex + 1;
      actions.setFormState('durationInWeeks', durationValue);
    } else {
      actions.setFormState('durationInWeeks', formState.durationInWeeks);
    }
  });

  return (
    <div className={classes.root}>
      <h5 className={classes.header}>Create a Behavioral Discount</h5>
      <TextField
        fullWidth
        label="Discount Name"
        variant="outlined"
        value={formState.name}
        onChange={(e) => actions.setFormState('name', e.target.value)}
      />
      <Grid item sm={2} xs={4} className={classes.serviceType}>
        <div className={classes.label}>Service</div>
        <ToggleButtonGroup
          fullWidth
          color="primary"
          value={formState.serviceType}
          exclusive
          onChange={(e) => handleServiceTypeChange(e.target.value)}
          aria-label="service"
          size="small"
          sx={{ width: 1 }}
        >
          <ToggleButton value="core" sx={{ width: 1 / 4 }}>
            CORE
          </ToggleButton>
          <ToggleButton value="family" sx={{ width: 1 / 4 }}>
            FAMILY
          </ToggleButton>
          <ToggleButton value="tempo" sx={{ width: 1 / 4 }}>
            TEMPO
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <FormControl className={classes.radioGroupContainer}>
        <FormLabel id="trigger-type-radio-buttons-group-label">Discount Frequency</FormLabel>
        <RadioGroup
          aria-labelledby="trigger-type-radio-buttons-group-label"
          value={formState.discountDuration}
          onChange={(e) => handleDiscountFrequencyChange(e.target.value)}
        >
          <FeatureFlag flag="behavioral_discount_multiple_menus">
            <div>
              <FormControlLabel value="menu" control={<Radio />} label="Specific Menu(s)" />
              <span className={classes.frequencyRadioHelperText}>
                Apply once to a selected menu week.
              </span>
            </div>
          </FeatureFlag>
          <FeatureFlag flag="behavioral_discount_multiple_menus" whenOff>
            <div>
              <FormControlLabel value="menu" control={<Radio />} label="A menu week" />
              <span className={classes.frequencyRadioHelperText}>
                Apply once to a selected menu week.
              </span>
            </div>
          </FeatureFlag>
          <div>
            <FormControlLabel value="recurring" control={<Radio />} label="Recurring" />
            <span className={classes.frequencyRadioHelperText}>
              Discounts are assigned for a set duration
            </span>
          </div>
        </RadioGroup>
      </FormControl>
      <Divider className={classes.divider} />
      <div className={classes.menusStyle}>
        <div className={classes.firstMenu}>
          <TextField
            fullWidth
            label="First Charge Menu"
            disabled={formState.discountDuration !== 'menu'}
            onChange={(e) => onFirstMenuChange(e.target.value)}
            select
            size="large"
            value={formState.discountDuration === 'menu' ? formState.menuEndsAt || '' : ''}
            variant="outlined"
            className={formState.discountDuration === 'menu' ? classes.halfWidth : classes.hidden}
          >
            {firstChargeMenus.map((menu) => (
              <MenuItem key={menu.ends_at} value={menu.ends_at}>
                {formattedMenu(menu.ends_at)}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={classes.lastMenu}>
          <TextField
            fullWidth
            label="Last Charge Menu"
            disabled={formState.discountDuration !== 'menu'}
            onChange={(e) => {
              actions.setFormState('lastMenuEndsAt', e.target.value);
            }}
            select
            size="large"
            value={formState.discountDuration === 'menu' ? formState.lastMenuEndsAt || '' : ''}
            variant="outlined"
            className={
              formState.discountDuration === 'menu' ? classes.LastChargeMenuField : classes.hidden
            }
          >
            {lastChargeMenus.map((lastMenu) => (
              <MenuItem key={lastMenu.ends_at} value={lastMenu.ends_at}>
                {formattedMenu(lastMenu.ends_at)}
              </MenuItem>
            ))}
          </TextField>
          <div className={classes.recurringFieldsContainer}>
            <TextField
              fullWidth
              label="Duration"
              select
              size="medium"
              value={formState.durationInWeeks}
              variant="outlined"
              className={
                formState.discountDuration === 'menu'
                  ? classes.quarterWidth
                  : classes.lessThanHalfWidth
              }
              onChange={(e) => handleDurationChange(e.target.value)}
              disabled={userCount > 0 || formState.discountDuration === 'menu'}
            >
              {range(1, 13, 1)
                .reverse()
                .concat(forLifeText)
                .map((num) => (
                  <MenuItem key={num} value={num}>
                    {num === forLifeText ? num : `${num} weeks`}
                  </MenuItem>
                ))}
            </TextField>
            <FormControlLabel
              control={
                <Switch checked={showMaxRedemptions} onChange={handleToggleMaxRedemptions} />
              }
              label="Limit the number of redemptions"
              disabled={
                userCount > 0 ||
                (formState.discountDuration === 'menu' &&
                  formState.lastMenuEndsAt === formState.menuEndsAt)
              }
            />
          </div>
        </div>
        {showMaxRedemptions && (
          <TextField
            fullWidth
            label="Max # of Redemptions Possible"
            select
            size="medium"
            value={formState.maxRedemptions || maxRedemptionCeiling()}
            variant="outlined"
            className={classes.halfWidth}
            onChange={(e) => actions.setFormState('maxRedemptions', e.target.value)}
            disabled={userCount > 0}
            required={showMaxRedemptions}
          >
            {range(1, maxRedemptionCeiling(), 1)
              .reverse()
              .map((num) => (
                <MenuItem key={num} value={num}>
                  {num} times
                </MenuItem>
              ))}
          </TextField>
        )}
      </div>
    </div>
  );
};

export default BaseAttributeSectionUpdated;
