import React from 'react';
import NumberFormat from 'react-number-format';

// Example: https://material-ui.com/components/text-fields/#FormattedInputs.js

// This is a component based on the react-number-format library that has been tuned for use in our app

const PercentFormatter = React.forwardRef(({ className, disabled, onChange, value = 0 }, ref) => {
  const handleFocus = (event) => event.target.select();
  return (
    <NumberFormat
      allowNegative={false}
      className={className}
      decimalScale={0}
      defaultValue={0}
      disabled={disabled}
      fixedDecimalScale
      getInputRef={ref}
      onChange={onChange}
      onFocus={handleFocus}
      suffix="%"
      value={value}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === '' || floatValue <= 100;
      }}
    />
  );
});

PercentFormatter.displayName = 'PercentFormatter';

export default PercentFormatter;
