import React from 'react';

import { FormControlLabel, TextField, Checkbox, Container, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './styles';

const AppConfigValue = ({ classes, type, value, environment, updateValue, changed }) => {
  const getStyles = () => {
    let sx = {};
    if (environment === 'production') sx = { ...sx, ...styles.productionLabel };
    if (changed) sx = { ...sx, color: 'green' };
    return sx;
  };

  return type === 'FeatureFlag' ? (
    <Container sx={styles.valueContainer} disableGutters>
      <FormControlLabel
        label={<Typography sx={getStyles()}>{environment}</Typography>}
        control={
          <Checkbox
            inputProps={{ 'aria-label': environment }}
            checked={value}
            onChange={() => updateValue(!value)}
          />
        }
      />
    </Container>
  ) : (
    <Container sx={styles.valueContainer} disableGutters>
      <TextField
        variant="filled"
        label={
          <Typography sx={environment === 'production' ? styles.productionLabel : {}}>
            {environment}
          </Typography>
        }
        size="small"
        fullWidth
        className={classes.input}
        name={environment}
        value={value}
        InputProps={{ className: changed ? 'Mui-focused' : '' }}
        InputLabelProps={{ className: changed ? 'Mui-focused' : '' }}
        sx={changed ? { ...styles.greenInput, ...styles.value } : styles.value}
        onChange={(e) => updateValue(e.target.value)}
      />
    </Container>
  );
};

export default withStyles(styles)(AppConfigValue);
