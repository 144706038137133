import React from 'react';
import { Box, DialogContentText } from '@mui/material';
import styles from '../styles';

const UpdateStateDirections = ({ success }) => {
  return success ? (
    <Box>
      <DialogContentText sx={styles.dialogContentText} component="span">
        You have changed the state of the selected Compound Ingredient. You have also changed the
        state of all other assignable versions to Legacy.
      </DialogContentText>
      <DialogContentText sx={styles.dialogContentBoldText} component="span">
        Would you like to swap the selected Compound Ingredient for any of the versions that have
        been changed to Legacy?
      </DialogContentText>
    </Box>
  ) : (
    <Box>
      <DialogContentText sx={styles.dialogContentText} component="span">
        Other versions of this Compound Ingredient are set to Active or Needs Approval. All must be
        changed to Legacy before changing the state of the selected compound ingredient.
      </DialogContentText>
      <DialogContentText sx={styles.dialogContentBoldText} component="span">
        Would you like to change the state of the selected compound ingredient, and change the state
        of all other versions to Legacy?
      </DialogContentText>
    </Box>
  );
};

export default UpdateStateDirections;
