// Dependecies
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addStagingSelections, toggleAddMealsDialog } from 'redux/menugrid/menus/actions';

// Helpers
import {
  splitStringByNewline,
  stripWhiteSpaceArray,
  arrayEmpty,
  validIds,
} from 'lib/helpers/menugrid/addMealsDialogHelper';

// Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import AddMealsErrorContent from '../AddMealsErrorContent';

const AddMealsDialog = ({ stagingGroup, displayGroup, classes }) => {
  const dispatch = useDispatch();

  // State
  const { openAddMealsDialog } = useSelector((state) => state.menugrid.menus);

  const [idsList, setIdsList] = useState([]);
  const [formError, setFormError] = useState(false);
  const [formHelperText, setFormHelperText] = useState('List all culinary version IDs.');

  // Validation
  const validateForm = () => {
    if (arrayEmpty(idsList)) {
      setFormError(true);
      setFormHelperText('You must submit at least one ID.');
      return false;
    }
    if (!validIds(idsList)) {
      setFormError(true);
      setFormHelperText('Each ID should follow the format xxxxxx.xxx.xx');
      return false;
    }
    setFormError(false);
    setFormHelperText('List all culinary version IDs.');
    return true;
  };

  // Handlers
  const handleOpen = () => {
    dispatch(toggleAddMealsDialog(true));
  };

  const handleClose = () => {
    dispatch(toggleAddMealsDialog(false));
    setIdsList([]);
  };

  const handleIdsListChange = (value) => {
    if (formError) {
      setFormError(false);
      setFormHelperText('List all culinary version IDs.');
      setIdsList([]);
    }
    const splitList = splitStringByNewline(value);
    const strippedList = stripWhiteSpaceArray(splitList);
    setIdsList(strippedList);
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const joinedIdsList = idsList.join(',');
      dispatch(
        addStagingSelections({ stagingGroup, displayGroup, culinaryVersionIds: joinedIdsList })
      );
      setIdsList([]);
    }
  };

  return (
    <div>
      <Button onClick={handleOpen} className={classes.button} variant="outlined" color="primary">
        Add Meals
      </Button>
      <Dialog
        open={openAddMealsDialog}
        onClose={handleClose}
        aria-labelledby="add-meals-dialog-title"
        fullWidth
      >
        <DialogTitle id="add-meals-dialog-title">Add meals to staging</DialogTitle>
        <DialogContent>
          <TextField
            label="Culinary Version IDs"
            multiline
            rows="4"
            variant="outlined"
            fullWidth
            color="primary"
            onChange={(e) => handleIdsListChange(e.target.value)}
            error={formError}
            helperText={formHelperText}
          />
          <AddMealsErrorContent />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add Meals
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddMealsDialog;
