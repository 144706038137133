// Dependencies
import React from 'react';

// Material
import Paper from '@mui/material/Paper';

import SelectVersionsButton from '../SelectVersionsButton';

const SelectVersionsPrompt = ({ activeMeals, inactiveMeals, classes }) => {
  return (
    <div>
      <Paper className={classes.paperBox} elevation={0}>
        <div className={classes.text}>Please select at least one version to start comparing.</div>
        <SelectVersionsButton activeMeals={activeMeals} inactiveMeals={inactiveMeals} />
      </Paper>
    </div>
  );
};

export default SelectVersionsPrompt;
