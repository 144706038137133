import React, { useState, useEffect, useContext } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Container,
  Drawer,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';

const ProductTypeSelector = ({ classes, visible = false }) => {
  const { formState, actions } = useContext(BehavioralDiscountFormContext);

  const [productTypeSelectorOpen, setProductTypeSelectorOpen] = useState(false);
  const [searchProductTypes, setSearchProductTypes] = useState(formState.formData.productTypes);

  const handleClose = () => [
    setProductTypeSelectorOpen(false),
    setSearchProductTypes(formState.formData.productTypes),
  ];

  const handleClick = (productTypeId) => {
    const selectedIndex = formState.rewardProductTypeIds.indexOf(productTypeId);
    let newSelectedIds = [];

    if (selectedIndex === -1) {
      newSelectedIds = newSelectedIds.concat(formState.rewardProductTypeIds, productTypeId);
    } else if (selectedIndex === 0) {
      newSelectedIds = newSelectedIds.concat(formState.rewardProductTypeIds.slice(1));
    } else if (selectedIndex === formState.rewardProductTypeIds.length - 1) {
      newSelectedIds = newSelectedIds.concat(formState.rewardProductTypeIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedIds = newSelectedIds.concat(
        formState.rewardProductTypeIds.slice(0, selectedIndex),
        formState.rewardProductTypeIds.slice(selectedIndex + 1)
      );
    }

    actions.setFormState('rewardProductTypeIds', newSelectedIds);
  };

  const findProductTypeById = (id) => formState.formData.productTypes.find((pt) => pt.id === id);

  const requestSearch = (searchTerm) => {
    setSearchProductTypes(
      formState.formData.productTypes.filter((productType) =>
        productType.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setSearchProductTypes(formState.formData.productTypes);
  }, [formState.formData.productTypes]);

  return (
    <Container
      disableGutters
      maxWidth={false}
      className={visible ? [classes.visible, classes.container] : classes.hidden}
    >
      <h6 className={classes.label}>
        Eligible Product Types{' '}
        <span className={classes.headerCount}>({formState.rewardProductTypeIds.length})</span>
      </h6>
      {formState.rewardProductTypeIds.length !== 0 &&
        formState.formData.productTypes.length !== 0 && (
          <TableContainer>
            <Table>
              <TableBody>
                {formState.rewardProductTypeIds.map((id) => (
                  <TableRow key={id}>
                    <TableCell className={classes.nameCell}>
                      <div className={classes.productTypeName}>{findProductTypeById(id).name}</div>
                    </TableCell>
                    <TableCell align="right" className={classes.trashIconCell}>
                      <IconButton onClick={() => handleClick(id)} size="large">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      <Container disableGutters>
        <Button
          variant="outlined"
          color="primary"
          disabled={
            formState.formData.productTypes === [] || formState.formData.productTypes.length === 0
          }
          onClick={() => setProductTypeSelectorOpen(true)}
          startIcon={<AddIcon className={classes.addIcon} />}
          className={classes.addProductTypesButton}
        >
          Add Product Types
        </Button>
      </Container>
      <Drawer
        anchor="right"
        open={productTypeSelectorOpen}
        classes={{ paper: classes.drawerPaper }}
      >
        <Container key="bd_meal_selector">
          <Grid container direction="column" justifyContent="flex-start" spacing={0} height="100vh">
            <Grid item xs={12} maxHeight="12vh">
              <Box className={classes.titleBar}>
                Select Product Types
                <IconButton onClick={() => handleClose()} className={classes.closeBtn} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search..."
                type="search"
                onInput={(e) => requestSearch(e.target.value)}
                size="small"
              />
            </Grid>
            <Grid item xs={12} maxHeight="81vh" overflow="hidden" sx={{ overflowY: 'auto' }}>
              <TableContainer>
                <Table>
                  <TableBody>
                    {searchProductTypes.map((productType) => (
                      <TableRow
                        key={productType.id}
                        hover
                        onClick={() => handleClick(productType.id)}
                        role="checkbox"
                        aria-checked={formState.rewardProductTypeIds.includes(productType.id)}
                        tabIndex={-1}
                        selected={formState.rewardProductTypeIds.includes(productType.id)}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={formState.rewardProductTypeIds.includes(productType.id)}
                            inputProps={{
                              'aria-labelledby': `title-${productType.id}`,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={`title-${productType.id}`}
                          scope="row"
                          padding="none"
                        >
                          {productType.name}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent="flex-start"
              width="100%"
              minHeight="50px"
              maxHeight="7vh"
            >
              <Button
                onClick={() => handleClose()}
                variant="contained"
                disabled={formState.rewardProductTypeIds.length <= 0}
                sx={{ width: '100%', height: '40px', marginTop: '8px' }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Drawer>
    </Container>
  );
};

export default ProductTypeSelector;
