import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const AppLink = ({ text, classes, url = null, externalUrl = null }) => {
  const paperEl = useRef();
  const [color, setColor] = useState();

  useEffect(() => {
    const {
      dataset: { linkColor },
    } = paperEl.current.closest('.MuiGrid-container');
    setColor(linkColor);
  }, []);

  return (
    <Paper ref={paperEl} elevation={2} className={`${classes.root} bottom-border-${color}`}>
      <Link to={externalUrl || url} target={externalUrl ? '_blank' : null} className={classes.link}>
        <span>{text}</span>
        <ArrowForwardIcon className={classes.arrow} />
      </Link>
    </Paper>
  );
};

export default AppLink;
