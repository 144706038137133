import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postMealLabelPdfs } from 'redux/mealEditForm/meal/actions';

// Material Components
import Grid from '@mui/material/Grid';

import { Form, SaveButton } from 'react-admin';
import { Loading } from 'components/shared';
import MealLabelPdfCard from './MealLabelPdfCard';
import SectionHeader from '../shared/SectionHeader';

const MealLabelPdfsTab = ({ classes, setDirty, dirty = false }) => {
  const { meal, submitting } = useSelector((state) => state.mealEditForm.meal);
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);
  const dispatch = useDispatch();

  const handleSubmit = async (formData) => {
    dispatch(postMealLabelPdfs({ mealId: meal.id, mealParams: formData }));
    setDirty(false);
  };

  if (submitting) {
    return (
      <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
        <Loading />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <SectionHeader text="Meal Label PDFs" />
      <Form onSubmit={handleSubmit} sanitizeEmptyValues>
        {formOptions.packingFacilities.map((packingFacility) => {
          return (
            <MealLabelPdfCard
              key={packingFacility.id}
              classes={classes}
              packingFacility={packingFacility}
              mealId={meal.id}
              savedPdfs={meal?.mealLabelPdfs}
              dirty={dirty}
              setDirty={setDirty}
            />
          );
        })}
        <SaveButton label="Save Changes" color="primary" />
      </Form>
    </Grid>
  );
};

export default MealLabelPdfsTab;
