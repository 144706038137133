import React, { useState, useEffect } from 'react';

// Material Components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';

import { FileField, FileInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import CustomFormInput from 'hub/admin/sharedComponents/CustomFormInput';
import SectionHeader from '../shared/SectionHeader';

const MealLabelPdfCard = ({
  classes,
  mealId,
  packingFacility,
  setDirty,
  dirty = false,
  savedPdfs = [],
}) => {
  const { setValue, watch, formState } = useFormContext();
  const [hasUpload, setHasUpload] = useState(false);

  const savedPdf = savedPdfs.find((sv) => sv.packingFacilityId === packingFacility.id);

  const sectionSource = `mealLabelPdfs.${packingFacility.id}`;

  const sectionDefaultValue = {
    newQrPdf: savedPdf || null,
    mealId,
    packingFacilityId: packingFacility.id,
    deleted: false,
  };
  const uploadAdded = watch(`${sectionSource}.newQrPdf.src`);

  const formatUpload = (upload) => {
    const formattedValue = upload;
    if (savedPdf) {
      return { ...formattedValue, src: savedPdf?.qrPdf, title: savedPdf?.fileName };
    }
    return formattedValue;
  };

  const removeExistingFile = () => {
    setValue(`${sectionSource}.deleted`, true);
    setHasUpload(false);
  };

  useEffect(() => {
    if (savedPdf?.qrPdf) {
      setHasUpload(true);
    }
  }, [savedPdf]);

  useEffect(() => {
    if (uploadAdded) {
      setHasUpload(true);
      setValue(`${sectionSource}.deleted`, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadAdded]);

  useEffect(() => {
    if (formState.isDirty && !dirty) {
      setDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isDirty]);

  return (
    <Paper className={classes.uploadCard}>
      <Card elevation={0}>
        <SectionHeader text={packingFacility.name} />
        <CardContent className={classes.cardContent}>
          {savedPdf === undefined || savedPdf?.fileName ? (
            <div className={classes.flexContainer}>
              <CustomFormInput source={sectionSource} defaultValue={sectionDefaultValue} />
              <FileInput
                key={packingFacility.id}
                placeholder={
                  <span className={classes.fileName}>
                    {hasUpload ? 'Replace File' : 'Drop file to upload, or click to select file'}
                  </span>
                }
                source={`${sectionSource}.newQrPdf`}
                label={false}
                accept="application/pdf"
                validateFileRemoval={removeExistingFile}
                format={formatUpload}
                className={classes.fileInput}
                sx={{
                  '& .RaFileInput-removeButton': { visibility: hasUpload ? 'visible' : 'hidden' },
                }}
              >
                <FileField
                  className={classes.fileField}
                  source="src"
                  title="title"
                  target="_blank"
                />
              </FileInput>
            </div>
          ) : (
            <p>File is being uploaded. Refresh in a few seconds to see the file</p>
          )}
        </CardContent>
      </Card>
    </Paper>
  );
};

export default MealLabelPdfCard;
