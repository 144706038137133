export default {
  root: { flexGrow: 1, padding: 24 },
  incidentablesWrapper: {
    overflowY: 'scroll',
    paddingRight: '10px',
  },
  formWrapper: {
    overflowY: 'scroll',
    marginBottom: '16px',
  },
};
