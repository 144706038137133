import React from 'react';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { centsToDollarString } from 'lib/helpers/money';

const IncidentSummary = ({
  classes,
  incidents,
  totalCashRefunds,
  totalGiftCardRefunds,
  totalCredits,
}) => {
  const anyRemediationCategories = (incident) => {
    return incident.remediationCategories.length > 0;
  };

  const renderSingleIncident = (incident) => {
    return (
      <div key={incident.id}>
        <div className={classes.item}>
          <div className={classes.lgBold}>{`${incident.id.toString()} - ${incident.type}`}</div>
        </div>
        {incident.deliveryErrorType && (
          <div className={classes.normal}>{incident.deliveryErrorType}</div>
        )}
        {anyRemediationCategories(incident) &&
          incident.remediationCategories.map((remediationCategory) => {
            return (
              <div key={`${incident.id}-${remediationCategory}`}>
                <div className={classes.normal}>{remediationCategory}</div>
              </div>
            );
          })}

        <div>
          <span className={classes.lgBold}>Charge Refund:</span>
          <span className={classes.lgLeftMargin}>
            {centsToDollarString(incident.totalCashRefund)}
          </span>
        </div>
        <div>
          <span className={classes.lgBold}>Gift Card Refund:</span>
          <span className={classes.lgLeftMargin}>
            {centsToDollarString(incident.totalGiftCardRefund)}
          </span>
        </div>
        <div>
          <span className={classes.lgBold}>Credit:</span>
          <span className={classes.lgLeftMargin}>{centsToDollarString(incident.totalCredit)}</span>
        </div>
        <hr className={classes.greyHr} />
      </div>
    );
  };

  return (
    <>
      <Paper className={classes.root} variant="outlined">
        <div className={classes.header}>Incident Summary</div>
        {incidents.map((incident) => renderSingleIncident(incident))}

        <div>
          <span className={classes.lgBold}>Total Charge Refund: </span>
          <span className={classes.lgBoldLeftMargin}>{centsToDollarString(totalCashRefunds)}</span>
        </div>
        <div>
          <span className={classes.lgBold}>Total Gift Card Refund: </span>
          <span className={classes.lgBoldLeftMargin}>
            {centsToDollarString(totalGiftCardRefunds)}
          </span>
        </div>
        <div>
          <span className={classes.lgBold}>Total Credit: </span>
          <span className={classes.lgBoldLeftMargin}>{centsToDollarString(totalCredits)}</span>
        </div>
      </Paper>

      <Paper className={classes.footer} variant="outlined">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item className={classes.gridItem}>
            <div className={classes.lgBold}>Employee </div>
            <div className={classes.normal}>{incidents[0].employee || 'N/A'}</div>
          </Grid>
          <Grid item className={classes.gridItem}>
            <div className={classes.lgBold}>Created At </div>
            <div className={classes.normal}>{incidents[0].createdAt || 'N/A'}</div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default IncidentSummary;
