import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';
import { isEmpty } from 'lodash';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';

import { ArrowBack, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

import { fetchSingleMenuData } from 'redux/menugrid/singleMenu/actions';

import MealCardWithInfo from '../MealCardWithInfo';

const MenuPage = ({ displayGroup, classes }) => {
  const dispatch = useDispatch();

  const { menuId } = useParams();
  const { menu, error, fetching } = useSelector((state) => state.menugrid.singleMenu);

  useEffect(() => {
    dispatch(fetchSingleMenuData({ displayGroup, menuId }));
  }, [dispatch, displayGroup, menuId]);

  // Render
  if (fetching) {
    return (
      <Container className={classes.loading}>
        <CircularProgress size={200} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container className={classes.loading}>
        <Typography color="primary">Menu wasn&apos;t found!</Typography>
      </Container>
    );
  }

  const renderMeals = () => {
    if (isEmpty(menu.menuSelections)) {
      return (
        <Container className={classes.loading}>
          <Typography color="primary">There aren&apos;t any meals.</Typography>
        </Container>
      );
    }
    return menu.menuSelections.map((meal) => {
      return <MealCardWithInfo meal={meal} key={meal.id} />;
    });
  };

  return (
    <>
      <Paper className={classes.root} elevation={0}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Link to={`/menugrid/${displayGroup}/`}>
              <Button startIcon={<ArrowBack />}>Back</Button>
            </Link>
            <div className={classes.separator}>|</div>
            <div className={classes.displayGroup}>{`${displayGroup}`}</div>
            <div className={classes.menu}>{`Menu ${menu.id}`}</div>
            <div className={classes.menuDate}>{`${format(
              fromUnixTime(menu.endsAt),
              'MMMM'
            )} ${format(fromUnixTime(menu.endsAt), 'd')}`}</div>
            <Link to={`/menugrid/${displayGroup}/${menu.prevMenuId}`}>
              <IconButton className={classes.arrowButton} size="large">
                <ArrowBackIos className={classes.arrow} />
              </IconButton>
            </Link>
            {menu.nextMenuId && (
              <Link to={`/menugrid/${displayGroup}/${menu.nextMenuId}`}>
                <IconButton className={classes.arrowButton} size="large">
                  <ArrowForwardIos className={classes.arrow} />
                </IconButton>
              </Link>
            )}
          </Box>
          <div className={classes.adminLink}>
            {/* link will error out locally, see #serialize method in
            mealhand/app/serializers/menugrids/menu_serializer.rb */}
            <a href={menu.url} target="_parent">
              View in Admin
            </a>
          </div>
        </Box>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          {renderMeals()}
        </Grid>
      </Paper>
    </>
  );
};

export default MenuPage;
