import React from 'react';
import OtherAssignableVersion from './OtherAssignableVersion';
import Collapse from './Collapse';

const OtherAssignableVersions = ({ compoundIngredientId, otherAssignableVersions, success }) => {
  return (
    <Collapse collapseHeader="Other Assignable Versions" isParent>
      {otherAssignableVersions?.map((otherAssignableVersion) => {
        return (
          <OtherAssignableVersion
            key={otherAssignableVersion.id}
            otherAssignableVersion={otherAssignableVersion}
            compoundIngredientId={compoundIngredientId}
            success={success}
          />
        );
      })}
    </Collapse>
  );
};

export default OtherAssignableVersions;
