import React, { useState, useEffect, useMemo } from 'react';

import { broadcastScrollSizeToParent } from 'lib/utils';
import { range } from 'lodash';

import { Button, Checkbox, FormControlLabel, Grid, Input } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import Dropdown from '../../shared/Dropdown';
import TextBox from '../../shared/TextBox';
import IngredientsPopper from './IngredientsPopper';

const RecipeStep = ({
  classes,
  formState,
  handleFormState,
  index,
  ingredients = [],
  recipeStep = null,
}) => {
  const [recipeStepState, setRecipeStepState] = useState({
    ...recipeStep,
    index,
    newImage: null,
  });

  useEffect(() => {
    setRecipeStepState({
      ...recipeStep,
      index,
      newImage: recipeStepState.newImage,
    });
  }, [recipeStep, index, recipeStepState.newImage]);

  // If being used in an iframe, set the width and height
  // so we don't have a double scrollbar situation.
  useEffect(() => {
    broadcastScrollSizeToParent();
  }, []);

  const onChange = (name, value) => {
    const newRecipeStepState = { ...recipeStepState, [name]: value };
    setRecipeStepState(newRecipeStepState);

    const newFormStateRecipeSteps = formState.recipeSteps;
    newFormStateRecipeSteps[index] = newRecipeStepState;

    handleFormState('recipeSteps', newFormStateRecipeSteps);
  };

  const onAttributeChange = (event) => {
    const { name, value } = event.target;
    onChange(name, value);
  };

  const onCheckboxChange = (event) => {
    const { name, checked } = event.target;
    onChange(name, checked);
  };

  const onDeleteStep = () => {
    onChange('deleted', true);
  };

  const onDeleteFile = () => {
    const newRecipeStepState = { ...recipeStepState, image: null, fileName: null, newImage: null };
    setRecipeStepState(newRecipeStepState);

    const newFormStateRecipeSteps = formState.recipeSteps;
    newFormStateRecipeSteps[index] = newRecipeStepState;

    handleFormState('recipeSteps', newFormStateRecipeSteps);
  };

  const onFileChange = (event) => {
    const imageFile = event.target.files[0];

    const newRecipeStepState = {
      ...recipeStepState,
      fileName: imageFile?.name,
      newImage: imageFile,
      image: null,
    };
    setRecipeStepState(newRecipeStepState);

    const newFormStateRecipeSteps = formState.recipeSteps;
    newFormStateRecipeSteps[index] = newRecipeStepState;

    handleFormState('recipeSteps', newFormStateRecipeSteps);
  };

  const selectStepOptions = useMemo(() => {
    return range(1, 11).map((num) => ({ label: num, value: num }));
  }, []);

  const renderImage = () => {
    const src = recipeStepState.newImage
      ? URL.createObjectURL(recipeStepState.newImage)
      : recipeStepState.image;
    return <img src={src} alt={recipeStepState.fileName} className={classes.recipeStepImage} />;
  };

  const renderRecipeStep = () => {
    const inputId = `contained-button-file-${index}`;

    return (
      <Grid className={classes.recipeStepCard}>
        <Grid item xs={6} className={classes.flexItemCard}>
          <TextBox
            fullWidth
            label="Title"
            name="title"
            onChange={onAttributeChange}
            value={recipeStepState.title}
          />
          <TextBox
            fullWidth
            multiline
            rows={6}
            label="Description"
            name="description"
            onChange={onAttributeChange}
            value={recipeStepState.description}
          />
          <Dropdown
            required
            label="Step"
            name="step"
            onChange={onAttributeChange}
            value={recipeStepState.step}
            options={selectStepOptions}
          />
          <IngredientsPopper classes={classes} index={index} ingredients={ingredients} />
          <Button variant="outlined" className={classes.deleteButton} onClick={onDeleteStep}>
            <DeleteIcon />
            Remove Step
          </Button>
        </Grid>
        <Grid item xs={6} className={classes.flexItemCard}>
          {renderImage()}
          <div className={classes.imageInformation}>
            <p className={classes.fileName}>
              {recipeStepState.fileName ? recipeStepState.fileName : 'No Image Selected'}
            </p>
            <div className={classes.uploadAndDelete}>
              <label htmlFor={inputId}>
                <Input
                  accept="image/*"
                  id={inputId}
                  type="file"
                  className={classes.input}
                  onChange={onFileChange}
                />
                <Button variant="outlined" component="span" className={classes.outlinedButton}>
                  {recipeStepState.id ? 'Replace Image' : 'Choose Image'}
                </Button>
              </label>
              <Button
                variant="outlined"
                className={classes.deleteImageButton}
                onClick={onDeleteFile}
              >
                <DeleteIcon />
              </Button>
            </div>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={recipeStepState.plated}
                disableRipple
                name="plated"
                onChange={onCheckboxChange}
              />
            }
            label="Plated?"
          />
        </Grid>
        <hr className={classes.recipeStepDivider} />
      </Grid>
    );
  };

  return <>{recipeStepState.deleted ? null : renderRecipeStep()}</>;
};

export default RecipeStep;
