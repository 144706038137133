import React from 'react';
import { format } from 'date-fns';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Button, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const CategoryRow = ({ category, classes, handleEditClick }) => {
  const variant = category.status === 'active' || category.status === 'archived';
  const color = category.status === 'active' || category.status === 'activating';

  return (
    <TableRow className={classes.tableRow} key={category.id}>
      <TableCell className={classes.handle} size="small" />
      <TableCell className={classes.handle} size="small">
        {category.id}
      </TableCell>
      <TableCell className={classes.handle} size="small">
        {category.name}
      </TableCell>
      <TableCell className={classes.handle} size="small">
        {category.netsuiteInternalId}
      </TableCell>
      <TableCell className={classes.handle} size="small">
        <Chip
          variant={variant ? 'outlined' : 'filled'}
          color={color ? 'success' : 'error'}
          label={category.status}
        />
      </TableCell>
      <TableCell>
        <Button
          variant="outlined"
          disabled={category.status === 'activating' || category.status === 'archiving'}
          onClick={() => handleEditClick(category)}
          startIcon={<EditIcon />}
        >
          Edit
        </Button>
      </TableCell>
      <TableCell className={classes.handle} size="small">
        {format(new Date(category.updatedAt), 'M/d/Y')}
      </TableCell>
      <TableCell className={classes.handle} size="small" />
    </TableRow>
  );
};

export default CategoryRow;
