import React, { useState } from 'react';
import { createConfigurationKey } from 'redux/appConfigurationTool/actions';
import { useDispatch } from 'react-redux';
import {
  RadioGroup,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  FormControlLabel,
  Radio,
  Dialog,
  Box,
  FormControl,
  FormHelperText,
} from '@mui/material';

import Dropdown from 'components/shared/Dropdown';

import Button from './shared/Button';
import styles from './styles';
import validateKey from './shared/ValidateKey';

const CreateKeyDialog = ({ open, onClose, configGroup, configGroupOptions }) => {
  const defaultFormData = { name: '', key: '', type: '', groupId: configGroup.groupId };
  const defaultFormErrors = { name: '', key: '', type: '', groupId: '' };
  const [formData, setFormData] = useState(defaultFormData);
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [errorState, setErrorState] = useState(false);
  const dispatch = useDispatch();

  const resetFormState = () => {
    setFormData(defaultFormData);
    setFormErrors(defaultFormErrors);
    setErrorState(false);
  };

  const validateName = (name) => {
    const errors = [];

    if (!name) errors.push('Name is required.');
    if (name.length < 4) errors.push('Name must be at least 4 characters.');

    return errors.join(' ');
  };

  const validateType = (type) => (type ? '' : 'Type is required.');

  const validate = (field, value) => {
    switch (field) {
      case 'name':
        return validateName(value);
      case 'key':
        return validateKey(value);
      case 'type':
        return validateType(value);
      default:
        return '';
    }
  };

  const handleChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
    if (errorState) setFormErrors({ ...formErrors, [field]: validate(field, e.target.value) });
  };
  const handleBlur = (field) => () => {
    let value = formData[field];
    if (field === 'key') value = value.toUpperCase();

    setFormData({ ...formData, [field]: value.trim() });
  };
  const handleCancel = () => {
    onClose();
    resetFormState();
  };
  const handleSave = (event) => {
    const nameErrors = validateName(formData.name);
    const keyErrors = validateKey(formData.key);
    const typeErrors = validateType(formData.type);

    event.preventDefault();
    if (!nameErrors && !keyErrors && !typeErrors) {
      dispatch(createConfigurationKey(formData));
      resetFormState();
    } else {
      setFormErrors({ name: nameErrors, key: keyErrors, type: typeErrors });
      setErrorState(true);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} sx={styles.topDialog}>
      <Box sx={styles.dialog}>
        <DialogTitle>Create Key</DialogTitle>
        <FormControl component="form" onSubmit={handleSave}>
          <DialogContent sx={styles.dialogContent}>
            <Dropdown
              label="Group Name"
              name="groupId"
              onChange={handleChange('groupId')}
              options={configGroupOptions}
              value={formData.groupId}
              required
            />
            <TextField
              label="Name"
              value={formData.name}
              variant="filled"
              sx={styles.dialogInput}
              onChange={handleChange('name')}
              onBlur={handleBlur('name')}
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
            <TextField
              label="Key"
              value={formData.key}
              variant="filled"
              sx={styles.dialogInput}
              onChange={handleChange('key')}
              onBlur={handleBlur('key')}
              error={!!formErrors.key}
              helperText={formErrors.key}
            />
            <FormControl error={!!formErrors.type}>
              <RadioGroup row onChange={handleChange('type')}>
                <FormControlLabel value="FeatureFlag" control={<Radio />} label="Feature Flag" />
                <FormControlLabel value="Value" control={<Radio />} label="Value" />
              </RadioGroup>
              <FormHelperText> {formErrors.type} </FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button handleOnClick={handleCancel} buttonText="Cancel" color="info" />
            <Button buttonText="Save" type="submit" />
          </DialogActions>
        </FormControl>
      </Box>
    </Dialog>
  );
};

export default CreateKeyDialog;
