import React from 'react';
import { AppBar, TitlePortal } from 'react-admin';
import { Box } from '@mui/material';
import hubLogo from '../../lib/helpers/hub/images/hubLogo.svg';

export const HubAppBar = () => (
  <AppBar sx={(theme) => ({ ...theme.components.AppBar })}>
    <Box component="img" className="logo" src={hubLogo} alt="Hub" />
    <TitlePortal />
    <Box component="span" flex={1} />
  </AppBar>
);
