// Dependencies
import React from 'react';

// Components
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';

import Input from '../Input';

const CancelFields = ({
  classes,
  formOptions,
  cancellable = false,
  cancelState = {
    cancel: false,
    reason: '',
    reasonDescription: '',
  },
  setCancelState = () => {},
}) => {
  const cancelSelected = cancelState.cancel;

  const handleCancelSelected = () => {
    const newCancelState = !cancelSelected;

    setCancelState({ ...cancelState, cancel: newCancelState });
  };

  const handleDescriptionChange = (event) => {
    const { value } = event.target;
    setCancelState({ ...cancelState, reasonDescription: value });
  };

  const handleReasonChange = (event) => {
    const { value } = event.target;
    setCancelState({ ...cancelState, reason: value });
  };

  const renderReasons = () => {
    return formOptions.cancelReasons.map((reason) => {
      return (
        <MenuItem key={reason.id} value={reason.id}>
          {reason.name}
        </MenuItem>
      );
    });
  };

  if (!cancellable) {
    return null;
  }

  return (
    <>
      <hr className={classes.divider} />
      <Paper className={classes.root} variant="outlined">
        <Checkbox value={cancelSelected} onClick={handleCancelSelected} />
        <span className={classes.checkboxLabel}>Cancel Basket</span>

        {cancelSelected ? (
          <>
            <Input
              label="Reason"
              name="reason"
              onChange={handleReasonChange}
              required
              select
              value={cancelState.reason}
            >
              <MenuItem value="">Reason</MenuItem>
              {renderReasons()}
            </Input>
            <Input
              label="Description (optional)"
              name="reasonDescription"
              onChange={handleDescriptionChange}
              value={cancelState.reasonDescription}
            />
          </>
        ) : null}
      </Paper>
    </>
  );
};

export default CancelFields;
