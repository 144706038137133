export const calculateIngredientAssignmentTolerances = (
  isRthMeal,
  quantity,
  lowTolerance,
  highTolerance
) => {
  if (isRthMeal) return { lowTolerance: quantity, highTolerance: quantity };
  return {
    lowTolerance: Number(quantity) + Number(lowTolerance ?? 0),
    highTolerance: Number(quantity) + Number(highTolerance ?? 0),
  };
};
