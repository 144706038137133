import React, { Fragment, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import LockIcon from '@mui/icons-material/Lock';

import IfAllowed from 'components/shared/IfAllowed';

import LoadingMenuSelector from './LoadingMenuSelector/LoadingMenuSelector';
import NewMenuButton from './NewMenuButton';

const MenuSelector = ({
  allMenus,
  onGetAllMenus,
  onSetSelectedMenuId,
  fetching,
  classes,
  selectedMenuId = null,
}) => {
  useEffect(() => {
    onGetAllMenus();
  }, [onGetAllMenus]);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.drawerHeader}>
        <span className={classes.drawerTitle}>Retail Menu ID</span>
        <IfAllowed ability="editRetailMenuManager">
          <NewMenuButton />
        </IfAllowed>
      </div>
      <List>
        <Divider />
        {fetching || allMenus.length === 0 ? (
          <LoadingMenuSelector />
        ) : (
          allMenus.map((menu) => (
            <Fragment key={menu.id}>
              <ListItem
                button
                onClick={() => onSetSelectedMenuId(menu.id)}
                selected={menu.id === selectedMenuId}
                className={classes.listItem}
              >
                <span className={classes.listItemMenuId}>{menu.id}</span>
                <span className={classes.listItemMenuDate}>{menu.inStoreStartsAt}</span>
                {menu.locked && <LockIcon className={classes.lockIcon} fontSize="small" />}
              </ListItem>
              <Divider variant="middle" />
            </Fragment>
          ))
        )}
      </List>
    </Drawer>
  );
};

export default MenuSelector;
