import React from 'react';

// Components
import MenuItem from '@mui/material/MenuItem';
import Input from '../../Input';

const UserIncidentFields = ({ formState, handleFormState, formOptions }) => {
  const renderSubcategories = () => {
    return formOptions.userRemediationCategories.map((category) => {
      const isDisabled = category.preShippingOnly && formState.hasTrackingInfo;
      return (
        <MenuItem disabled={isDisabled} key={category.id} value={category.id}>
          {category.name}
        </MenuItem>
      );
    });
  };

  return (
    <>
      <Input
        label="Subcategory"
        name="remediationCategoryId"
        onChange={handleFormState}
        required
        select
        value={formState.remediationCategoryId}
      >
        <MenuItem value="">Subcategory</MenuItem>
        {renderSubcategories()}
      </Input>
      <Input
        label="Description"
        multiline
        name="description"
        onChange={handleFormState}
        required
        value={formState.description}
      />
    </>
  );
};

export default UserIncidentFields;
