import { APP_API_URI, NETSUITE_TYPE_LINE_OF_BUSINESS } from 'lib/constants';

import React, { useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { GET } from 'lib/api';

export const NETSUITE_TYPE_LABELS = {
  [NETSUITE_TYPE_LINE_OF_BUSINESS]: 'Line of Business',
};

const NetsuiteAutocomplete = ({ type, value = null, onChange = () => {}, ...props }) => {
  const [options, setOptions] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setOptions(null);
    setErrorMessage(null);

    const fetchNetsuiteOptions = async () => {
      await GET(`${APP_API_URI}/internal/netsuite_autocomplete/get_all`, { params: { type } })
        .then((response) => setOptions(response.data.options))
        .catch((error) => {
          setErrorMessage(error.response?.data?.error || error.response.statusText);
        });
    };

    fetchNetsuiteOptions();
  }, [type]);

  const inputLabel = NETSUITE_TYPE_LABELS[type];
  const getTooltip = (option) => `Internal ID: ${option.id}`;
  const renderInput = (inputProps) => {
    const textField = <TextField {...inputProps} label={inputLabel} />;

    if (value) {
      return <Tooltip title={getTooltip(value)}>{textField}</Tooltip>;
    }
    return textField;
  };

  if (errorMessage) {
    return <Alert severity="error">{errorMessage}</Alert>;
  }

  if (options === null) {
    return (
      <Skeleton variant="rectangle" width="100%">
        <TextField />
      </Skeleton>
    );
  }

  const selectedOption = options.find(({ id }) => {
    return id === value;
  });

  return (
    <Autocomplete
      id="netsuite-selector"
      value={selectedOption || null}
      onChange={(e, newValue) => onChange(newValue?.id)}
      options={options}
      renderOption={(optionProps, option) => (
        <Tooltip key={option.id} title={getTooltip(option)}>
          <MenuItem {...optionProps}>{option.label}</MenuItem>
        </Tooltip>
      )}
      renderInput={renderInput}
      {...props}
    />
  );
};

export default NetsuiteAutocomplete;
