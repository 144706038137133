// Dependencies
import React, { useState, useContext } from 'react';

// Helpers
import { dollarStringToNumber } from 'lib/helpers/money';

// Material
import TextField from '@mui/material/TextField';

import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';

// Custom Components
import NumberFormatter from '../NumberFormatter';

const MoneyInput = ({
  children = [],
  classes,
  disabled = false,
  focused = false,
  isBasketTrigger = false,
  label = '',
  name = '',
  fullWidth = false,
  setFormError,
  value,
}) => {
  const { actions } = useContext(BehavioralDiscountFormContext);

  const [error, setError] = useState({
    error: false,
  });
  const [helperText, setHelperText] = useState(
    isBasketTrigger ? 'This is the minimum order required to trigger the reward.' : ''
  );

  // Helpers
  const isValid = (input) => {
    let validity = false;

    if (input <= 0 && !isBasketTrigger) {
      setHelperText('Discounts must be more than $0');
    } else if (input > 20 && !isBasketTrigger) {
      setHelperText('Discounts must be $20 or less');
    } else {
      setHelperText('');
      validity = true;
    }

    return validity;
  };

  // Handlers
  const handleOnChange = (event) => {
    const convertedInput = dollarStringToNumber(event.target.value);
    setError({ error: false });
    setFormError(false);
    if (isBasketTrigger) {
      actions.setFormState('targetValueCents', convertedInput);
    } else {
      actions.setFormState('valueCents', convertedInput);
    }

    if (!isValid(convertedInput)) {
      setFormError(true);
      setError({
        error: true,
      });
    }
  };

  return (
    <TextField
      className={classes.textInput}
      disabled={disabled}
      focused={focused}
      error={error.error}
      helperText={helperText}
      label={label}
      name={name}
      onChange={handleOnChange}
      value={value}
      variant="outlined"
      InputProps={{
        inputComponent: NumberFormatter,
      }}
      fullWidth={fullWidth}
    >
      {children}
    </TextField>
  );
};

export default MoneyInput;
