import React from 'react';

import Button from '@mui/material/Button';

const RemoveIncidentButton = ({ classes, formId, formStates, setFormStates }) => {
  const removeIncidentForm = () => {
    const newFormStates = formStates;
    delete newFormStates[formId];

    setFormStates({ ...newFormStates });
  };

  if (formId === '0') {
    return null;
  }

  return (
    <Button className={classes.button} onClick={removeIncidentForm}>
      Remove
    </Button>
  );
};

export default RemoveIncidentButton;
