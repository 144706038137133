import React, { useState } from 'react';
import useAllowed from 'lib/useAllowed';

import { Draggable } from 'react-beautiful-dnd';
import TableCell from '@mui/material/TableCell';
import DragIcon from '@mui/icons-material/DragIndicator';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveMealDialog from '../../RemoveMealDialog';

const DraggableTableRow = ({
  alternativeRanking,
  classes,
  category,
  meal,
  onSetSelectedMealId,
  onSubmitRemoveMeal,
  index,
  retailMenuId = null,
  locked = false,
  selectedMealId = null,
}) => {
  const [activeRow, setActiveRow] = useState(null);
  const editAllowed = useAllowed('editRetailMenuManager');

  const buttonVisibility = clsx((activeRow && classes.visible) || classes.hidden);

  const mealRowStyle = (snapshot) =>
    clsx(classes.tableRow, snapshot.isDragging && classes.rowDropShadow);

  const handleMouseOver = () => {
    setActiveRow(true);
  };

  const handleMouseOut = () => {
    setActiveRow(null);
  };

  const handleRemoveOpen = () => {
    onSetSelectedMealId(meal.id);
  };

  const handleRemoveClose = () => {
    onSetSelectedMealId(null);
    setActiveRow(null);
  };

  const handleOnSubmitRemoveMeal = () => {
    onSubmitRemoveMeal(retailMenuId, category.id, meal.id);
    setActiveRow(null);
  };

  return (
    <Draggable key={meal.id} draggableId={`${meal.id}`} index={index} isDragDisabled={!editAllowed}>
      {({ innerRef, draggableProps, dragHandleProps }, snapshot) => {
        return (
          <tr
            key={meal.id}
            className={mealRowStyle(snapshot)}
            ref={innerRef}
            onMouseOver={handleMouseOver}
            onFocus={handleMouseOver}
            onMouseOut={handleMouseOut}
            onBlur={handleMouseOut}
            {...draggableProps}
          >
            <TableCell className={classes.handle}>
              <span {...dragHandleProps}>
                {!editAllowed || locked || <DragIcon className={buttonVisibility} />}
              </span>
            </TableCell>
            <TableCell size="small" align="center">
              {alternativeRanking ? meal.alternativeRanking : meal.ranking}
            </TableCell>
            <TableCell size="small">{meal.title}</TableCell>
            <TableCell size="small">{meal.type}</TableCell>
            <TableCell size="small">{meal.culinaryVersionId}</TableCell>
            <TableCell size="small">{meal.id}</TableCell>
            <TableCell size="small" align="center">
              {alternativeRanking ? meal.ranking : meal.alternativeRanking}
            </TableCell>
            <TableCell align="left">
              <div className={classes.actionButtonContainer}>
                {!editAllowed || locked || alternativeRanking || (
                  <>
                    <IconButton
                      onClick={handleRemoveOpen}
                      size="small"
                      className={buttonVisibility}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <RemoveMealDialog
                      open={meal.id === selectedMealId}
                      onDialogClose={handleRemoveClose}
                      onSubmitRemoveMeal={handleOnSubmitRemoveMeal}
                      storeOrderCount={meal.storeOrderCount}
                      countUnits={meal.countUnits}
                    />
                  </>
                )}
              </div>
            </TableCell>
          </tr>
        );
      }}
    </Draggable>
  );
};

export default DraggableTableRow;
