// Dependencies
import React, { useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';

// Lodash
import flatMapDeep from 'lodash/flatMapDeep';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Collapse from '@mui/material/Collapse';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';

// Helpers
import {
  compareTextBlobs,
  sanitizedEquipmentItems,
  setHighlightEquipmentItem,
} from 'lib/helpers/comparisonTool';

import withStyles from '@mui/styles/withStyles';
import styles from './styles';

const EquipmentRow = ({ viewableMeals, classes }) => {
  // State methods
  const [openCollapsible, setOpenCollapsible] = useState(false);
  const [highlightEquipmentItems, setHighlightEquipmentItems] = useState({});
  const [hasDifferences, setHasDifferences] = useState(false);

  // Lifecycle methods
  useMemo(() => {
    const checkDifferences =
      viewableMeals.length !== 0
        ? compareTextBlobs(sanitizedEquipmentItems(viewableMeals.map((meal) => meal.equipment)))
        : false;
    setHasDifferences(checkDifferences);
  }, [viewableMeals]);

  useEffect(() => {
    const allEquipmentItems = groupBy(flatMapDeep(viewableMeals, (meal) => meal.equipment));

    setHighlightEquipmentItems(
      mapValues(allEquipmentItems, (occurrences) =>
        setHighlightEquipmentItem(occurrences, viewableMeals)
      )
    );
  }, [viewableMeals]);

  // Handler methods
  const handleCollapsibleClick = () => {
    setOpenCollapsible(!openCollapsible);
  };

  // Render methods
  const renderEquipmentItem = (itemName, meal) => {
    const matchedItem = meal.equipment.find((equipmentItem) => equipmentItem === itemName);
    if (matchedItem) {
      return (
        <div
          key={`${meal.versionId}-${itemName}`}
          className={clsx(
            classes.equipmentItem,
            highlightEquipmentItems[itemName] && classes.highlight
          )}
        >
          <div key={`${meal.versionId}-${itemName}`} className={classes.utensil}>
            {itemName}
          </div>
        </div>
      );
    }
    return '';
  };

  return (
    <TableRow>
      <TableCell
        className={clsx(classes.iconCell, hasDifferences && classes.tableRowGreenBorder)}
        onClick={handleCollapsibleClick}
      >
        {openCollapsible ? (
          <ExpandMore className={classes.icon} />
        ) : (
          <ChevronRightIcon className={classes.icon} />
        )}
      </TableCell>
      <TableCell className={classes.rowLabel} onClick={handleCollapsibleClick}>
        Equipment
      </TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`equipment.${meal.versionId}`}
          className={clsx(meal.inactive && classes.greyed)}
        >
          <div className={classes.listContentSameBlock}>{`${meal.equipment.length} Utensil${
            meal.equipment.length !== 1 ? 's' : ''
          }`}</div>
          <Collapse in={openCollapsible} unmountOnExit className={classes.collapse}>
            {meal.equipment.length > 0 &&
              map(meal.equipment, (itemName) => {
                return renderEquipmentItem(itemName, meal);
              })}
          </Collapse>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default withStyles(styles)(EquipmentRow);
