import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  PACKING_FACILITY_IDS,
  PACKING_FACILITY_NAMES,
  HIGH_TOLERANCE,
  LOW_TOLERANCE,
  MEASURE,
  USAGE_YIELD,
  QUANTITY,
  VOLUME_MEASUREMENTS,
} from 'lib/constants';
import { calculateIngredientAssignmentTolerances } from 'lib/helpers/mealEditForm/';
import clsx from 'clsx';

// Material Components
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material//Snackbar';
import Alert from '@mui/material/Alert';
import Checkboxes from '../../shared/Checkboxes';
import Dropdown from '../../shared/Dropdown';
import IngredientCheckboxes from '../IngredientsTab/IngredientCheckboxes';
import SectionHeader from '../shared/SectionHeader';
import TextBox from '../../shared/TextBox';
import IngredientSearch from '../IngredientsTab/IngredientSearch';
import MealAllergensValidationDialog from './MealAllergensValidationDialog';

import { ACTIONS, ReplacementsTabContext } from '../reducer';
import { fetchMealAllergenValidation } from './service';

const ReplacementForm = ({ classes, inactiveForm }) => {
  const {
    formOptions: { ingredientPackagings, portionMethods, opsCategories, packingFacilities },
  } = useSelector((state) => state.mealEditForm.formOptions);
  const { meal } = useSelector((state) => state.mealEditForm.meal);
  const { state, dispatch } = useContext(ReplacementsTabContext);
  const { selectedState, replacementState } = state;

  // Meal Allergen Dialog
  const [newlyIntroducedAllergens, setNewlyIntroducedAllergens] = useState([]);
  const [showMealValidationError, setShowMealValidationError] = useState(false);

  const selectIngredientPackagings = useMemo(
    () => ingredientPackagings.map(({ name, id }) => ({ label: name, value: id })),
    [ingredientPackagings]
  );

  const selectPortionMethods = useMemo(
    () => portionMethods.map(({ formattedName, name }) => ({ label: formattedName, value: name })),
    [portionMethods]
  );

  const selectOpsCategories = useMemo(
    () => opsCategories.map(({ formattedName, name }) => ({ label: formattedName, value: name })),
    [opsCategories]
  );

  const selectVolumeMeasurements = useMemo(() => {
    return VOLUME_MEASUREMENTS.map((measurement) => ({ label: measurement, value: measurement }));
  }, []);

  const addPackingFacilityNames = () =>
    packingFacilities
      .filter((pf) => replacementState?.packingFacilityIds?.includes(pf.id))
      .map((pf) => pf.name);

  const setReplacementState = (newReplacementState) => {
    dispatch({
      type: ACTIONS.SET_REPLACEMENT_STATE,
      payload: { value: newReplacementState },
    });
  };

  const handleReplacementState = (name, value) => {
    const newReplacementState = {
      ...replacementState,
      [name]: value,
    };
    setReplacementState(newReplacementState);
  };

  const defaultCheckboxState = {
    labelRequired: selectedState.labelRequired || replacementState?.labelRequired || false,
    readyToEat: selectedState.readyToEat || replacementState?.readyToEat || false,
    mustCook: selectedState.mustCook || replacementState?.mustCook || false,
    pickable: selectedState.pickable || replacementState?.pickable || false,
    largeIngredient: selectedState.largeIngredient || replacementState?.largeIngredient || false,
  };
  const [checkboxState, setCheckboxState] = useState(defaultCheckboxState);

  const [replacementIngredientSelected, setReplacementIngredientSelected] = useState(
    replacementState?.ingredient || selectedState.ingredient
  );
  const handleCheckboxChange = (event) => {
    const newCheckboxState = { ...checkboxState, [event.target.name]: event.target.checked };
    setCheckboxState(newCheckboxState);
    handleReplacementState(event.target.name, event.target.checked);
  };

  const handleChange = (event) => {
    handleReplacementState(event.target.name, event.target.value.map(Number));
  };

  const handleValidateMealAllergen = (newReplacementIngredient) => {
    fetchMealAllergenValidation({
      mealId: meal.id,
      replacementIngredientId: newReplacementIngredient.id,
    })
      .then((res) => {
        const { data } = res;
        setNewlyIntroducedAllergens(data.newlyIntroducedAllergens);
      })
      .catch(() => {
        // We want to ensure that if the validation request fails
        // users cannot proceed
        setShowMealValidationError(true);
        setReplacementState(null);
        setReplacementIngredientSelected(null);
      });
  };

  const handleMealAllergenErrorClose = () => {
    setShowMealValidationError(false);
  };

  /**
   * Sets the replacementIngredientSelected to the ingredient selected by the user.
   *
   * Autocomplete onChange takes in two params: event and values.
   * In this case values is the value of the selected ingredient.
   * @function onIngredientChange
   */
  const onIngredientChange = (_event, values) => {
    const newReplacementIngredient = values;

    if (newReplacementIngredient) {
      handleValidateMealAllergen(newReplacementIngredient);
    }

    setReplacementIngredientSelected(newReplacementIngredient);
    let newReplacementState = {
      brandName: newReplacementIngredient?.brandName,
      ingredientPackagingId: newReplacementIngredient?.packaging,
      labelRequired: newReplacementIngredient?.labelRequired,
      largeIngredient: newReplacementIngredient?.largeIngredient,
      mustCook: newReplacementIngredient?.mustCook,
      pickable: newReplacementIngredient?.pickable,
      readyToEat: false,
      highTolerance: '',
      ingredient: newReplacementIngredient,
      ingredientId: newReplacementIngredient?.id,
      lowTolerance: '',
      measure: newReplacementIngredient?.orderableUnit,
      name: newReplacementIngredient?.displayName,
      opsCategory: newReplacementIngredient?.opsCategory,
      portionMethod: newReplacementIngredient?.portionMethod,
      productionSheetNotes: '',
      usageYield: newReplacementIngredient?.usageYield,
    };
    if (selectedState.compoundIngredientRecipeId) {
      newReplacementState = {
        ...newReplacementState,
        compoundIngredientRecipeId: selectedState.compoundIngredientRecipeId,
        originalCiraId: selectedState.id,
        mealWhereUsedAsReplacementId: meal.id,
      };
    }
    setReplacementState({
      ...replacementState,
      ...newReplacementState,
    });
  };

  const renderMeasureField = () => {
    if (inactiveForm || selectedState.compoundIngredientRecipeId != null) {
      return (
        <TextBox
          fullWidth
          label="Measure"
          name={MEASURE}
          value={selectedState.measure || ''}
          disabled
        />
      );
    }

    const isVolume = replacementState?.ingredient?.standardUnit === 'volume';

    return isVolume ? (
      <Dropdown
        required
        label="Measure"
        name={MEASURE}
        onChange={(event) => handleReplacementState(event.target.name, event.target.value)}
        value={replacementState?.measure}
        options={selectVolumeMeasurements}
      />
    ) : (
      <TextBox
        fullWidth
        label="Measure"
        name={MEASURE}
        value={replacementState?.measure || ''}
        disabled
      />
    );
  };

  const resetTolerances = () => {
    const { lowTolerance, highTolerance } = calculateIngredientAssignmentTolerances(
      meal.rthProductType,
      replacementState.quantity,
      replacementIngredientSelected?.lowTolerance,
      replacementIngredientSelected?.highTolerance
    );

    const newReplacementState = {
      ...replacementState,
      lowTolerance,
      highTolerance,
    };

    setReplacementState(newReplacementState);
  };

  useEffect(() => {
    resetTolerances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replacementState?.quantity, replacementIngredientSelected]);

  useEffect(() => {
    // TODO: Get rid of this useEffect
    handleReplacementState(PACKING_FACILITY_NAMES, addPackingFacilityNames());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replacementState?.packingFacilityIds]);

  return (
    <Tooltip
      title="Assigned Ingredient form is display-only"
      className={classes.tooltip}
      PopperProps={{
        sx: inactiveForm || { display: 'none' },
      }}
      followCursor
    >
      <Paper className={clsx(classes.paper, inactiveForm && classes.inactiveForm)}>
        <SectionHeader text={inactiveForm ? 'Assigned Ingredient' : 'Replacement Ingredient'} />
        <IngredientSearch
          classes={classes}
          autocompleteOnChange={onIngredientChange}
          ingredientSelected={
            inactiveForm ? selectedState.ingredient : replacementIngredientSelected
          }
          compoundIngredientRecipeId={replacementState?.compoundIngredientId || ''}
          includeShortsAndReplacements
          name="ingredient"
          label="Ingredient"
          rthProductType={meal.rthProductType}
          isReplacement
          disabled={inactiveForm}
        />
        <div className={classes.inactiveFormTextContainer}>
          <div className={classes.inactiveFormTextLabel}>Brand</div>
          <div className={classes.inactiveFormText}>
            {inactiveForm
              ? selectedState.ingredient?.brandName
              : replacementState?.ingredient?.brandName}
          </div>
        </div>
        <div className={classes.inactiveFormTextContainer}>
          <div className={classes.inactiveFormTextLabel}>State</div>
          <div className={classes.inactiveFormText}>
            {inactiveForm ? selectedState.ingredient?.state : replacementState?.ingredient?.state}
          </div>
        </div>

        <TextBox
          fullWidth
          label="ID *"
          name="id"
          value={
            inactiveForm
              ? selectedState.ingredient?.id || ''
              : replacementState?.ingredient?.id || ''
          }
          disabled
        />
        <TextBox
          fullWidth
          label="Display Name"
          name="name"
          onChange={(event) => handleReplacementState(event.target.name, event.target.value)}
          value={inactiveForm ? selectedState.name || '' : replacementState?.name || ''}
          disabled={inactiveForm}
        />
        {renderMeasureField()}
        <TextBox
          fullWidth
          label="Quantity"
          name={QUANTITY}
          type="number"
          onChange={(event) => handleReplacementState(event.target.name, event.target.value)}
          required
          value={inactiveForm ? selectedState.quantity || '' : replacementState?.quantity || ''}
          disabled={
            inactiveForm ||
            (selectedState?.compoundIngredientRecipeId !== undefined &&
              selectedState?.compoundIngredientRecipeId !== null)
          }
        />
        <TextBox
          fullWidth
          label="Usage Yield"
          name={USAGE_YIELD}
          type="number"
          onChange={(event) => handleReplacementState(event.target.name, event.target.value)}
          required
          value={inactiveForm ? selectedState.usageYield || '' : replacementState?.usageYield || ''}
          disabled={inactiveForm}
        />
        <TextBox
          fullWidth
          multiline
          label="Production Sheet Notes"
          name="productionSheetNotes"
          onChange={(event) => handleReplacementState(event.target.name, event.target.value)}
          value={
            inactiveForm
              ? selectedState.productionSheetNotes || ''
              : replacementState?.productionSheetNotes || ''
          }
          disabled={inactiveForm}
        />
        <TextBox
          fullWidth
          label="Low Tolerance"
          name={LOW_TOLERANCE}
          type="number"
          onChange={(event) => handleReplacementState(event.target.name, event.target.value)}
          value={
            inactiveForm ? selectedState.lowTolerance || '' : replacementState?.lowTolerance || ''
          }
          disabled
        />
        <TextBox
          fullWidth
          label="High Tolerance"
          name={HIGH_TOLERANCE}
          type="number"
          onChange={(event) => handleReplacementState(event.target.name, event.target.value)}
          value={
            inactiveForm ? selectedState.highTolerance || '' : replacementState?.highTolerance || ''
          }
          disabled
        />
        {selectedState.compoundIngredientRecipeId ? null : (
          <Dropdown
            label="Ingredient Packaging"
            name="ingredientPackagingId"
            required
            value={
              inactiveForm
                ? selectedState.ingredientPackagingId || ''
                : replacementState?.ingredientPackagingId || ''
            }
            options={selectIngredientPackagings}
            onChange={(event) => handleReplacementState(event.target.name, event.target.value)}
            disabled={inactiveForm}
          />
        )}
        <Dropdown
          label="Portion Method"
          name="portionMethod"
          required
          value={
            inactiveForm ? selectedState.portionMethod || '' : replacementState?.portionMethod || ''
          }
          options={selectPortionMethods}
          onChange={(event) => handleReplacementState(event.target.name, event.target.value)}
          disabled={inactiveForm}
        />
        <Dropdown
          label="Ops Category"
          name="opsCategory"
          required
          value={
            inactiveForm ? selectedState.opsCategory || '' : replacementState?.opsCategory || ''
          }
          options={selectOpsCategories}
          onChange={(event) => handleReplacementState(event.target.name, event.target.value)}
          disabled={inactiveForm}
        />
        <div className={classes.replacementFormContainer}>
          {selectedState.compoundIngredientRecipeId ? null : (
            <div className={classes.replacementFormCheckboxContainer}>
              <p className={classes.label}>Ingredient Options</p>
              <IngredientCheckboxes
                classes={classes}
                ingredientAssignmentState={
                  inactiveForm ? selectedState : replacementState || checkboxState
                }
                handleCheckboxChange={handleCheckboxChange}
                disabledState={inactiveForm}
              />
            </div>
          )}
          {inactiveForm || (
            <div className={classes.replacementFormCheckboxContainer}>
              <p className={classes.label}>Packing Facilities *</p>
              <Checkboxes
                choices={packingFacilities}
                columns={1}
                selectedFormState={
                  inactiveForm
                    ? selectedState.packingFacilityIds || []
                    : replacementState?.packingFacilityIds || []
                }
                selectedFormStateName={PACKING_FACILITY_IDS}
                handleFormState={handleChange}
                disabledState={inactiveForm}
              />
            </div>
          )}
        </div>
        <MealAllergensValidationDialog
          allergens={newlyIntroducedAllergens}
          handleNewlyIntroducedAllergens={setNewlyIntroducedAllergens}
          handleReplacementIngredientSelected={setReplacementIngredientSelected}
          handleReplacementState={setReplacementState}
        />
        <Snackbar
          open={showMealValidationError}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={6000}
          onClose={handleMealAllergenErrorClose}
        >
          <Alert
            onClose={handleMealAllergenErrorClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            Could not validate allergens for selected ingredient
          </Alert>
        </Snackbar>
      </Paper>
    </Tooltip>
  );
};

export default ReplacementForm;
