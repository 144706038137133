import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { postMeal, postMealPhotos, postMealRecipeStepsTab } from 'redux/mealEditForm/meal/actions';

import Button from '@mui/material/Button';

const SaveChangesButton = ({
  mealId,
  mealParams,
  disabled = false,
  savePhotos = false,
  saveRecipeSteps = false,
}) => {
  const isSubmitting = useSelector((state) => state.mealEditForm.meal.submitting);

  const dispatch = useDispatch();

  const postChanges = () => {
    if (savePhotos) {
      dispatch(postMealPhotos({ mealId, mealParams }));
    } else if (saveRecipeSteps) {
      dispatch(postMealRecipeStepsTab({ mealId, mealParams }));
    } else {
      dispatch(postMeal({ mealId, mealParams }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    postChanges();
  };

  return (
    <Button
      color="primary"
      onClick={handleSubmit}
      disabled={disabled || isSubmitting}
      variant="contained"
    >
      Save Changes
    </Button>
  );
};

export default SaveChangesButton;
