import React, { useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Skeleton } from '@mui/material';
import { GET } from 'lib/api';
import { APP_API_URI } from 'lib/constants';

const PackingFacilitySelector = ({ classes, value = null, onChange = () => {} }) => {
  const [packingFacilities, setPackingFacilities] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    const fetchPackingFacilities = async () => {
      setFetching(true);
      await GET(`${APP_API_URI}/internal/retail/flexible_volume_changes/get_packing_facilities`)
        .then((response) => {
          setPackingFacilities(response.data.packingFacilities);
        })
        .catch((error) => {
          setErrorMessage(error.response?.data?.error || error.response.statusText);
        })
        .finally(() => setFetching(false));
    };

    fetchPackingFacilities();
  }, []);

  if (errorMessage) {
    return <Alert severity="error">{errorMessage}</Alert>;
  }

  if (packingFacilities === null || fetching) {
    return <Skeleton variant="rectangular" width={200} height={58} />;
  }

  return (
    <ToggleButtonGroup label="Packing Facility" exclusive size="large">
      {packingFacilities &&
        packingFacilities.map((pf) => {
          return (
            <ToggleButton
              onClick={() => onChange(pf)}
              selected={value && pf.id === value.id}
              key={pf.id}
              value={pf.id}
              className={classes.packingFacilityButton}
            >
              {pf.name}
            </ToggleButton>
          );
        })}
    </ToggleButtonGroup>
  );
};

export default PackingFacilitySelector;
