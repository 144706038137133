// Dependencies
import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';

// Lodash
import flatMapDeep from 'lodash/flatMapDeep';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import map from 'lodash/map';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import {
  compareTextBlobs,
  sanitizedDisposableItems,
  setHighlightDisposableItem,
} from 'lib/helpers/comparisonTool';

const DisposableItemsRow = ({ viewableMeals, classes }) => {
  // State methods
  const [openCollapsible, setOpenCollapsible] = useState(false);
  const [hasDifferences, setHasDifferences] = useState(false);
  const [highlightDisposableItems, setHighlightDisposableItems] = useState({});
  const [disposableItemsSuperset, setDisposableItemsSuperset] = useState({});

  // Lifecycle methods
  useMemo(() => {
    const checkDifferences =
      viewableMeals.length !== 0
        ? compareTextBlobs(
            sanitizedDisposableItems(viewableMeals.map((meal) => meal.disposableItems))
          )
        : false;
    setHasDifferences(checkDifferences);
  }, [viewableMeals]);

  useEffect(() => {
    const allDisposableItems = groupBy(
      flatMapDeep(viewableMeals, (meal) => {
        return meal.disposableItems.map((disposableItem) => disposableItem.name);
      })
    );

    setDisposableItemsSuperset(keys(allDisposableItems));

    setHighlightDisposableItems(
      mapValues(allDisposableItems, (occurrences, disposableItem) => {
        return setHighlightDisposableItem(disposableItem, occurrences, viewableMeals);
      })
    );
  }, [viewableMeals]);

  // Handler methods
  const handleCollapsibleClick = () => {
    setOpenCollapsible(!openCollapsible);
  };

  // Render methods
  const renderDisposableItem = (itemName, meal) => {
    const matchedItem = meal.disposableItems.find(
      (disposableItem) => disposableItem.name === itemName
    );
    if (matchedItem) {
      return (
        <div
          key={`${meal.versionId}-${itemName}`}
          className={clsx(
            classes.disposableItem,
            highlightDisposableItems[itemName] && classes.highlight
          )}
        >
          {itemName}{' '}
          <span className={clsx(classes.disposableQuantity)}>Qty: {matchedItem.quantity}</span>
        </div>
      );
    }

    return (
      <div
        key={`${meal.versionId}-${itemName}`}
        className={clsx(classes.disposableItem, classes.disposableItemMissing)}
      >
        No Item
      </div>
    );
  };

  return (
    <TableRow>
      <TableCell
        className={clsx(classes.iconCell, hasDifferences && classes.tableRowGreenBorder)}
        onClick={handleCollapsibleClick}
      >
        {openCollapsible ? (
          <ExpandMore className={classes.icon} />
        ) : (
          <ChevronRightIcon className={classes.icon} />
        )}
      </TableCell>
      <TableCell className={classes.rowLabel} onClick={handleCollapsibleClick}>
        Disposable Items
      </TableCell>

      {viewableMeals.map((meal) => (
        <TableCell
          key={`disposableItems.${meal.versionId}`}
          className={clsx(classes.expandingCell, meal.inactive && classes.greyed)}
        >
          <div className={classes.listContentSameBlock}>{`${meal.disposableItems.length} Item${
            meal.disposableItems.length === 1 ? '' : 's'
          }`}</div>
          <Collapse in={openCollapsible} unmountOnExit className={classes.collapse}>
            {map(disposableItemsSuperset, (itemName) => {
              return renderDisposableItem(itemName, meal);
            })}
          </Collapse>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default DisposableItemsRow;
