import React from 'react';
import { Button } from '@mui/material';
import { useListContext } from 'react-admin';

const ClearFiltersInput = ({ labelText }) => {
  const { setFilters } = useListContext();

  return (
    <Button color="primary" onClick={() => setFilters({})}>
      {labelText}
    </Button>
  );
};

export default ClearFiltersInput;
