import React, { useState } from 'react';

import TextField from '@mui/material/TextField';

const Input = ({
  classes,
  children = [],
  disabled = false,
  errorMessage = 'This field is required.',
  fullWidth = true,
  helperText = '',
  label = '',
  maxLength = 255,
  multiline = false,
  name = '',
  onChange = () => {},
  required = false,
  select = false,
  value = '',
}) => {
  const [error, setError] = useState({
    error: false,
    message: '',
  });

  const validateField = (event) => {
    const fieldValue = event.target.value;

    if (required && !fieldValue) {
      setError({
        error: true,
        message: errorMessage,
      });
    } else {
      setError({
        error: false,
        message: '',
      });
    }
  };

  const validateLengthAndChange = (event) => {
    const fieldLength = event.target.value.length;

    if (fieldLength > maxLength) {
      setError({
        error: true,
        message: `Maximum length is ${maxLength} characters.`,
      });
    } else {
      setError({
        error: false,
        message: '',
      });
    }

    onChange(event);
  };

  return (
    <TextField
      className={classes.textInput}
      disabled={disabled}
      error={error.error}
      fullWidth={fullWidth}
      helperText={error.error ? error.message : helperText}
      label={label}
      multiline={multiline}
      name={name}
      onBlur={validateField}
      onChange={validateLengthAndChange}
      required={required}
      rows={multiline ? 4 : null}
      select={select}
      value={value}
      variant="outlined"
      InputProps={{
        maxLength,
      }}
    >
      {children}
    </TextField>
  );
};

export default Input;
