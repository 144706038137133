import { FVC_CHANGE_TYPE_TRANSFER_QUANTITY, FVC_BALANCING_TYPES_DEFAULT } from 'lib/constants';
import React, { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

import GeographyInput from '../GeographyInput';
import MealSelector from '../MealSelector';
import PackingFacilitySelector from '../PackingFacilitySelector';
import BalancerSelector from '../BalancerSelector';

const TransferQuantity = ({ onChange, value = null }) => {
  const [shipDate, setShipDate] = useState(value?.shipDate);
  const [packingFacility, setPackingFacility] = useState(value?.packingFacility);
  const [geographyFrom, setGeographyFrom] = useState(value?.geographyFrom || []);
  const [geographyTo, setGeographyTo] = useState(value?.geographyTo || []);
  const [meal, setMeal] = useState(value?.meal);
  const [quantity, setQuantity] = useState(value?.quantity);
  const [balancer, setBalancer] = useState(value?.balancer || FVC_BALANCING_TYPES_DEFAULT);
  const [explanation, setExplanation] = useState(value?.explanation || '');

  const geographyError =
    geographyFrom.some((g) => g.errorMessage) || geographyTo.some((g) => g.errorMessage);

  useEffect(() => {
    const isFormReady =
      shipDate &&
      packingFacility &&
      geographyFrom &&
      geographyTo &&
      meal &&
      balancer &&
      explanation &&
      quantity !== null &&
      !geographyError;
    const changeRequestData = isFormReady
      ? {
          changeType: FVC_CHANGE_TYPE_TRANSFER_QUANTITY,
          shipDate,
          packingFacility,
          geographyFrom,
          geographyTo,
          meal,
          quantity,
          balancer,
          explanation,
        }
      : value;

    onChange(changeRequestData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shipDate,
    packingFacility,
    geographyFrom,
    geographyTo,
    meal,
    quantity,
    balancer,
    explanation,
    geographyError,
    onChange,
  ]);

  useEffect(() => {
    setShipDate(value?.shipDate);
    setPackingFacility(value?.packingFacility);
    setGeographyFrom(value?.geographyFrom || []);
    setGeographyTo(value?.geographyTo || []);
    setMeal(value?.meal);
    setQuantity(value?.quantity);
    setBalancer(value?.balancer || FVC_BALANCING_TYPES_DEFAULT);
    setExplanation(value?.explanation || '');
  }, [value]);

  const handleQuantityChange = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setQuantity(parseInt(e.target.value, 10));
    }
  };

  return (
    <>
      <TextField
        id="shipDate"
        label="On Production Day"
        type="date"
        value={shipDate || ''}
        onChange={(e) => setShipDate(e.target.value)}
        fullWidth
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <PackingFacilitySelector
        id="packingFacility"
        value={packingFacility}
        onChange={setPackingFacility}
      />
      <MealSelector
        id="meal"
        packingFacility={packingFacility}
        shipDate={shipDate}
        geography={geographyFrom}
        value={meal}
        onChange={setMeal}
      />
      <GeographyInput
        id="geographyFrom"
        label="Geography From"
        packingFacility={packingFacility}
        shipDate={shipDate}
        meal={meal}
        value={geographyFrom}
        onChange={setGeographyFrom}
      />
      <GeographyInput
        id="geographyTo"
        label="Geography To"
        packingFacility={packingFacility}
        shipDate={shipDate}
        meal={meal}
        value={geographyTo}
        onChange={setGeographyTo}
      />
      <Divider />
      <TextField
        id="quantity"
        label="Quantity"
        type="text"
        value={quantity ?? ''}
        onChange={(e) => handleQuantityChange(e)}
        variant="outlined"
        fullWidth
      />
      <BalancerSelector id="balancer" value={balancer} onChange={setBalancer} />
      <Divider />
      <TextField
        id="explanation"
        label="Explanation"
        value={explanation}
        onChange={(e) => setExplanation(e.target.value)}
        multiline
        rows={4}
        variant="outlined"
        fullWidth
      />
    </>
  );
};

export default TransferQuantity;
