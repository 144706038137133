import { AVATAR_GREY, MEDIUM_GREY } from 'lib/constants';

export default {
  avatarGroupContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 0,
    paddingTop: 1,
    paddingRight: '0 !important',
    paddingBottom: 1,
    width: '100%',
  },
  avatarCollapse: {
    bgcolor: AVATAR_GREY,
    '&:hover': {
      backgroundColor: MEDIUM_GREY,
      transition: '.5s',
    },
  },
};
