import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import LockIcon from '@mui/icons-material/Lock';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import LoadingMenu from './LoadingMenu';
import CategoryCard from './CategoryCard';

const Menu = ({
  classes,
  locked,
  onGetMenu,
  onSetRanking,
  alternativeRanking,
  categories = [],
  fetching = false,
  inStoreStartsAt = null,
  menuId = null,
}) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (menuId) {
      onGetMenu(menuId);
      onSetRanking(false);
      setTabIndex(0);
    }
  }, [menuId, onGetMenu, onSetRanking, setTabIndex]);

  const handleChange = (event, newTabIndex) => {
    if (tabIndex === newTabIndex) {
      return;
    }

    setTabIndex(newTabIndex);
    onSetRanking(!alternativeRanking);
  };

  if (fetching || categories === null) {
    return (
      <Container className={classes.root} maxWidth="md">
        <LoadingMenu numberOfTables={3} rowsPerTable={4} />
      </Container>
    );
  }

  return (
    <Container className={classes.root} maxWidth="md">
      {locked && (
        <Alert icon={<LockIcon fontSize="small" />} className={classes.alertBar} severity="warning">
          This menu is locked and cannot be edited.
        </Alert>
      )}
      <div className={classes.header}>
        <span className={classes.headerTitle}>Menu {menuId}</span>
        <span className={classes.subtitle}>{inStoreStartsAt}</span>
      </div>
      <Tabs
        className={classes.tabs}
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        <Tab label="Primary Rank" />
        <Tab label="Alternative Rank" />
      </Tabs>
      <Divider className={classes.tabsDivider} />
      {categories.map((category) => (
        <CategoryCard
          key={category.id}
          category={category}
          locked={locked}
          alternativeRanking={alternativeRanking}
        />
      ))}
    </Container>
  );
};

export default Menu;
