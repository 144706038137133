import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Container,
  ButtonGroup,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import withStyles from '@mui/styles/withStyles';
import {
  deleteConfigurationKey,
  updateConfigurationKey,
  getAllAppConfigurations,
} from 'redux/appConfigurationTool/actions';
import { useDispatch, useSelector } from 'react-redux';
import { differenceWith, isEqual } from 'lodash';
import { broadcastScrollTopToParent } from 'lib/utils';
import {
  SET_FORM_DATA,
  CONFIG_ENV_ORDER,
  SET_FOCUS_KEY_ID,
  LIGHTEST_BLUE,
  CLEAR_FOCUS_KEY_ID,
} from 'lib/constants';
import styles from './styles';
import AppConfigValue from './AppConfigValue';
import Button from './shared/Button';
import DeleteKeyDialog from './DeleteKeyDialog';
import validateKey from './shared/ValidateKey';

const AppConfigKey = ({ configKey }) => {
  const dispatch = useDispatch();
  const { focusKeyId, showRestartDialog } = useSelector((state) => state.appConfigurationTool);
  const focus = focusKeyId === configKey.id;

  const groupsList = useSelector((state) => state.appConfigurationTool.applicationConfig).map(
    (group) => ({
      groupName: group.groupName,
      groupId: group.groupId,
    })
  );

  const formData = useSelector(
    (state) => state.appConfigurationTool.formData[configKey.id] || configKey
  );
  const setFormData = (unsavedKey) => dispatch({ type: SET_FORM_DATA, unsavedKey });

  const hasValueChanged = (unsavedValue, id) => {
    const { value } = configKey.values.find((v) => v.id === id);
    return unsavedValue !== value;
  };

  const [keyError, setKeyError] = useState('');
  const [showDeleteKeyDialog, setShowDeleteKeyDialog] = useState(false);
  const [showMoveConfirmationDialog, setShowMoveConfirmationDialog] = useState(false);
  const [editingName, setEditingName] = useState(false);
  const nameChanged = formData.name !== configKey.name;

  const updateValueFn = (id) => (value) => {
    setFormData({
      ...formData,
      values: formData.values.map((configValue) =>
        id === configValue.id ? { ...configValue, value } : configValue
      ),
    });
  };

  const handleSave = () => {
    const error = validateKey(formData.key);

    if (error) {
      setKeyError(error);
    } else {
      dispatch(updateConfigurationKey(formData)).then(() => dispatch(getAllAppConfigurations()));
      dispatch({ type: SET_FOCUS_KEY_ID, focusKeyId: formData.id });
      setKeyError('');
    }
  };
  const handleDelete = () => dispatch(deleteConfigurationKey(configKey.id));
  const handleClose = () => setShowDeleteKeyDialog(false);
  const handleChange = (e) => {
    if (keyError) setKeyError(validateKey(e.target.value));
    setFormData({ ...formData, key: e.target.value });
  };
  const handleBlur = (e) => setFormData({ ...formData, key: e.target.value.trim().toUpperCase() });
  const validKeyChange = formData.key !== configKey.key && !keyError;
  const enableSave =
    !!differenceWith(formData.values, configKey.values, isEqual).length ||
    validKeyChange ||
    nameChanged;

  const ref = useRef(null);

  useEffect(() => {
    if (ref === null || ref.current === null || showRestartDialog) return () => {};

    let containerNode;

    if (focus) {
      containerNode = ref.current;

      setTimeout(() => {
        containerNode.scrollIntoView();
        containerNode.style.transition = 'background 2.0s ease';
        containerNode.style.backgroundColor = LIGHTEST_BLUE;
      }, 150);

      setTimeout(() => {
        dispatch({ type: CLEAR_FOCUS_KEY_ID });
      }, 2000);
    }

    return () => {
      if (containerNode === undefined) return;

      containerNode.style.transition = '';
      containerNode.style.backgroundColor = '';
      dispatch({ type: CLEAR_FOCUS_KEY_ID });
    };
  }, [ref, focus, showRestartDialog, dispatch]);

  useEffect(() => {
    if (showDeleteKeyDialog || showMoveConfirmationDialog) {
      broadcastScrollTopToParent();
    }
  });

  return (
    <Container
      sx={focus ? { ...styles.keyContainer, ...styles.highlight } : styles.keyContainer}
      component="fieldset"
      maxWidth="false"
      ref={ref}
    >
      {editingName ? (
        <legend>
          <TextField
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            InputProps={{ sx: { height: '35px' } }}
            sx={
              nameChanged
                ? { ...styles.keyNameInput, ...styles.greenInput }
                : { ...styles.keyNameInput }
            }
          />
          <IconButton
            onClick={() => {
              setEditingName(false);
              setFormData({ ...formData, name: configKey.name });
            }}
          >
            <CloseIcon />
          </IconButton>
        </legend>
      ) : (
        <legend style={styles.legend}>
          {configKey.name}
          <IconButton onClick={() => setEditingName(true)}>
            <EditIcon />
          </IconButton>
        </legend>
      )}

      <FormGroup>
        <Box sx={styles.summary}>
          <TextField
            size="small"
            label="Key"
            variant="standard"
            color="primary"
            InputProps={{ className: validKeyChange ? 'Mui-focused' : '' }}
            InputLabelProps={{ className: validKeyChange ? 'Mui-focused' : '' }}
            sx={validKeyChange ? { ...styles.key, ...styles.greenInput } : styles.key}
            value={formData.key}
            onChange={handleChange}
            error={!!keyError}
            helperText={keyError}
            onBlur={handleBlur}
          />
          <ButtonGroup>
            <Button
              buttonText="Move"
              handleOnClick={() => setShowMoveConfirmationDialog(true)}
              color="warning"
            />
            <Button
              buttonText="Delete"
              handleOnClick={() => setShowDeleteKeyDialog(true)}
              color="error"
            />
            <Button
              buttonText="Save"
              handleOnClick={handleSave}
              color="success"
              disabled={!enableSave}
            />
          </ButtonGroup>
        </Box>
        <Box sx={styles.details}>
          {formData.values
            .sort(
              (a, b) =>
                CONFIG_ENV_ORDER.indexOf(b.environment) - CONFIG_ENV_ORDER.indexOf(a.environment)
            )
            .map((configValue) => (
              <AppConfigValue
                key={configValue.id}
                type={formData.type}
                value={configValue.value}
                environment={configValue.environment}
                updateValue={updateValueFn(configValue.id)}
                changed={hasValueChanged(configValue.value, configValue.id)}
              />
            ))}
        </Box>

        {showDeleteKeyDialog && (
          <DeleteKeyDialog
            open={showDeleteKeyDialog}
            handleConfirm={handleDelete}
            onClose={handleClose}
            configKey={configKey}
          />
        )}
        <Dialog
          open={showMoveConfirmationDialog}
          onClose={() => {
            setShowMoveConfirmationDialog(false);
            setFormData({ ...formData, groupId: configKey.groupId });
          }}
          sx={styles.topDialog}
        >
          <DialogTitle>Move {configKey.key} To Another Group</DialogTitle>
          <DialogContent>
            <DialogActions>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Groups</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={configKey.groupId}
                  label="Groups"
                  onChange={(e) => setFormData({ ...formData, groupId: e.target.value })}
                >
                  {groupsList &&
                    groupsList.map((group) => (
                      <MenuItem key={group.groupId} value={group.groupId}>
                        {group.groupName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </DialogActions>
            <Typography>Choose which Group to move {configKey.key} to</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              buttonText="Cancel"
              handleOnClick={() => {
                setShowMoveConfirmationDialog(false);
                setFormData({ ...formData, groupId: configKey.groupId });
              }}
              color="info"
            />
            <Button buttonText="Save" handleOnClick={handleSave} />
          </DialogActions>
        </Dialog>
      </FormGroup>
    </Container>
  );
};

AppConfigKey.displayName = 'AppConfigKey';

export default withStyles(styles)(AppConfigKey);
