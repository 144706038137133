import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { broadcastScrollSizeToParent } from 'lib/utils';

// Material Components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import Dropdown from '../../shared/Dropdown';
import SaveChangesButton from '../shared/SaveChangesButton';
import SectionHeader from '../shared/SectionHeader';
import TextBox from '../../shared/TextBox';

const CookingFactsTab = ({ classes, setDirty, dirty = false }) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);
  const { meal } = useSelector((state) => state.mealEditForm.meal);

  const [formState, setFormState] = useState({
    prep: meal.prep,
    difficultyLevel: meal.difficultyLevel,
    spiceLevel: meal.spiceLevel,
    daysCookBy: meal.daysCookBy,
    didYouKnow: meal.didYouKnow,
    cookByRangeMin: meal.cookByRangeMin,
    cookByRangeMax: meal.cookByRangeMax,
  });

  const handleFormState = (event) => {
    if (!dirty) {
      setDirty(true);
    }
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  // If being used in an iframe, set the width and height
  // so we don't have a double scrollbar situation.
  useEffect(() => {
    broadcastScrollSizeToParent();
  }, []);

  const selectPrepTimes = useMemo(() => {
    return formOptions.preps.map((prep) => ({ label: prep, value: prep }));
  }, [formOptions.preps]);

  const selectDifficultyLevels = useMemo(() => {
    return formOptions.difficultyLevels.map((difficultyLevel) => ({
      label: difficultyLevel,
      value: difficultyLevel,
    }));
  }, [formOptions.difficultyLevels]);

  const selectSpiceLevels = useMemo(() => {
    return formOptions.spiceLevels.map((spiceLevel) => ({ label: spiceLevel, value: spiceLevel }));
  }, [formOptions.spiceLevels]);

  const selectDaysCookBys = useMemo(() => {
    return formOptions.daysCookBys.map((daysCookBy) => ({ label: daysCookBy, value: daysCookBy }));
  }, [formOptions.daysCookBys]);

  const selectCookByRangeMins = useMemo(() => {
    return formOptions.cookByRangeMins.map((cookByRangeMin) => ({
      label: cookByRangeMin,
      value: cookByRangeMin,
    }));
  }, [formOptions.cookByRangeMins]);

  const selectCookByRangeMaxs = useMemo(() => {
    return formOptions.cookByRangeMaxs.map((cookByRangeMax) => ({
      label: cookByRangeMax,
      value: cookByRangeMax,
    }));
  }, [formOptions.cookByRangeMaxs]);

  return (
    <Grid item xs={6}>
      <Card className={classes.card}>
        <SectionHeader text="Prep" />

        <Dropdown
          label="Prep Time (mins)"
          name="prep"
          onChange={handleFormState}
          value={formState.prep || ''}
          options={selectPrepTimes}
        />

        <Dropdown
          label="Difficulty Level"
          name="difficultyLevel"
          onChange={handleFormState}
          value={formState.difficultyLevel || ''}
          options={selectDifficultyLevels}
        />

        <Dropdown
          label="Spice Level"
          name="spiceLevel"
          onChange={handleFormState}
          value={formState.spiceLevel || ''}
          options={selectSpiceLevels}
        />

        <Dropdown
          label="Days Cook By"
          name="daysCookBy"
          onChange={handleFormState}
          value={formState.daysCookBy || ''}
          options={selectDaysCookBys}
        />

        <Dropdown
          label="Cook By Range Minimum Days"
          name="cookByRangeMin"
          onChange={handleFormState}
          value={formState.cookByRangeMin || ''}
          options={selectCookByRangeMins}
        />

        <Dropdown
          label="Cook By Range Maximum Days"
          name="cookByRangeMax"
          onChange={handleFormState}
          value={formState.cookByRangeMax || ''}
          options={selectCookByRangeMaxs}
        />

        <TextBox
          helperText="For use in the 'Did You Know?' section of the recipe card"
          label="Did You Know?"
          multiline
          name="didYouKnow"
          onChange={handleFormState}
          value={formState.didYouKnow || ''}
        />
      </Card>

      <div className={classes.buttonContainer}>
        <SaveChangesButton mealParams={formState} mealId={meal.id} />
      </div>
    </Grid>
  );
};

export default CookingFactsTab;
