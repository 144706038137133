// Dependencies
import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';

// Lodash
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';

// Helpers
import { compareTextBlobs } from 'lib/helpers/comparisonTool';
import { sanitizedDietaryInfo } from 'lib/helpers/comparisonTool/dietaryInfo';

// Material
import Grid from '@mui/material/Grid';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import withStyles from '@mui/styles/withStyles';
import styles from './styles';

const DietaryRow = ({ viewableMeals, classes }) => {
  // State methods
  const [hasDifferences, setHasDifferences] = useState(false);
  const [dietaryHighlightInfo, setDietaryHighlightInfo] = useState({});

  // Lifecycle methods
  useMemo(() => {
    const checkDifferences =
      viewableMeals.length !== 0
        ? compareTextBlobs(sanitizedDietaryInfo(viewableMeals.map((meal) => meal.dietaryInfo)))
        : false;
    setHasDifferences(checkDifferences);
  }, [viewableMeals]);

  useEffect(() => {
    const allDietaryInfo = map(viewableMeals, (meal) => {
      return meal.dietaryInfo;
    });

    const groupedDietaryInfo = () => {
      const consolidatedDietaryInfo = {
        dietaryPreferences: [],
        dietaryRestrictions: [],
      };
      forEach(allDietaryInfo, (dietaryInfo) => {
        forEach(dietaryInfo, (value, key) => {
          consolidatedDietaryInfo[key].push(value);
        });
      });
      return consolidatedDietaryInfo;
    };

    const buildDietaryHighlightInfo = () => {
      return mapValues(groupedDietaryInfo(), (groupedValue) => {
        const superset = uniq(flatten(groupedValue));
        const sharedOptions = intersection(...groupedValue);
        const highlightInfo = {};
        forEach(superset, (dietaryOption) => {
          const shouldHighlight = !includes(sharedOptions, dietaryOption);
          highlightInfo[dietaryOption] = shouldHighlight;
        });
        return highlightInfo;
      });
    };

    setDietaryHighlightInfo(buildDietaryHighlightInfo());
  }, [viewableMeals]);

  const renderDietaryInfo = (dietaryInfoInput) => {
    return map(dietaryInfoInput, (dietaryListContent, dietaryListHeader) => {
      const highlight = (highlightItem) => {
        return (
          dietaryHighlightInfo[dietaryListHeader] &&
          dietaryHighlightInfo[dietaryListHeader][highlightItem]
        );
      };
      return (
        <Grid key={`${dietaryListHeader}`} container>
          <Grid item xs={4}>
            <span className={classes.listLabel}>{startCase(dietaryListHeader)}</span>
          </Grid>
          <Grid className={classes.dietaryListContent} key={`${dietaryListHeader}`} item xs={6}>
            {dietaryListContent.map((listItem) => {
              return (
                <div
                  className={clsx(classes.listContent, highlight(listItem) && classes.highlight)}
                  key={`${listItem}`}
                >
                  {listItem}
                </div>
              );
            })}
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <TableRow>
      <TableCell
        className={clsx(classes.iconCell, hasDifferences && classes.tableRowGreenBorder)}
      />
      <TableCell className={classes.rowLabel}>Dietary</TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`dietaryInfo.${meal.versionId}`}
          className={clsx(classes.dietaryInfoCell, meal.inactive && classes.greyed)}
        >
          <Grid container rowSpacing={1} className={classes.dietaryInfoRow}>
            {renderDietaryInfo(meal.dietaryInfo)}
          </Grid>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default withStyles(styles)(DietaryRow);
