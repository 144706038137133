import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

// Material Components
import { Alert, Button, Grid, Modal, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { broadcastScrollTopToParent, cleanArray } from 'lib/utils';
import { getFormOptions } from 'redux/compoundIngredientRecipeForm/actions';
import {
  getCompoundIngredientRecipe,
  clearSuccessAndErrorMessages,
} from 'redux/compoundIngredientRecipeForm/compoundIngredientRecipe/actions';

import { Loading } from 'components/shared';
import PresenceIndicator from 'components/shared/Presence/PresenceIndicator';
import BasicsTab from './BasicsTab';
import PrepInstructionsTab from './PrepInstructionsTab';
import ComponentIngredientsTab from './ComponentIngredientsTab';
import NutritionTab from './NutritionTab';
import PackingFacilitiesTab from './PackingFacilitiesTab';

const CompoundIngredientRecipeForm = ({ action, classes }) => {
  const error = useSelector(
    (state) =>
      state.compoundIngredientRecipeForm.compoundIngredientRecipe.error ||
      state.compoundIngredientRecipeForm.formOptions.error
  );

  const errorMessages = useSelector((state) =>
    cleanArray([
      state.compoundIngredientRecipeForm.compoundIngredientRecipe.errorMessage,
      state.compoundIngredientRecipeForm.formOptions.errorMessage,
    ])
  );

  const successMessages = useSelector((state) =>
    cleanArray([state.compoundIngredientRecipeForm.compoundIngredientRecipe.successMessage])
  );

  const fetching = useSelector(
    (state) =>
      state.compoundIngredientRecipeForm.compoundIngredientRecipe.fetching ||
      state.compoundIngredientRecipeForm.formOptions.fetching
  );

  const { formOptions } = useSelector((state) => state.compoundIngredientRecipeForm.formOptions);

  const { compoundIngredientRecipe, success } = useSelector(
    (state) => state.compoundIngredientRecipeForm.compoundIngredientRecipe
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { compoundIngredientRecipeId } = useParams();

  const [currentTab, setCurrentTab] = useState(0);
  const [nextTab, setNextTab] = useState(0);
  const [dirty, setDirty] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const tabs = {
    0: BasicsTab,
    1: ComponentIngredientsTab,
    2: PrepInstructionsTab,
    3: NutritionTab,
    4: PackingFacilitiesTab,
  };

  const handleSetCurrentTab = (_event, newTabValue) => {
    if (dirty) {
      setModalOpen(true);
      setNextTab(newTabValue);
    } else if (newTabValue !== null) {
      dispatch(getCompoundIngredientRecipe(compoundIngredientRecipeId));
      setCurrentTab(newTabValue);
    }
  };

  const handleDiscardChanges = () => {
    setModalOpen(false);
    setDirty(false);
    dispatch(getCompoundIngredientRecipe(compoundIngredientRecipeId));
    setCurrentTab(nextTab);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const renderTabContent = () => {
    const TabComponent = tabs[currentTab];
    const shouldRenderTabComponent =
      (action === 'edit' && compoundIngredientRecipe && formOptions) ||
      (action === 'new' && formOptions);

    return shouldRenderTabComponent ? (
      <TabComponent action={action} dirty={dirty} setDirty={setDirty} />
    ) : null;
  };

  const renderErrorMessages = () => {
    return errorMessages.map((message) => {
      return (
        <Alert key={message} severity="error" variant="filled">
          {message}
        </Alert>
      );
    });
  };

  const renderSuccessMessages = () => {
    return successMessages.map((message) => {
      return (
        <Alert key={message} severity="success" variant="filled">
          {message}{' '}
          <a
            className={classes.viewCompoundLink}
            href={compoundIngredientRecipe.adminUrl}
            target="_parent"
          >
            View Compound Ingredient
          </a>
        </Alert>
      );
    });
  };

  const renderModal = () => {
    return (
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Paper className={classes.modal}>
          <h3>Be sure to save your changes before switching to another tab.</h3>
          <div className={classes.buttonRow}>
            <Button color="primary" onClick={handleCloseModal} variant="outlined">
              OK
            </Button>
            <Button color="secondary" onClick={handleDiscardChanges} variant="outlined">
              Discard Changes
            </Button>
          </div>
        </Paper>
      </Modal>
    );
  };

  const renderForm = () => {
    return (
      <Grid container spacing={3} className={classes.container}>
        {compoundIngredientRecipeId && ( // only show presence indicator if we are editing a CIR (not creating a new one)
          <PresenceIndicator
            channelName={`${compoundIngredientRecipeId}-compound-ingredient-recipe-form`}
          />
        )}
        <Grid item xs={12}>
          <ToggleButtonGroup
            color="primary"
            disabled={action === 'new'}
            exclusive
            onChange={handleSetCurrentTab}
            value={currentTab}
          >
            <ToggleButton value={0}>The Basics</ToggleButton>
            <ToggleButton value={1}>Component Ingredients</ToggleButton>
            <ToggleButton value={2}>Prep Instructions</ToggleButton>
            <ToggleButton value={3}>Nutrition</ToggleButton>
            <ToggleButton value={4}>Packing Facilities</ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        {fetching ? (
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Loading />
          </Grid>
        ) : (
          renderTabContent()
        )}
      </Grid>
    );
  };

  useEffect(() => {
    if (success || error) {
      setDirty(false);
      broadcastScrollTopToParent();
    }
  }, [success, error]);

  useEffect(() => {
    if (compoundIngredientRecipe === null && action === 'edit') {
      dispatch(getCompoundIngredientRecipe(compoundIngredientRecipeId));
    }
  }, [action, compoundIngredientRecipe, compoundIngredientRecipeId, dispatch]);

  // if the new recipe has successfully saved and been returned as json,
  // redirect so that the action will be 'edit' instead of 'new'
  useEffect(() => {
    if (compoundIngredientRecipe && action === 'new') {
      navigate(`/compound-ingredient-recipe/${compoundIngredientRecipe.id}/edit`, {});
    }
  }, [action, compoundIngredientRecipe, navigate]);

  useEffect(() => {
    if (formOptions === null) {
      dispatch(getFormOptions());
    }
  }, [formOptions, dispatch]);

  useEffect(() => {
    dispatch(clearSuccessAndErrorMessages());
  }, [currentTab, dispatch]);

  return (
    <>
      {success && renderSuccessMessages()}
      {error && renderErrorMessages()}
      {renderModal()}
      {renderForm()}
    </>
  );
};

export default CompoundIngredientRecipeForm;
