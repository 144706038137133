import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { postCompoundIngredientRecipe } from 'redux/compoundIngredientRecipeForm/actions';

import Button from '@mui/material/Button';

const SaveChangesButton = ({
  compoundIngredientRecipeParams,
  compoundIngredientRecipeId = null,
  editsLocked = false,
}) => {
  const isSubmitting = useSelector(
    (state) => state.compoundIngredientRecipeForm.compoundIngredientRecipe.submitting
  );

  const dispatch = useDispatch();

  const postChanges = () => {
    dispatch(
      postCompoundIngredientRecipe({ compoundIngredientRecipeId, compoundIngredientRecipeParams })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    postChanges();
  };

  return (
    <Button
      color="primary"
      onClick={handleSubmit}
      disabled={isSubmitting || editsLocked}
      variant="contained"
    >
      Save Changes
    </Button>
  );
};

export default SaveChangesButton;
