import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import MealInfo from '../MealInfo';
import PlaceholderInfo from '../PlaceholderInfo';

const MealCard = ({ menuSelection, index, displayGroup }) => {
  return (
    <Draggable draggableId={String(menuSelection.id)} index={index}>
      {(provided) => (
        <div
          data-testid="mealCard"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {menuSelection.mealId === 0 ? (
            <PlaceholderInfo placeholder={menuSelection} displayGroup={displayGroup} />
          ) : (
            <MealInfo menuSelection={menuSelection} />
          )}
        </div>
      )}
    </Draggable>
  );
};

export default MealCard;
