import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import { BACKGROUND_GREY, WHITE } from 'lib/constants';
import SectionHeader from '../shared/SectionHeader';

const NutritionTable = ({ classes, compoundIngredient = null }) => {
  const facts = compoundIngredient.nutritionFacts;

  return (
    <div>
      <div className={classes.tableHeader}>
        <SectionHeader text="Calculated Nutrition" />
      </div>
      <TableContainer className={classes.container}>
        <Table sx={{ minWidth: 450 }} size="small" aria-label="simple table">
          <TableBody>
            {Object.entries(facts).map(([key, value], index) => {
              const rowBackgroundColor = index % 2 === 0 ? BACKGROUND_GREY : WHITE;
              return (
                <TableRow key={key} style={{ backgroundColor: rowBackgroundColor }}>
                  <TableCell className={classes.tableLabel}>{key.replace(/_/g, ' ')}</TableCell>
                  <TableCell className={classes.tableValue}>{value}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default NutritionTable;
