import { createContext, React } from 'react';
import { useFormState } from 'components/BehavioralDiscountTool/formUtils';

const BehavioralDiscountFormContext = createContext();

const BehavioralDiscountFormProvider = ({ children }) => {
  const { formState, actions } = useFormState();

  return (
    <BehavioralDiscountFormContext.Provider value={{ formState, actions }}>
      {children}
    </BehavioralDiscountFormContext.Provider>
  );
};
export { BehavioralDiscountFormProvider, BehavioralDiscountFormContext };
