import React from 'react';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

const GeographyPill = ({ classes, pill, onDelete = null }) => {
  if (pill.errorMessage !== undefined) {
    return (
      <Tooltip title={pill.errorMessage}>
        <Chip label={pill.code} onDelete={onDelete} className={classes.error} />
      </Tooltip>
    );
  }
  if (pill.warningMessage !== undefined) {
    return (
      <Tooltip title={pill.warningMessage}>
        <Chip label={pill.code} onDelete={onDelete} className={classes.warning} />
      </Tooltip>
    );
  }

  return <Chip onDelete={onDelete} label={pill.code} />;
};

export default GeographyPill;
