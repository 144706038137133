import React, { useState, useEffect, useReducer, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DELETED, OZ_WEIGHT } from 'lib/constants';
import useAllowed from 'lib/useAllowed';

import {
  findAssignedCompoundIngredientBeingReplaced,
  findPackingFacilitiesFromIaReplacement,
  findPackingFacilitiesFromCiraReplacement,
  findDuplicatedFacilitiesForReplacement,
} from 'lib/helpers/mealEditForm/replacementsTabValidator';
import { broadcastScrollSizeToParent } from 'lib/utils';
import isEmpty from 'lodash/isEmpty';

// Material Components
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import SaveChangesButton from '../shared/SaveChangesButton';
import SectionHeader from '../shared/SectionHeader';
import ReplacementForm from './ReplacementForm';
import CompactIngredientCard from './CompactIngredientCard';

import ReplacementsByFacilityValidationDialog from './ReplacementsByFacilityValidationDialog';

import { ACTIONS, reducer, ReplacementsTabContext } from '../reducer';

const initialStateFromMeal = (meal) => ({
  formState: {
    ingredientAssignments: meal.ingredientAssignments,
    replacementCiras: meal.replacementCiras,
  },
  showReplacementForm: false,
  showValidationModal: false,
  showWarningModal: false,
  allCardsExpanded: false,
  expandedCards: [],
  collapsedCards: [],
  selectedState: {},
  selectedIndex: null,
  replacementState: {},
  replacementIndex: null,
});

const ReplacementsTab = ({ classes, setDirty, dirty = false }) => {
  const { meal } = useSelector((state) => state.mealEditForm.meal);
  const [state, dispatch] = useReducer(reducer, meal, initialStateFromMeal);
  const [duplicateFacilityReplacementErrors, setDuplicateFacilityReplacementErrors] = useState({});
  const {
    formState,
    showReplacementForm,
    showValidationModal,
    showWarningModal,
    allCardsExpanded,
    selectedState,
    selectedIndex,
    replacementState,
    replacementIndex,
  } = state;

  useEffect(() => {
    // If being used in an iframe, set the width and height so we don't have a double scrollbar situation.
    broadcastScrollSizeToParent();
  }, [allCardsExpanded]);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  const canEditReplacements = useAllowed('editReplacements');

  const handleExpandAll = () => {
    dispatch({
      type: ACTIONS.TOGGLE_EXPAND_ALL,
      payload: { value: !allCardsExpanded },
    });
  };

  const handleFormState = (event) => {
    if (!dirty) {
      setDirty(true);
    }
    const { name, value } = event.target;
    dispatch({ type: ACTIONS.UPDATE_FORM_STATE, payload: { name, value } });
  };

  const validateUniqueReplacementsByFacility = () => {
    const { ingredientAssignments, replacementCiras } = formState;
    const selectedCompoundIngredientRecipeId =
      selectedState.compoundIngredientRecipeId ||
      selectedState.ingredient.compoundIngredientRecipeId;
    const assignedCompoundIngredientRequiresValidation =
      findAssignedCompoundIngredientBeingReplaced(
        ingredientAssignments,
        replacementCiras,
        selectedCompoundIngredientRecipeId
      );

    if (assignedCompoundIngredientRequiresValidation) {
      // Retrieve the packing facilities from
      //  IA replacement Level and CIRA replacement level
      const packingFacilitiesFromIaReplacement = findPackingFacilitiesFromIaReplacement(
        assignedCompoundIngredientRequiresValidation
      );
      const packingFacilitiesFromCiraReplacement = findPackingFacilitiesFromCiraReplacement(
        replacementCiras,
        selectedCompoundIngredientRecipeId
      );

      // Check to see if incoming replacement's facilities
      // are already replaced at EITHER
      // the basic ingredient level OR componenent ingredient level
      const duplicatedPackingFacilitiesForReplacement = findDuplicatedFacilitiesForReplacement({
        replacementState,
        selectedState,
        assignedCompoundIngredientRequiresValidation,
        packingFacilitiesFromCiraReplacement,
        packingFacilitiesFromIaReplacement,
      });

      if (!isEmpty(duplicatedPackingFacilitiesForReplacement)) {
        const { id: assignedCompoundIngredientId, name: assignedCompoundIngredientName } =
          assignedCompoundIngredientRequiresValidation.ingredient;
        const { id: selectedIngredientId, name: selectedIngredientName } = selectedState.ingredient;

        return [
          false,
          {
            assignedCompoundIngredient: {
              id: assignedCompoundIngredientId,
              name: assignedCompoundIngredientName,
            },
            selectedIngredient: { id: selectedIngredientId, name: selectedIngredientName },
            duplicatedPackingFacilitiesForReplacement,
          },
        ];
      }
    }

    return [true, {}];
  };

  const validateReplacementIngredient = () => {
    if (!selectedState.compoundIngredientRecipeId) return true;

    const { measure: selectedMeasure, ingredient: selectedIngredient } = selectedState;
    const { measure: replacementMeasure, ingredient: replacementIngredient } = replacementState;

    if (selectedMeasure !== replacementMeasure) return false;
    if (selectedIngredient.standardUnit !== replacementIngredient.standardUnit) return false;

    if (
      selectedIngredient.standardUnit !== OZ_WEIGHT ||
      replacementIngredient.standardUnit !== OZ_WEIGHT
    ) {
      if (selectedIngredient.volumeToWeight !== replacementIngredient.volumeToWeight) {
        return false;
      }
    }
    return true;
  };

  const handleUpdateIngredientAssignments = (updatedIngredientAssignmentState) => {
    const newFormStateIngredientAssignments = [...formState.ingredientAssignments];
    newFormStateIngredientAssignments[selectedIndex] = updatedIngredientAssignmentState;

    handleFormState({
      target: { name: 'ingredientAssignments', value: newFormStateIngredientAssignments },
    });
  };

  const integrateReplacementIngredientState = () => {
    let updatedReplacements;
    let updatedIngredientAssignmentState;

    if (replacementIndex !== null) {
      updatedReplacements = [...selectedState.replacementIngredients];
      updatedReplacements[replacementIndex] = replacementState;

      updatedIngredientAssignmentState = {
        ...selectedState,
        replacementIngredients: updatedReplacements,
      };
    } else {
      updatedIngredientAssignmentState = {
        ...selectedState,
        replacementIngredients: [...selectedState.replacementIngredients, replacementState],
      };
    }

    handleUpdateIngredientAssignments(updatedIngredientAssignmentState);
  };

  const integrateReplacementCiraState = () => {
    let updatedReplacements;

    if (replacementIndex !== null) {
      updatedReplacements = [...formState.replacementCiras];
      updatedReplacements[replacementIndex] = replacementState;
    } else {
      updatedReplacements = [...formState.replacementCiras, replacementState];
    }

    handleFormState({
      target: { name: 'replacementCiras', value: updatedReplacements },
    });
  };

  const integrateReplacementState = () => {
    if (selectedState.compoundIngredientRecipeId) {
      integrateReplacementCiraState();
    } else {
      integrateReplacementIngredientState();
    }
  };

  const handleDoneClick = (e) => {
    e.preventDefault();
    const [isValidReplacementByFacility, validatedReplacementsData] =
      validateUniqueReplacementsByFacility();
    const isValidReplacementIngredient = validateReplacementIngredient();

    if (isValidReplacementIngredient && isValidReplacementByFacility) {
      integrateReplacementState();
      dispatch({ type: ACTIONS.HIDE_REPLACEMENT_FORM });
    } else if (!isValidReplacementIngredient) {
      dispatch({ type: ACTIONS.SHOW_VALIDATION_MODAL });
    } else if (!isValidReplacementByFacility) {
      setDuplicateFacilityReplacementErrors(validatedReplacementsData);
    }
  };

  const handleDeleteReplacement = () => {
    const deletedReplacementState = {
      ...replacementState,
      [DELETED]: true,
    };
    dispatch({
      type: ACTIONS.SET_REPLACEMENT_STATE,
      payload: { value: deletedReplacementState },
    });

    if (selectedState.compoundIngredientRecipeId) {
      let updatedReplacements;

      if (replacementIndex !== null) {
        updatedReplacements = [...formState.replacementCiras];
        updatedReplacements[replacementIndex] = deletedReplacementState;
      } else {
        updatedReplacements = [...formState.replacementCiras, deletedReplacementState];
      }
      handleFormState({
        target: { name: 'replacementCiras', value: updatedReplacements },
      });
    } else {
      let updatedReplacements;
      let updatedIngredientAssignmentState;

      if (replacementIndex !== null) {
        updatedReplacements = [...selectedState.replacementIngredients];
        updatedReplacements[replacementIndex] = deletedReplacementState;

        updatedIngredientAssignmentState = {
          ...selectedState,
          replacementIngredients: updatedReplacements,
        };
      } else {
        updatedIngredientAssignmentState = {
          ...selectedState,
          replacementIngredients: [
            ...selectedState.replacementIngredients,
            deletedReplacementState,
          ],
        };
      }
      handleUpdateIngredientAssignments(updatedIngredientAssignmentState);
    }
    dispatch({ type: ACTIONS.HIDE_WARNING_MODAL_AND_REPLACEMENT_FORM });
  };

  const renderValidationModal = () => (
    <Modal
      open={showValidationModal}
      onClose={() => dispatch({ type: ACTIONS.HIDE_VALIDATION_MODAL })}
    >
      <Paper className={classes.modal}>
        <h3>Component Ingredient replacements that change total input weight are not allowed.</h3>
        <p>
          Please select a replacement with the same unit, measure, and volume to weight as the
          original ingredient.
        </p>
        <p>
          For exceptions that require a change to Compound Ingredient proportions, please create a
          new Shorts and Replacement version of the Compound Ingredient.
        </p>
      </Paper>
    </Modal>
  );

  const renderModal = () => (
    <Modal open={showWarningModal} onClose={() => dispatch({ type: ACTIONS.HIDE_WARNING_MODAL })}>
      <Paper className={classes.modal}>
        <h3>Are you sure you want to delete this replacement?</h3>
        <p>This action cannot be undone</p>
        <div className={classes.buttonRow}>
          <Button color="secondary" onClick={() => dispatch({ type: ACTIONS.HIDE_WARNING_MODAL })}>
            Cancel
          </Button>
          <Button
            className={classes.deleteReplacementButton}
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteReplacement}
          >
            Remove Replacement
          </Button>
        </div>
      </Paper>
    </Modal>
  );

  const renderIngredientCards = () =>
    formState.ingredientAssignments.map((ingredientAssignment, index) => {
      const key = `ingredient-assignment-${index}`;
      return (
        <Grid item xs={12} className={classes.ml_24} key={key}>
          <CompactIngredientCard
            classes={classes}
            ingredientAssignment={ingredientAssignment}
            ingredientAssignmentIndex={index}
          />
        </Grid>
      );
    });

  return (
    <ReplacementsTabContext.Provider value={contextValue}>
      <Grid item xs={12} className={classes.replacementFormHeader}>
        <SectionHeader
          text={
            showReplacementForm
              ? `${replacementIndex !== null ? 'Edit' : 'Add'} Replacement Ingredient`
              : 'Assigned Ingredients'
          }
        />
        {showReplacementForm ? (
          <IconButton
            onClick={() => dispatch({ type: ACTIONS.HIDE_REPLACEMENT_FORM })}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <Button onClick={() => handleExpandAll()}>
            {allCardsExpanded ? 'Collapse All' : 'Expand All'}
          </Button>
        )}
      </Grid>
      {showReplacementForm ? (
        <form onSubmit={handleDoneClick}>
          <div className={classes.replacementFormContainer}>
            <ReplacementForm classes={classes} inactiveForm />
            <ReplacementForm classes={classes} inactiveForm={false} />
          </div>
          <div className={classes.buttonRow}>
            <Button
              color="secondary"
              variant="outlined"
              className={classes.deleteButton}
              startIcon={<DeleteIcon />}
              onClick={() => dispatch({ type: ACTIONS.SHOW_WARNING_MODAL })}
            >
              Remove Replacement
            </Button>
            <Button color="primary" variant="contained" type="submit">
              Done
            </Button>
            <Button
              className={classes.cancelButton}
              color="secondary"
              onClick={() => dispatch({ type: ACTIONS.HIDE_REPLACEMENT_FORM })}
              variant="outlined"
            >
              Cancel
            </Button>
          </div>
          {renderModal()}
          {renderValidationModal()}
          <ReplacementsByFacilityValidationDialog
            errors={duplicateFacilityReplacementErrors}
            handleErrors={setDuplicateFacilityReplacementErrors}
          />
        </form>
      ) : (
        <>
          {renderIngredientCards()}
          <Grid item xs={12} className={classes.ml_24}>
            {canEditReplacements && <SaveChangesButton mealParams={formState} mealId={meal.id} />}
          </Grid>
        </>
      )}
    </ReplacementsTabContext.Provider>
  );
};

export default ReplacementsTab;
