// Dependencies
import React, { useState } from 'react';
import parse from 'html-react-parser';
import clsx from 'clsx';

// Material
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// Constants
import { COLLAPSED_RECIPE_CARD_HEIGHT } from 'lib/constants';

const RecipeStepCard = ({ classes, recipeStep, highlightProperties }) => {
  // State
  const [imageError, setImageError] = useState(false);
  const [expandDescription, setExpandDescription] = useState(false);

  // Handler methods
  const showImageError = () => {
    setImageError(true);
  };
  const handleExpandDescriptionClick = () => {
    setExpandDescription(!expandDescription);
  };

  return (
    <Card className={classes.card} key={`${recipeStep.id}-${recipeStep.title}`}>
      {imageError ? (
        <div className={classes.imageErrorContent}> Image not found </div>
      ) : (
        <CardMedia
          component="img"
          alt={recipeStep.title}
          image={recipeStep.url}
          title={recipeStep.title}
          className={classes.cardMedia}
          onError={showImageError}
        />
      )}
      <CardContent className={classes.cardContent}>
        <Collapse in={expandDescription} collapsedSize={COLLAPSED_RECIPE_CARD_HEIGHT}>
          <div className={clsx(classes.cardTitle, highlightProperties.title && classes.highlight)}>
            {recipeStep.title}
          </div>
          <div
            className={clsx(
              classes.cardDescription,
              highlightProperties.description && classes.highlight
            )}
          >
            {parse(recipeStep.description)}
          </div>
        </Collapse>
      </CardContent>
      <CardActions>
        <IconButton className={classes.icon} size="small" onClick={handleExpandDescriptionClick}>
          {expandDescription ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default RecipeStepCard;
