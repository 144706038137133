import React from 'react';

// Material
import Grid from '@mui/material/Grid';

// Components
import AppHeader from '../shared/AppHeader';
import AppLink from '../shared/AppLink';
import TeamTitle from './TeamTitle';

const AppLandingPage = ({ classes }) => {
  return (
    <div className={classes.root}>
      <AppHeader text="Internal Apps" />
      <Grid container justifyContent="center" spacing={10} className={classes.container}>
        <Grid item>
          <TeamTitle title="CX Apps" />
          <AppLink text="Behavioral Discount Tool" url="/behavioral-discount-tool/new" />
        </Grid>
        <Grid item>
          <TeamTitle title="Culinary Apps" />
          <AppLink text="Culinary Version Comparison Tool" url="/comparison-tool" />
        </Grid>
        <Grid item>
          <TeamTitle title="Customer Support Apps" />
          <AppLink text="Disputed Charges" url="/disputed-charges" />
        </Grid>
        <Grid item>
          <TeamTitle title="Retail Apps" />
          <AppLink text="Retail Apps" url="/retail" />
        </Grid>
      </Grid>
    </div>
  );
};

export default AppLandingPage;
