import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import keys from 'lodash/keys';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';

import SectionHeader from '../shared/SectionHeader';
import TextBox from '../../shared/TextBox';

const WhatYouNeed = ({
  classes,
  formState,
  handleFormState,
  setFormState,
  setDirty,
  dirty = false,
}) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);

  const defaultCheckboxState = {};
  formOptions.whatYouNeeds.forEach((need) => {
    defaultCheckboxState[need] = formState.whatYouNeed.includes(need);
  });

  const [checkboxState, setCheckboxState] = useState(defaultCheckboxState);

  const handleChange = (event) => {
    const newCheckboxState = { ...checkboxState, [event.target.name]: event.target.checked };
    setCheckboxState(newCheckboxState);

    const neededItems = keys(newCheckboxState).filter((key) => newCheckboxState[key] === true);
    const newFormState = { whatYouNeed: neededItems };

    if (!neededItems.includes('Olive Oil')) {
      newFormState.oliveOilQuantity = 0;
    }
    if (!neededItems.includes('Salt')) {
      newFormState.saltQuantity = 0;
    }

    if (!dirty && neededItems !== formState.whatYouNeed) {
      setDirty(true);
    }

    setFormState({ ...formState, ...newFormState });
  };

  const renderNeed = (need) => {
    return (
      <Grid item key={need}>
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkbox}
              checked={checkboxState[need]}
              disableRipple
              name={need}
              onChange={handleChange}
            />
          }
          label={<span className={classes.inputLabel}>{need}</span>}
        />
      </Grid>
    );
  };

  return (
    <Paper className={classes.paper}>
      <SectionHeader text="What You Need" />

      <Grid container>
        <Grid item xs={6}>
          {formOptions.whatYouNeeds.map((need) => renderNeed(need))}
        </Grid>

        <Grid item xs={6}>
          {checkboxState['Olive Oil'] === true ? (
            <TextBox
              endAdornment={<InputAdornment position="end">tsp</InputAdornment>}
              fullWidth
              label="Olive Oil Quantity"
              name="oliveOilQuantity"
              onChange={handleFormState}
              required
              value={formState.oliveOilQuantity || 0}
            />
          ) : null}

          {/* eslint-disable-next-line dot-notation */}
          {checkboxState['Salt'] === true ? (
            <TextBox
              endAdornment={<InputAdornment position="end">tsp</InputAdornment>}
              fullWidth
              label="Salt Quantity"
              name="saltQuantity"
              onChange={handleFormState}
              required
              value={formState.saltQuantity || 0}
            />
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default WhatYouNeed;
