import { FVC_BALANCING_TYPES } from 'lib/constants';
import React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const BalancerSelector = ({ classes, value = null, onChange = () => {} }) => {
  return (
    <ToggleButtonGroup label="Balancer" exclusive size="large">
      {FVC_BALANCING_TYPES.map((b) => {
        return (
          <ToggleButton
            onClick={() => onChange(b)}
            selected={value && b.value === value.value}
            key={b.value}
            value={b}
            className={classes.balancerButton}
          >
            {b.display_name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default BalancerSelector;
