import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ConfirmReplacementsDirections from './ConfirmReplacementsDirections';
import styles from '../styles';

const ConfirmReplacementsDialog = ({ open, onClose, ingredientSwapAction }) => {
  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={styles.dialogHeader}>Confirm Swap With Replacements</DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <ConfirmReplacementsDirections />
        <DialogActions sx={styles.dialogActions}>
          <Button onClick={() => handleCancel()} color="error" variant="outlined">
            Cancel Swap
          </Button>
          <Button onClick={() => ingredientSwapAction()} color="success" variant="outlined">
            Confirm Swap
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmReplacementsDialog;
