import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { broadcastScrollSizeToParent } from 'lib/utils';
import {
  Grid,
  Paper,
  Tab,
  Tabs,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Skeleton,
} from '@mui/material';
import {
  CLEAR_FOCUS_KEY_ID,
  CLOSE_DEPLOY_LOCK_DIALOG,
  CLOSE_RESTART_DIALOG,
  SET_FOCUS_KEY_ID,
  UPDATE_TAB_INDEX,
} from 'lib/constants';
import { restartNow } from 'redux/appConfigurationTool/actions';
import AppConfigGroup from './AppConfigGroup';
import SharedButton from './shared/Button';
import styles from './styles';

const AppConfigurationTool = ({ classes, applicationConfig, onGetAllAppConfigurations }) => {
  const { fetching, tabIndex, showRestartDialog, showDeployLockDialog } = useSelector(
    (state) => state.appConfigurationTool
  );

  useEffect(() => {
    const interval = setInterval(() => {
      broadcastScrollSizeToParent();
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!applicationConfig.length && !fetching) {
      onGetAllAppConfigurations();
    }
  });

  const dispatch = useDispatch();

  const handleChange = (_event, newTabIndex) => {
    dispatch({ type: CLEAR_FOCUS_KEY_ID });
    dispatch({ type: UPDATE_TAB_INDEX, newTabIndex });
  };
  const handleCloseRestartDialog = () => dispatch({ type: CLOSE_RESTART_DIALOG });
  const handleCloseDeployLockDialog = () => dispatch({ type: CLOSE_DEPLOY_LOCK_DIALOG });

  const handleRestartNow = () => {
    dispatch(restartNow());
    dispatch({ type: CLOSE_RESTART_DIALOG });
  };

  const handleSearchSelect = (_event, option) => {
    if (option === undefined || option === null) return;

    dispatch({ type: SET_FOCUS_KEY_ID, focusKeyId: option.id });
    dispatch({ type: UPDATE_TAB_INDEX, newTabIndex: option.tabIndex });

    setTimeout(() => {
      dispatch({ type: CLEAR_FOCUS_KEY_ID });
    }, 2000);
  };

  return (
    <Container className={classes.bodyWrapper} maxWidth="false">
      <Paper className={classes.root} elevation={3}>
        {fetching ? (
          <Skeleton />
        ) : (
          applicationConfig.length && (
            <Grid container direction="row" spacing={2} alignItems="start">
              <Grid item xs={2} lg={2}>
                <Tabs onChange={handleChange} value={tabIndex} orientation="vertical" centered>
                  {applicationConfig.map((configGroup) => (
                    <Tab label={configGroup.groupName} key={configGroup.groupId} />
                  ))}
                </Tabs>
              </Grid>
              <Grid item xs={10} lg={10}>
                <AppConfigGroup
                  configGroup={applicationConfig[tabIndex]}
                  handleSearchSelect={handleSearchSelect}
                />
              </Grid>
            </Grid>
          )
        )}
      </Paper>

      <Dialog
        open={showRestartDialog && !fetching}
        sx={styles.topDialog}
        maxWidth="sm"
        fullWidth
        onClose={handleCloseRestartDialog}
      >
        <DialogContent>
          homechef-prod and homechef-admin are queued for restart in two minutes. Charge and label
          locks will be obeyed. Further changes will extend this timer. Note that it may take up to
          ten minutes for all dynos to finish restarting depending on site traffic.
        </DialogContent>
        <DialogActions>
          <SharedButton buttonText="Restart Now" handleOnClick={handleRestartNow} />
          <SharedButton buttonText="OK" handleOnClick={handleCloseRestartDialog} />
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDeployLockDialog}
        sx={styles.topDialog}
        maxWidth="sm"
        fullWidth
        onClose={handleCloseDeployLockDialog}
      >
        <DialogContent>
          Cannot trigger an immediate restart while deploy locks are present. The application will
          restart as soon as deploys are unlocked. Note that it may take up to ten minutes for all
          dynos to finish restarting depending on site traffic.
        </DialogContent>
        <DialogActions>
          <SharedButton buttonText="OK" handleOnClick={handleCloseDeployLockDialog} />
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AppConfigurationTool;
