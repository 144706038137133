// Dependencies
import React from 'react';

// Material Components
import Grid from '@mui/material/Grid';

const SearchResults = ({ classes, swapInIngredient = null }) => {
  // Rendering
  return (
    <>
      <hr className={classes.hr} />
      <h4 className={classes.name}>{swapInIngredient.name}</h4>
      <h5 className={classes.brandName}>{swapInIngredient.brandName}</h5>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.details}
      >
        <Grid item xs={6}>
          <p className={classes.detailTitle}>Standard Unit</p>
          <p className={classes.detailValue}>{swapInIngredient.standardUnit}</p>
        </Grid>
        <Grid item xs={6}>
          <p className={classes.detailTitle}>State</p>
          <p className={classes.detailValue}>{swapInIngredient.state}</p>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchResults;
