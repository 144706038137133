import { useContext } from 'react';
import { SessionContext } from 'contexts/sessionContext';

// To show children only if a feature flag is OFF,
// pass whenOff={true}.

const FeatureFlag = ({ flag, children, whenOff = false }) => {
  const { featureFlags } = useContext(SessionContext);

  const flagOn = featureFlags[flag];
  const desiredCondition = whenOff ? !flagOn : flagOn;

  if (desiredCondition) {
    return children;
  }
  return null;
};

export default FeatureFlag;
