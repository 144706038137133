import { LIGHTEST_GREY, MEDIUM_GREY } from 'lib/constants';

export default {
  cardContent: {
    padding: '16px 0 0 0',
  },
  fileInput: {
    '& .RaFileInput-dropZone': {
      width: '40%',
      padding: '12px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: LIGHTEST_GREY,
      },
    },
  },
  fileField: {
    cursor: 'pointer',
  },
  fileName: {
    font: 'Roboto',
    weight: 400,
    fontSize: 14,
    lineHeight: '24px',
    color: MEDIUM_GREY,
    overflowWrap: 'break-word',
    width: '50%',
  },
  flexContainer: {
    '& .MuiStack-root': {
      display: 'flex',
      flexDirection: 'row',
      justifyItems: 'center',
      columnGap: '10%',
    },
  },
  uploadCard: {
    padding: '16px',
    marginBottom: '24px',
  },
};
