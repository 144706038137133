// Dependencies
import React, { useEffect, useMemo, useState } from 'react';

// Components
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import IngredientCheckboxes from './IngredientCheckboxes';
import Input from '../../Input';
import AutocompleteInput from '../../AutocompleteInput';

const DeliveryIncidentFields = ({
  classes,
  formOptions,
  formState,
  setFormState,
  handleFormState,
  incidentable,
}) => {
  const [mealSelectionRequired, updateMealSelectionRequired] = useState(false);
  const [ingredientSelectionRequired, updateIngredientSelectionRequired] = useState(false);

  const errorTypeOptions = useMemo(
    () =>
      formOptions.errorTypes.map((errorType) => ({
        id: errorType.id,
        label: errorType.name,
      })),
    [formOptions.errorTypes]
  );

  const selectedErrorType = useMemo(
    () =>
      errorTypeOptions.find((errorType) => errorType.id === formState.deliveryErrorTypeId) || null,
    [errorTypeOptions, formState.deliveryErrorTypeId]
  );

  const handleErrorTypeChange = (event, newValue) => {
    handleFormState({ target: { name: 'deliveryErrorTypeId', value: newValue?.id } });

    if (newValue === null) {
      updateMealSelectionRequired(false);
      updateIngredientSelectionRequired(false);
    } else {
      const value = newValue.id;
      const errorTypeSelection = formOptions.errorTypes.find(
        (deliveryErrorType) => deliveryErrorType.id === value
      );

      updateMealSelectionRequired(errorTypeSelection.mealSelectionRequired);
      updateIngredientSelectionRequired(
        incidentable.mealPlan === 'Tempo' ? false : errorTypeSelection.ingredientSelectionRequired
      );
    }
  };

  const filteredSubcategories = () => {
    return formOptions.deliveryRemediationCategories.filter((rem) =>
      rem.allowedErrorTypes.includes(formState.deliveryErrorTypeId)
    );
  };

  const renderFilteredSubcategories = () => {
    if (!formState.deliveryErrorTypeId) {
      return [];
    }

    return filteredSubcategories().map((category) => {
      return (
        <MenuItem key={category.id} value={category.id}>
          {category.name}
        </MenuItem>
      );
    });
  };

  useEffect(() => {
    switch (filteredSubcategories().length) {
      case 1:
        if (formState.remediationCategoryId !== filteredSubcategories()[0].id) {
          setFormState({ ...formState, remediationCategoryId: filteredSubcategories()[0].id });
        }
        break;
      case 0:
        if (formState.remediationCategoryId !== '') {
          setFormState({ ...formState, remediationCategoryId: '' });
        }
        break;
      default:
        break;
    }
  });

  const renderMealInput = () => {
    return (
      <Input
        label="Meal"
        name="mealId"
        onChange={handleFormState}
        select
        value={formState.mealId}
        required={mealSelectionRequired || ingredientSelectionRequired}
      >
        <MenuItem value="">Entire basket affected</MenuItem>
        {incidentable.mealSelections.map((selection) => {
          return (
            <MenuItem key={selection.mealId} value={selection.mealId}>
              {selection.title}
            </MenuItem>
          );
        })}
      </Input>
    );
  };

  const renderIngredientsInput = () => {
    const meal = incidentable.mealSelections.find(
      (selection) => selection.mealId === formState.mealId
    );

    return (
      <IngredientCheckboxes
        classes={classes}
        handleFormState={handleFormState}
        ingredientAssignments={meal.ingredients}
        required={ingredientSelectionRequired}
        otherIngredient={incidentable.otherIngredient}
      />
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Autocomplete
            disablePortal
            autoHighlight
            options={errorTypeOptions}
            value={selectedErrorType}
            onChange={handleErrorTypeChange}
            slotProps={{ popper: { sx: { minWidth: 'fit-content' } } }}
            renderInput={(params) => (
              <AutocompleteInput
                {...params}
                label="Error Type"
                name="deliveryErrorTypeId"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Input
            label="Subcategory"
            name="remediationCategoryId"
            onChange={handleFormState}
            required
            select
            value={formState.remediationCategoryId}
          >
            <MenuItem value="">Subcategory</MenuItem>
            {renderFilteredSubcategories()}
          </Input>
        </Grid>
      </Grid>

      {renderMealInput()}
      {formState.mealId && incidentable.mealPlan !== 'Tempo' && renderIngredientsInput()}

      <Input
        label="Description"
        multiline
        name="description"
        onChange={handleFormState}
        required
        value={formState.description}
      />
    </>
  );
};

export default DeliveryIncidentFields;
