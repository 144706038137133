// Dependencies
import React from 'react';

// Components
import Grid from '@mui/material/Grid';

import { centsToDollarString, dollarsToCents } from 'lib/helpers/money';
import MoneyInput from '../MoneyInput';

const CreditFields = ({ incidentable, classes, handleSetMoneyAmount, formId }) => {
  const { maxCreditAmountCents } = incidentable.creditsAndRefunds;

  const maxAmountText = `Max: ${centsToDollarString(maxCreditAmountCents)}`;

  const validateCredit = (creditAmount) => {
    return dollarsToCents(creditAmount) <= maxCreditAmountCents;
  };

  const handleMoneyInput = (creditAmount) => {
    handleSetMoneyAmount('creditAmount', creditAmount);
  };

  return (
    <>
      <div className={classes.header}>Credit</div>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <MoneyInput
            helperText={maxAmountText}
            label="Credit Amount"
            name="creditAmount"
            onSetFormState={handleMoneyInput}
            onValidate={validateCredit}
            fieldId={`credit-amount-${formId}`}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CreditFields;
