import React, { useState } from 'react';

import { Button, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { POST } from 'lib/api';
import { APP_API_URI } from 'lib/constants';

const AddFulfillerForm = ({ classes, getData, handleClose }) => {
  const [fulfillerName, setFulfillerName] = useState('');
  const [fulfillerDescription, setFulfillerDescription] = useState('');

  const closeDrawer = () => {
    setFulfillerName('');
    setFulfillerDescription('');
    handleClose();
  };

  const submitForm = async () => {
    await POST(`${APP_API_URI}/internal/retail/fulfiller/save_fulfiller`, {
      name: fulfillerName,
      description: fulfillerDescription,
    });
    handleClose();
    getData();
  };

  return (
    <Stack justifyContent="space-between" spacing={3}>
      <Typography variant="h5" classes={classes.titleBar}>
        Add New Fulfiller
        <Button onClick={closeDrawer}>
          <CloseIcon />
        </Button>
      </Typography>
      <TextField
        label="Fulfiller Name"
        variant="outlined"
        value={fulfillerName}
        onChange={(e) => setFulfillerName(e.target.value)}
      />
      <TextField
        label="Description (optional)"
        variant="outlined"
        value={fulfillerDescription}
        onChange={(e) => setFulfillerDescription(e.target.value)}
      />
      <Button variant="contained" onClick={submitForm}>
        Save
      </Button>
    </Stack>
  );
};

export default AddFulfillerForm;
