import React, { useState } from 'react';

// Material Components
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';

import SectionHeader from '../shared/SectionHeader';
import TextBox from '../../shared/TextBox';

const CustomizeItStep = ({ classes, handleFormState, customizeItStep = null }) => {
  const [customizeItStepState, setCustomizeItStepState] = useState({
    ...customizeItStep,
  });

  const updateFormState = (name, value) => {
    const newCustomizeItStepState = { ...customizeItStepState, [name]: value };

    setCustomizeItStepState(newCustomizeItStepState);
    handleFormState('customizeItStep', newCustomizeItStepState);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    updateFormState(name, value);
  };

  const onDeleteStep = () => {
    updateFormState('deleted', true);
  };

  const renderCustomizeItStep = () => {
    return (
      <>
        <Paper className={classes.paper}>
          <SectionHeader text="Customize It Step" />
          <TextBox
            fullWidth
            multiline
            label="Description"
            name="description"
            onChange={onChange}
            value={customizeItStepState.description}
          />
          <Button variant="outlined" className={classes.deleteButton} onClick={onDeleteStep}>
            <DeleteIcon />
            Remove Step
          </Button>
        </Paper>
      </>
    );
  };

  return <>{customizeItStepState.deleted ? null : renderCustomizeItStep()}</>;
};

export default CustomizeItStep;
