// Dependencies
import React from 'react';

// Material
import Button from '@mui/material/Button';

// Components
import SelectVersions from '../SelectVersions';

const SelectVersionsButton = ({
  activeMeals,
  inactiveMeals,
  openSelectVersions,
  onSetOpenSelectVersions,
  classes,
}) => {
  // Handler methods
  const handleClickOpenSelectVersions = () => {
    onSetOpenSelectVersions(true);
  };

  const handleCloseSelectVerisons = () => {
    onSetOpenSelectVersions(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpenSelectVersions}
        className={classes.selectVersionsButton}
      >
        Select Versions
      </Button>
      <SelectVersions
        activeMeals={activeMeals}
        inactiveMeals={inactiveMeals}
        openSelectVersions={openSelectVersions}
        handleCloseSelectVerisons={handleCloseSelectVerisons}
      />
    </>
  );
};

export default SelectVersionsButton;
