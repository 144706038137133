// Dependencies
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { cleanArray } from 'lib/utils';

// Material
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';

// Components
import TitleRow from '../TitleRow';
import VersionNotesRow from '../VersionNotesRow';
import LastUpdatedRow from '../LastUpdatedRow';
import MenusRow from '../MenusRow';
import ClassificationRow from '../ClassificationRow';
import ChefRow from '../ChefRow';
import IngredientsRow from '../IngredientsRow';
import BeforeYouCookRow from '../BeforeYouCookRow';
import ImagesRow from '../ImagesRow';
import CookingFactsRow from '../CookingFactsRow';
import NutritionRow from '../NutritionRow';
import RecipeStepsRow from '../RecipeStepsRow';
import WhatYouNeedRow from '../WhatYouNeedRow';
import DisposableItemsRow from '../DisposableItemsRow';
import DescriptionRow from '../DescriptionRow/DescriptionRow';
import EquipmentRow from '../EquipmentRow/EquipmentRow';
import DietaryRow from '../DietaryRow/DietaryRow';

const VersionTable = ({
  classes,
  onGetMeals,
  viewableMeals,
  activeMeals,
  inactiveMeals,
  conceptId = null,
}) => {
  const submitting = useSelector((state) => state.comparisonTool.mealVersionDataCopier.submitting);
  const success = useSelector((state) => state.comparisonTool.mealVersionDataCopier.success);
  const successMessage = useSelector(
    (state) => state.comparisonTool.mealVersionDataCopier.successMessage
  );
  const error = useSelector((state) => state.comparisonTool.mealVersionDataCopier.error);
  const errorMessages = useSelector((state) =>
    cleanArray([state.comparisonTool.mealVersionDataCopier.errorMessage])
  );

  const renderSuccessMessage = () => {
    return (
      <Alert key={successMessage} severity="success" variant="filled">
        {successMessage}
      </Alert>
    );
  };

  const renderErrorMessages = () => {
    return errorMessages.map((message) => {
      return (
        <Alert key={message} severity="error" variant="filled">
          {message}
        </Alert>
      );
    });
  };

  const [prevSubmittingVal, setPrevSubmittingVal] = useState(submitting);
  // The value of `submitting` is only set to true when there is a request to update meals.
  // This useEffect ensures that we reload the meals only after a request to update meals is done and `submitting` switches from a value of true to false
  useEffect(() => {
    if (prevSubmittingVal === true && submitting === false) {
      onGetMeals(conceptId);
    }

    setPrevSubmittingVal(submitting);
  }, [conceptId, onGetMeals, submitting, prevSubmittingVal]);

  return (
    <Paper className={classes.versionTable}>
      {success && renderSuccessMessage()}
      {error && renderErrorMessages()}
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TitleRow
              viewableMeals={viewableMeals}
              conceptId={conceptId}
              inactiveMeals={inactiveMeals}
              activeMeals={activeMeals}
            />
          </TableHead>
          <TableBody>
            <DescriptionRow viewableMeals={viewableMeals} />
            <VersionNotesRow viewableMeals={viewableMeals} />
            <LastUpdatedRow viewableMeals={viewableMeals} />
            <MenusRow viewableMeals={viewableMeals} />
            <ClassificationRow viewableMeals={viewableMeals} />
            <ChefRow viewableMeals={viewableMeals} />
            <WhatYouNeedRow viewableMeals={viewableMeals} />
            <DisposableItemsRow viewableMeals={viewableMeals} />
            <IngredientsRow viewableMeals={viewableMeals} />
            <BeforeYouCookRow viewableMeals={viewableMeals} />
            <EquipmentRow viewableMeals={viewableMeals} />
            <ImagesRow viewableMeals={viewableMeals} />
            <RecipeStepsRow viewableMeals={viewableMeals} />
            <CookingFactsRow viewableMeals={viewableMeals} />
            <NutritionRow viewableMeals={viewableMeals} />
            <DietaryRow viewableMeals={viewableMeals} />
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default VersionTable;
