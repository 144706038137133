// Dependencies
import React from 'react';
import { useDispatch } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';

// Helpers
import {
  getSameMenuDropChanges,
  getCrossMenuDropChanges,
  getCrossStagingGroupDropChanges,
  getStagingGroupDropChanges,
} from 'lib/helpers/menugrid/beautifulMenugridMealRepositioner';

import { updateGrid, updateApiRequest } from 'redux/menugrid/menus/actions';

// Components
import Box from '@mui/material/Box';
import MenuSelectionsColumn from '../MenuSelectionsColumn';
import StagingArea from '../StagingArea';

const MenuSelectionsContainer = ({ menus, menuType, displayGroup, stagingGroup }) => {
  const dispatch = useDispatch();

  const handleSameMenuDrop = (menu, source, draggableId, destination) => {
    const { repositionedMenus, payload } = getSameMenuDropChanges(
      menu,
      source,
      draggableId,
      destination
    );

    dispatch(updateGrid(repositionedMenus, menuType));
    dispatch(updateApiRequest({ menus: payload, menuType, displayGroup }));
  };

  const handleCrossMenuDrop = (startMenu, endMenu, source, draggableId, destination) => {
    const { repositionedMenus, payload } = getCrossMenuDropChanges(
      startMenu,
      endMenu,
      source,
      draggableId,
      destination
    );
    dispatch(updateGrid(repositionedMenus, menuType));
    dispatch(updateApiRequest({ menus: payload, menuType, displayGroup }));
  };

  const handleStagingDrop = (staging, source, draggableId, destination) => {
    const { repositionedStaging, payload } = getStagingGroupDropChanges(
      staging,
      source,
      draggableId,
      destination
    );

    dispatch(updateGrid(repositionedStaging));
    dispatch(updateApiRequest({ stagingGroup: payload, menuType, displayGroup }));
  };

  const handleCrossStagingDrop = (startMenu, endMenu, source, draggableId, destination) => {
    const { repositionedMenus, stagingPayload, menuPayload } = getCrossStagingGroupDropChanges(
      startMenu,
      endMenu,
      source,
      draggableId,
      destination
    );

    dispatch(updateGrid(repositionedMenus, menuType));
    dispatch(
      updateApiRequest({
        stagingGroup: stagingPayload,
        menus: menuPayload,
        menuType,
        displayGroup,
      })
    );
  };

  const findMenu = (sourceOrDestination) => {
    if (sourceOrDestination.droppableId.match(/staging/)) {
      return {
        ...stagingGroup,
        staging: true,
      };
    }

    const foundMenu = menus.find(
      (menu) => menu.id === parseInt(sourceOrDestination.droppableId, 10)
    );
    return {
      ...foundMenu,
      staging: false,
    };
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && source.index === destination.index) {
      return;
    }

    const startMenu = findMenu(source);
    const endMenu = findMenu(destination);

    if (startMenu.staging && endMenu.staging) {
      handleStagingDrop(startMenu, source, draggableId, destination);
    } else if (startMenu.staging || endMenu.staging) {
      handleCrossStagingDrop(startMenu, endMenu, source, draggableId, destination);
    } else if (startMenu.id === endMenu.id) {
      handleSameMenuDrop(startMenu, source, draggableId, destination);
    } else {
      handleCrossMenuDrop(startMenu, endMenu, source, draggableId, destination);
    }
  };

  // ==========================================================================
  // rendering
  // ==========================================================================

  const renderMenuSelectionsColumns = () => {
    return menus.map((menu) => {
      return (
        <MenuSelectionsColumn
          key={menu.id}
          displayGroup={displayGroup}
          menu={menu}
          menuType={menuType}
        />
      );
    });
  };

  const renderStagingArea = () => {
    return (
      <StagingArea
        key={stagingGroup.id}
        stagingGroup={stagingGroup}
        displayGroup={displayGroup}
        menuSelections={stagingGroup.menuSelections}
      />
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box display="flex">{renderStagingArea()}</Box>
      <Box display="flex">{renderMenuSelectionsColumns()}</Box>
    </DragDropContext>
  );
};

export default MenuSelectionsContainer;
