import React from 'react';
import TableRow from '@mui/material/TableRow';
import DraggableTableRow from '../DraggableTableRow';

const MealRow = ({ classes, category, meal, index = null }) => {
  return (
    <TableRow
      key={meal.id}
      category={category}
      meal={meal}
      index={index}
      component={DraggableTableRow}
      styles={classes}
      className={classes.backgroundColor}
    />
  );
};

export default MealRow;
