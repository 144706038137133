import React from 'react';
import { useInput } from 'react-admin';

const CustomFormInput = ({ source = '', defaultValue = {} }) => {
  const { field } = useInput({ source, defaultValue });

  return <div {...field} />;
};

export default CustomFormInput;
