import React from 'react';
import Button from '@mui/material/Button';

const NewMenuButton = ({ posting, onSubmitCreateNewMenu, classes = {} }) => {
  const handleOnSubmitCreateNewMenu = () => {
    onSubmitCreateNewMenu();
  };

  return (
    <div className={classes.newMenuButton}>
      <Button
        variant="outlined"
        onClick={handleOnSubmitCreateNewMenu}
        disabled={posting}
        color="primary"
      >
        New Menu
      </Button>
    </div>
  );
};

export default NewMenuButton;
