// Dependencies
import React, { useState, useMemo } from 'react';
import clsx from 'clsx';

// Helpers
import { sanitizedDescriptions, compareTextBlobs } from 'lib/helpers/comparisonTool';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import styles from './styles';

const DescriptionRow = ({ viewableMeals, classes }) => {
  // State methods
  const [hasDifferences, setHasDifferences] = useState(false);

  // Lifecycle methods
  useMemo(() => {
    const checkDifferences =
      viewableMeals.length !== 0 ? compareTextBlobs(sanitizedDescriptions(viewableMeals)) : false;
    setHasDifferences(checkDifferences);
  }, [viewableMeals]);

  return (
    <TableRow>
      <TableCell
        className={clsx(classes.iconCell, hasDifferences && classes.tableRowGreenBorder)}
      />
      <TableCell className={classes.rowLabel}>Description</TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`description.${meal.versionId}`}
          className={clsx(meal.inactive && classes.greyed)}
        >
          <div className={clsx(classes.description, hasDifferences && classes.highlight)}>
            {meal.description}
          </div>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default withStyles(styles)(DescriptionRow);
