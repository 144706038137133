import React from 'react';
import {
  createConfigurationGroup,
  renameConfigurationGroup,
  removeConfigurationGroup,
} from 'redux/appConfigurationTool/actions';
import {
  CONFIG_GROUP_CREATE_ACTION,
  CONFIG_GROUP_RENAME_ACTION,
  CONFIG_GROUP_DELETE_ACTION,
} from 'lib/constants/appConfigurationToolConstants';
import { useDispatch } from 'react-redux';

import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Button from './shared/Button';
import styles from './styles';

const ConfigGroupActionDialog = ({
  width = 350,
  configGroup = {},
  openModal = false,
  setOpenModal = () => {},
  action = CONFIG_GROUP_CREATE_ACTION,
}) => {
  const [formData, setFormData] = React.useState({ name: '' });
  const [errorState, setErrorState] = React.useState(false);
  const nameLabel = action === CONFIG_GROUP_RENAME_ACTION ? 'New Name' : 'Name';

  const dispatch = useDispatch();

  const handleToggleModal = () => {
    setOpenModal(!openModal);
    setFormData({ name: '' });
    setErrorState(false);
  };

  const handleNameChange = (event) => {
    setFormData({ name: event.target.value, id: configGroup.groupId });
    if (event.target.value.length > 3) setErrorState(false);
  };

  const handleDeleteGroup = () => {
    dispatch(removeConfigurationGroup(configGroup));
    handleToggleModal();
  };

  const handleSave = async (event) => {
    event.preventDefault();
    if (formData.name.length < 4) {
      setErrorState(true);
      return;
    }

    if (action === CONFIG_GROUP_RENAME_ACTION) {
      dispatch(renameConfigurationGroup(formData));
    } else {
      dispatch(createConfigurationGroup(formData));
    }
    handleToggleModal();
  };

  const handleBlur = (event) => setFormData({ ...formData, name: event.target.value.trim() });

  return (
    <div>
      <Dialog open={openModal} onClose={handleToggleModal} sx={styles.topDialog}>
        <DialogTitle>{action}</DialogTitle>
        <FormControl component="form" onSubmit={handleSave}>
          <DialogContent>
            {configGroup && action === CONFIG_GROUP_DELETE_ACTION ? (
              <>
                <TextField
                  sx={{ width }}
                  id={action}
                  label="Name"
                  placeholder={configGroup.groupName}
                  value={configGroup.groupName}
                  size="small"
                  variant="standard"
                  onChange={handleNameChange}
                  disabled
                />
                <DialogContentText>
                  This action will delete the group from all environments. Are you sure you want to
                  delete it?
                </DialogContentText>
              </>
            ) : (
              <>
                {configGroup && action === CONFIG_GROUP_RENAME_ACTION && (
                  <div style={{ marginBottom: '2rem' }}>
                    <TextField
                      sx={{ width }}
                      id="create-group-old"
                      label="Current name"
                      size="small"
                      variant="filled"
                      placeholder={configGroup.groupName}
                      value={configGroup.groupName}
                      disabled
                    />
                  </div>
                )}
                <TextField
                  sx={{ width }}
                  id={action}
                  label={nameLabel}
                  size="small"
                  variant="filled"
                  onChange={handleNameChange}
                  error={errorState}
                  helperText={errorState && 'Name must be at least 4 characters long'}
                  onBlur={handleBlur}
                  value={formData.name}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button handleOnClick={handleToggleModal} buttonText="Cancel" color="info" />
            {action === CONFIG_GROUP_DELETE_ACTION && (
              <Button handleOnClick={handleDeleteGroup} buttonText="Delete" color="error" />
            )}
            {action === CONFIG_GROUP_RENAME_ACTION && <Button type="submit" buttonText="Rename" />}
            {action === CONFIG_GROUP_CREATE_ACTION && (
              <Button type="submit" buttonText="Create Group" />
            )}
          </DialogActions>
        </FormControl>
      </Dialog>
    </div>
  );
};

export default ConfigGroupActionDialog;
