import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

const Loading = ({ classes, size = 40, topPadding = true, centered = true, className = '' }) => {
  const topPaddingStyle = topPadding ? classes.topPadding : '';
  const centerStyle = centered ? classes.centered : '';

  // Rendering
  return (
    <div className={`${classes.displayFlex} ${topPaddingStyle} ${centerStyle} ${className}`}>
      <CircularProgress className={classes.circularColorPrimary} size={size} />
    </div>
  );
};

export default withStyles(styles)(Loading);
