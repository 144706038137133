import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Material Components
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

import Dropdown from '../../shared/Dropdown';
import TextBox from '../../shared/TextBox';

const Utensil = ({ classes, formState, handleFormState, index, utensil = null }) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);

  const [utensilState, setUtensilState] = useState({
    ...utensil,
    index,
  });

  useEffect(() => {
    setUtensilState({
      ...utensil,
      index,
    });
  }, [utensil, index]);

  const updateFormState = (attribute, value) => {
    const newUtensilState = { ...utensilState, [attribute]: value };
    setUtensilState(newUtensilState);

    const newFormStateUtensils = formState.utensils;
    newFormStateUtensils[index] = newUtensilState;

    handleFormState('utensils', newFormStateUtensils);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    updateFormState(name, value);
  };

  const onDelete = () => {
    updateFormState('deleted', true);
  };

  const selectUtensils = useMemo(() => {
    return formOptions.utensils.map((utensilOption) => ({
      label: utensilOption.name,
      value: utensilOption.id,
    }));
  }, [formOptions.utensils]);

  const renderUtensil = () => {
    return (
      <Grid item xs={6} className={classes.flexItemCard}>
        <Dropdown
          required
          label="Utensil"
          name="utensilId"
          value={utensilState.utensilId}
          onChange={onChange}
          options={selectUtensils}
        />
        <TextBox
          fullWidth
          label="Quantity"
          name="quantity"
          type="number"
          onChange={onChange}
          value={utensilState.quantity}
        />
        <TextBox
          fullWidth
          label="Sequence Number"
          name="sequenceNumber"
          type="number"
          onChange={onChange}
          value={utensilState.sequenceNumber}
        />
        <Button variant="outlined" className={classes.deleteButton} onClick={onDelete}>
          <DeleteIcon className={classes.deleteIcon} />
          Remove Equipment
        </Button>
        <hr className={classes.divider} />
      </Grid>
    );
  };

  return <>{utensil.deleted ? null : renderUtensil()}</>;
};

export default Utensil;
