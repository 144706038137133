import React from 'react';

const AddMealsErrorMessageSection = ({ errorMessage = '', classes = {} }) => {
  return (
    <div>
      <div className={classes.addMealsErrorText}>{errorMessage}</div>
    </div>
  );
};

export default AddMealsErrorMessageSection;
