import React, { useEffect, useState } from 'react';

import NetsuiteAutocomplete from 'components/shared/NetsuiteAutocomplete';
import { APP_API_URI, NETSUITE_TYPE_LINE_OF_BUSINESS } from 'lib/constants';

import { Button, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { POST } from 'lib/api';

const EditCategoryForm = ({
  classes,
  getData,
  handleClose,
  setSnackState,
  workingCategory,
  setJobId,
  isUpdating,
  setIsUpdating,
}) => {
  const [categoryName, setCategoryName] = useState(workingCategory.name);
  const [categoryDescription, setCategoryDescription] = useState(workingCategory.description);
  const [categoryInternalId, setCategoryInternalId] = useState(workingCategory.netsuiteInternalId);
  const [toArchive, setToArchive] = useState(workingCategory.status === 'active');

  useEffect(() => {
    setCategoryName(workingCategory.name);
    setCategoryDescription(workingCategory.description);
    setCategoryInternalId(workingCategory.netsuiteInternalId);
    setToArchive(workingCategory.status === 'active');
  }, [workingCategory]);

  const handleSubmit = async () => {
    await POST(`${APP_API_URI}/internal/retail/category/save_category`, {
      id: workingCategory.id,
      name: categoryName,
      description: categoryDescription,
      netsuite_internal_id: categoryInternalId,
    })
      .then(() => {
        setSnackState({
          message: `Successfully updated ${workingCategory.name}`,
          severity: 'success',
        });
        handleClose();
        getData();
      })
      .catch(() =>
        setSnackState({
          message: `Problem updating ${workingCategory.name}`,
          severity: 'error',
        })
      )
      .finally(() => handleClose());
  };

  const handleArchiveClick = async () => {
    setJobId(null);
    setIsUpdating(true);

    await POST(`${APP_API_URI}/internal/retail/category/set_category_archived`, {
      id: workingCategory.id,
      archived: toArchive,
    })
      .then((response) => {
        setJobId(response.data.retailCategory.updateAssortmentsJobId);
        handleClose();
        getData();
        setIsUpdating(false);
      })
      .catch(() =>
        setSnackState({
          message: `Problem archiving ${workingCategory.name}`,
          severity: 'error',
        })
      )
      .finally(() => handleClose());
  };

  return (
    <Stack justifyContent="space-between" spacing={3}>
      <Typography variant="h5" classes={classes.titleBar}>
        Edit Product Line
        <Button onClick={handleClose}>
          <CloseIcon />
        </Button>
      </Typography>
      <TextField
        label="Product Line Info"
        variant="outlined"
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
        disabled={!toArchive}
      />
      <TextField
        label="Description (optional)"
        variant="outlined"
        value={categoryDescription}
        onChange={(e) => setCategoryDescription(e.target.value)}
        disabled={!toArchive}
      />
      <NetsuiteAutocomplete
        type={NETSUITE_TYPE_LINE_OF_BUSINESS}
        value={categoryInternalId}
        onChange={(id) => setCategoryInternalId(id)}
        disabled={!toArchive}
      />
      <Button variant="contained" onClick={handleSubmit} disabled={isUpdating || !toArchive}>
        {isUpdating ? 'Updating...' : 'Save'}
      </Button>
      <Button variant="outlined" onClick={handleArchiveClick} disabled={isUpdating}>
        {toArchive ? 'Archive' : 'Activate'} Product Line
      </Button>
    </Stack>
  );
};

export default EditCategoryForm;
