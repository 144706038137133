// Dependencies
import React from 'react';

// Material
import TextField from '@mui/material/TextField';

// Custom Components
import NumberFormatter from '../NumberFormatter';

// Example: https://material-ui.com/components/text-fields/#FormattedInputs.js

const MoneyView = ({
  classes,
  children = [],
  helperText = '',
  label = '',
  name = '',
  value = '',
}) => {
  return (
    <TextField
      className={classes.textInput}
      disabled
      focused={value >= 0}
      helperText={helperText}
      label={label}
      name={name}
      value={value}
      variant="outlined"
      InputProps={{ inputComponent: NumberFormatter }}
    >
      {children}
    </TextField>
  );
};

export default MoneyView;
