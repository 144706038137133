import { useEffect, useState } from 'react';
import { POST } from 'lib/api';
import Pusher from 'pusher-js';
import { APP_API_URI } from 'lib/constants';
import Logger from 'lib/Logger';

const PUSHER_KEY = process.env.REACT_APP_PUSHER_INTERNAL_APPS_API_KEY;
const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_INTERNAL_APPS_CLUSTER;

if (!PUSHER_KEY || !PUSHER_CLUSTER) {
  console.error(
    'Missing Pusher environment variables. Ensure pusher environment variables are set.'
  );
}

const useLiveUsers = (channelName) => {
  const [onlineUsers, setOnlineUsers] = useState([]);

  useEffect(() => {
    if (!PUSHER_KEY || !PUSHER_CLUSTER) return undefined;

    const addMember = (userId, userInfo) => {
      setOnlineUsers((prevUsers) => {
        if (prevUsers.some((user) => user.id === userId)) return prevUsers;
        return [...prevUsers, { id: String(userId), info: userInfo }];
      });
    };

    const removeMember = (userId) => {
      setOnlineUsers((prevUsers) => prevUsers.filter((user) => user.id !== String(userId)));
    };

    const pusher = new Pusher(PUSHER_KEY, {
      cluster: PUSHER_CLUSTER,
      encrypted: true,
      channelAuthorization: {
        customHandler: async (data, callback) => {
          try {
            const response = await POST(
              `${APP_API_URI}/internal/pusher/user_presence_indicator/auth`,
              { socket_id: data.socketId, channel_name: data.channelName }
            );

            if (response?.data) {
              callback(null, response.data);
            } else {
              callback('Authentication failed', null);
              console.error('Authentication failed. Response data missing.');
            }
          } catch (error) {
            callback(error.message || 'Unknown error', null);
            console.error(error);
          }
        },
      },
    });

    const presenceChannel = pusher.subscribe(`presence-${channelName}`);

    // Triggered when the current user joins the channel
    presenceChannel.bind('pusher:subscription_succeeded', (members) => {
      members.each(({ id, info }) => addMember(id, info));
    });

    presenceChannel.bind('pusher:subscription_error', (error) => {
      Logger.error(error);
    });

    // Triggered when a new member that is not the current user joins the channel
    presenceChannel.bind('pusher:member_added', (member) => {
      addMember(member.id, member.info);
    });

    // Triggered when a new member that is not the current user leaves the channel
    presenceChannel.bind('pusher:member_removed', (member) => {
      removeMember(member.id);
    });

    return () => {
      pusher.unsubscribe(`presence-${channelName}`);
      pusher.disconnect();
    };
  }, [channelName]);

  return onlineUsers;
};

export default useLiveUsers;
