// Dependencies
import React, { useState, useEffect, useMemo } from 'react';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import flatMapDeep from 'lodash/flatMapDeep';
import clsx from 'clsx';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

// Helpers
import {
  sanitizedRecipeSteps,
  compareTextBlobs,
  setHighlightPropertyForGroup,
} from 'lib/helpers/comparisonTool';

// Components
import RecipeStepCard from '../RecipeStepCard';

const RecipeStepsRow = ({ viewableMeals, classes }) => {
  // State methods
  const [openCollapsible, setOpenCollapsible] = useState(false);
  const [hasDifferences, setHasDifferences] = useState(false);
  const [highlightPropertiesGroupedByStep, setHighlightPropertiesGroupedByStep] = useState({});

  // Lifecycle methods
  useMemo(() => {
    const checkDifferences =
      viewableMeals.length !== 0
        ? compareTextBlobs(
            sanitizedRecipeSteps(
              viewableMeals.map((meal) => {
                return meal.recipeSteps;
              })
            )
          )
        : false;
    setHasDifferences(checkDifferences);
  }, [viewableMeals]);

  useEffect(() => {
    const allRecipeSteps = flatMapDeep(viewableMeals, (meal) => {
      return meal.recipeSteps;
    });
    const groupedRecipeSteps = groupBy(allRecipeSteps, (recipeStep) => {
      return recipeStep.step;
    });
    setHighlightPropertiesGroupedByStep(
      mapValues(groupedRecipeSteps, (stepGroup) => {
        return {
          title: setHighlightPropertyForGroup({
            recipeStepsGroup: stepGroup,
            property: 'title',
            numMeals: viewableMeals.length,
          }),
          description: setHighlightPropertyForGroup({
            recipeStepsGroup: stepGroup,
            property: 'description',
            numMeals: viewableMeals.length,
          }),
        };
      })
    );
  }, [viewableMeals]);

  // Handler methods
  const handleCollapsibleClick = () => {
    setOpenCollapsible(!openCollapsible);
  };

  return (
    <TableRow>
      <TableCell
        className={clsx(classes.iconCell, hasDifferences && classes.tableRowGreenBorder)}
        onClick={handleCollapsibleClick}
      >
        {openCollapsible ? (
          <ExpandMore className={classes.icon} />
        ) : (
          <ChevronRightIcon className={classes.icon} />
        )}
      </TableCell>
      <TableCell className={classes.rowLabel} onClick={handleCollapsibleClick}>
        Recipe Steps
      </TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`recipeSteps.${meal.versionId}`}
          className={clsx(classes.expandingCell, meal.inactive && classes.greyed)}
        >
          <div className={classes.listContentSameBlock}>{`${meal.recipeSteps.length} Steps`}</div>
          <Collapse in={openCollapsible} unmountOnExit className={classes.collapse}>
            {meal.recipeSteps.map((recipeStep) => {
              return (
                <RecipeStepCard
                  recipeStep={recipeStep}
                  key={`${meal.versionId}-${recipeStep.id}`}
                  highlightProperties={highlightPropertiesGroupedByStep[recipeStep.step]}
                />
              );
            })}
          </Collapse>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default RecipeStepsRow;
