import React from 'react';
import { PACKING_FACILITY_IDS } from 'lib/constants';

import Grid from '@mui/material/Grid';

import Checkboxes from '../../shared/Checkboxes';

const PackingFacility = ({
  classes,
  formOptions,
  replacementIngredientState,
  onAttributeChange,
}) => {
  const handleChange = (event) => {
    onAttributeChange(event.target.name, event.target.value.map(Number));
  };

  return (
    <Grid item xs={6}>
      <p className={classes.label}>Packing Facility</p>
      <Grid item xs={6} className={classes.pl_8}>
        <Checkboxes
          choices={formOptions.packingFacilities}
          selectedFormState={replacementIngredientState.packingFacilityIds || []}
          selectedFormStateName={PACKING_FACILITY_IDS}
          handleFormState={handleChange}
        />
      </Grid>
      <p className={classes.helperText}> Select at least one packing facility </p>
    </Grid>
  );
};

export default PackingFacility;
