import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Material Components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import PhotoCard from './PhotoCard';
import SaveChangesButton from '../shared/SaveChangesButton';
import SectionHeader from '../shared/SectionHeader';

const MediaTab = ({ classes, dirty = false, setDirty }) => {
  const { meal } = useSelector((state) => state.mealEditForm.meal);

  const [formState, setFormState] = useState({
    photos: meal.photos,
  });

  useEffect(() => {
    setFormState({ photos: meal.photos });
  }, [meal]);

  const handleFormState = (attribute, value) => {
    if (!dirty) {
      setDirty(true);
    }
    setFormState({ ...formState, [attribute]: value });
  };

  const addImageCard = () => {
    const newPhoto = {};
    const newPhotosState = formState.photos;
    newPhotosState.push(newPhoto);
    setFormState({ ...formState, photos: newPhotosState });
  };

  const renderPhotoCards = () => {
    return formState.photos.map((photo, index) => {
      const key = `photo-${index}`;
      return (
        <PhotoCard
          key={key}
          classes={classes}
          handleFormState={handleFormState}
          photo={photo}
          index={index}
          formState={formState}
        />
      );
    });
  };

  return (
    <Grid item xs={8}>
      <Card className={classes.photosContainer} data-testid="photos-container">
        <SectionHeader text="Meal Images" />
        {renderPhotoCards()}
        <hr className={classes.hr} />
        <Button variant="outlined" className={classes.outlinedButton} onClick={addImageCard}>
          Add Image
        </Button>
      </Card>

      <SaveChangesButton mealParams={formState} mealId={meal.id} savePhotos />
    </Grid>
  );
};

export default MediaTab;
