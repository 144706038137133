import React, { useEffect, useState, useContext } from 'react';

import { broadcastScrollTopToParent, currentHeight } from 'lib/utils';

// Components
import { FeatureFlag } from 'components/shared';

import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';

import { SessionContext } from 'contexts/sessionContext';
import AddIncidentButton from '../AddIncidentButton';
import CancelFields from '../CancelFields';
import DeliveryInfo from '../DeliveryInfo';
import Footer from '../Footer';
import MealsAndCharges from '../MealsAndCharges';
import IncidentSummary from '../IncidentSummary';
import IncidentForm from '../IncidentForm';

const IncidentGrid = ({
  cancelState,
  classes,
  incidentable,
  formStates,
  setCancelState,
  setFormStates,
  updateFormState,
  onCancelOrder,
  onGetFormOptions,
  onSubmitIncidents,
  onRaiseIncidentError,
  formOptions = {
    cancelReasons: null,
    errorTypes: null,
    userRemediationCategories: null,
    deliveryRemediationCategories: null,
  },
  error = false,
  errorMessages = [],
  incidents = [],
  success = false,
  successMessages = [],
}) => {
  const [buttonHeight, setButtonHeight] = useState(null);
  const { employeeId } = useContext(SessionContext);
  const renderErrorMessages = () => {
    return errorMessages.map((message) => {
      return (
        <Alert key={message} severity="error" variant="filled">
          {message}
        </Alert>
      );
    });
  };

  const renderSuccessMessages = () => {
    return successMessages.map((message) => {
      return (
        <Alert key={message} severity="success" variant="filled">
          {message}
        </Alert>
      );
    });
  };

  const findMaxHeight = () => {
    const addButtonPadding = 32;
    return currentHeight() - buttonHeight - addButtonPadding;
  };

  useEffect(() => {
    if (formOptions.errorTypes === null) {
      onGetFormOptions(incidentable.id, incidentable.type);
    }
  }, [onGetFormOptions, formOptions, incidentable.id, incidentable.type]);

  const renderForms = () => {
    return Object.keys(formStates).map((formId) => {
      return (
        <IncidentForm
          key={formId}
          formId={formId}
          formOptions={formOptions}
          formStates={formStates}
          setFormStates={setFormStates}
          updateFormState={updateFormState}
        />
      );
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const arrayOfIncidentsParams = Object.values(formStates);

    const ingredientSelectionError = arrayOfIncidentsParams.some((incident) => {
      if (incident.incidentType === 'User Error') {
        return false;
      }

      if (incidentable.mealPlan === 'Tempo') {
        return false;
      }

      const incidentCause = formOptions.errorTypes.find(
        (deliveryErrorType) => deliveryErrorType.id === incident.deliveryErrorTypeId
      );

      return (
        incidentCause.ingredientSelectionRequired &&
        !incident.ingredientId &&
        !incident.ingredientAssignmentId
      );
    });

    if (ingredientSelectionError) {
      onRaiseIncidentError('You must select an ingredient for this error type.');
      return;
    }

    const incidentParams = {
      ...cancelState,
      employeeId,
      incidentableId: incidentable.id,
      incidentableType: incidentable.type,
      incidents: arrayOfIncidentsParams,
    };

    if (cancelState.cancel) {
      Promise.all([onSubmitIncidents(incidentParams), onCancelOrder(incidentParams)]);
    } else {
      onSubmitIncidents(incidentParams);
    }
  };

  const anyIncidents = incidents.length > 0;

  useEffect(() => {
    if (anyIncidents) {
      broadcastScrollTopToParent();
    }
  }, [anyIncidents]);

  return (
    <>
      {/* Here is an example of how to use FeatureFlag. */}
      <FeatureFlag flag="cs_beta_testing">
        <Alert key="cs_beta_testing" severity="warning" variant="filled">
          Testing Beta Features
        </Alert>
      </FeatureFlag>

      {success && renderSuccessMessages()}
      {error && renderErrorMessages()}

      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <div className={classes.incidentablesWrapper} style={{ maxHeight: findMaxHeight() }}>
              <DeliveryInfo incidentable={incidentable} />
              <MealsAndCharges incidentable={incidentable} />
            </div>
          </Grid>
          <Grid item xs={8}>
            {anyIncidents ? (
              <IncidentSummary />
            ) : (
              <form onSubmit={handleSubmit}>
                <div className={classes.formWrapper} style={{ maxHeight: findMaxHeight() }}>
                  {renderForms()}
                  <AddIncidentButton
                    formStates={formStates}
                    setFormStates={setFormStates}
                    setButtonHeight={setButtonHeight}
                  />
                </div>
                <CancelFields
                  cancelState={cancelState}
                  cancellable={incidentable.cancellable}
                  setCancelState={setCancelState}
                />
                <Footer incidentable={incidentable} formStates={formStates} />
              </form>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default IncidentGrid;
