import React, { useContext } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { BehavioralDiscountFormContext } from 'contexts/BehavioralDiscountToolContext';

const EligibleForVouchersSection = ({ classes }) => {
  const { formState, actions } = useContext(BehavioralDiscountFormContext);

  const onChange = () => {
    if (formState.eligibleForVouchers) {
      actions.setFormState('eligibleForVouchers', false);
    } else {
      actions.setFormState('eligibleForVouchers', true);
    }
  };

  return (
    <div className={classes.root}>
      <h6 className={classes.header}>Vouchers</h6>
      <FormControlLabel
        className={classes.toggle}
        control={<Switch checked={formState.eligibleForVouchers} onChange={onChange} />}
        label="Eligible For Vouchers"
      />
    </div>
  );
};

export default EligibleForVouchersSection;
