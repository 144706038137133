import React from 'react';
import Button from '@mui/material/Button';

const SharedButton = ({
  className = '',
  handleOnClick = () => {},
  buttonText = '',
  variant = 'text',
  color = 'success',
  disabled = false,
  value = '',
  type = 'button',
}) => {
  return (
    <Button
      color={color}
      variant={variant}
      className={className}
      onClick={handleOnClick}
      disabled={disabled}
      value={value}
      type={type}
    >
      {buttonText}
    </Button>
  );
};

export default SharedButton;
