import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useAllowed from 'lib/useAllowed';
import { calculateIngredientAssignmentTolerances } from 'lib/helpers/mealEditForm/';

// Material Components
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';

import IngredientSearch from './IngredientSearch';
import IngredientFormInputs from '../../shared/IngredientFormInputs/IngredientFormInputs';
import IngredientDropdowns from '../../shared/IngredientDropdowns/IngredientDropdowns';
import IngredientCheckboxes from './IngredientCheckboxes';
import PackingFacility from './PackingFacility';
import SectionHeader from '../shared/SectionHeader';

const ReplacementIngredientCard = ({
  classes,
  formState,
  handleFormState,
  ingredientAssignmentIndex,
  replacementIngredientIndex,
  replacementIngredient,
  originalIngredientAssignmentName = '',
  rthProductType,
}) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);

  const [replacementIngredientSelected, setReplacementIngredientSelected] = useState(
    replacementIngredient.ingredient
  );
  const [replacementIngredientState, setReplacementIngredientState] =
    useState(replacementIngredient);
  const [isFirstRun, setIsFirstRun] = useState(true);
  const canEditReplacements = useAllowed('editReplacements');

  const defaultCheckboxState = {
    labelRequired:
      replacementIngredient?.labelRequired || replacementIngredientSelected?.labelRequired || false,
    readyToEat:
      replacementIngredient?.readyToEat || replacementIngredientSelected?.readyToEat || false,
    mustCook: replacementIngredientSelected?.mustCook || false,
    pickable: replacementIngredientSelected?.pickable || false,
    largeIngredient: replacementIngredientSelected?.largeIngredient || false,
  };
  const [checkboxState, setCheckboxState] = useState(defaultCheckboxState);

  const updateFormState = (newReplacementIngredientAssignmentState) => {
    setReplacementIngredientState(newReplacementIngredientAssignmentState);
    const newFormStateIngredientAssignments = [...formState.ingredientAssignments];

    newFormStateIngredientAssignments[ingredientAssignmentIndex].replacementIngredients[
      replacementIngredientIndex
    ] = newReplacementIngredientAssignmentState;

    handleFormState({
      target: { name: 'ingredientAssignments', value: newFormStateIngredientAssignments },
    });
  };

  const handleReplacementIngredientAssignmentState = (name, value) => {
    if (canEditReplacements) {
      const newReplacementIngredientAssignmentState = {
        ...replacementIngredientState,
        [name]: value,
      };
      updateFormState(newReplacementIngredientAssignmentState);
    }
  };

  const handleCheckboxChange = (event) => {
    const newCheckboxState = { ...checkboxState, [event.target.name]: event.target.checked };
    setCheckboxState(newCheckboxState);
    handleReplacementIngredientAssignmentState(event.target.name, event.target.checked);
  };

  const handleDelete = () => {
    handleReplacementIngredientAssignmentState('deleted', true);
  };

  const onAttributeChange = (event) => {
    handleReplacementIngredientAssignmentState(event.target.name, event.target.value);
  };

  const resetTolerances = () => {
    const { lowTolerance, highTolerance } = calculateIngredientAssignmentTolerances(
      rthProductType,
      replacementIngredientState.quantity,
      replacementIngredientSelected?.lowTolerance,
      replacementIngredientSelected?.highTolerance
    );

    const newReplacementIngredientAssignmentState = {
      ...replacementIngredientState,
      lowTolerance,
      highTolerance,
    };

    updateFormState(newReplacementIngredientAssignmentState);
  };

  useEffect(() => {
    if (isFirstRun) {
      setIsFirstRun(false);
    }

    setReplacementIngredientState((currentReplacementIngredientAssignmentState) => {
      return {
        ...currentReplacementIngredientAssignmentState,
        mustCook: currentReplacementIngredientAssignmentState.ingredient?.mustCook || false,
        pickable: currentReplacementIngredientAssignmentState.ingredient?.pickable || false,
        largeIngredient:
          currentReplacementIngredientAssignmentState.ingredient?.largeIngredient || false,
        originalIngredient: false,
      };
    });
  }, [isFirstRun]);

  useEffect(() => {
    if (!isFirstRun) {
      const newFormStateIngredientAssignments = [...formState.ingredientAssignments];
      newFormStateIngredientAssignments[ingredientAssignmentIndex].replacementIngredients[
        replacementIngredientIndex
      ] = replacementIngredientState;

      handleFormState({
        target: { name: 'ingredientAssignments', value: newFormStateIngredientAssignments },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replacementIngredientState.ingredientId]);

  useEffect(() => {
    // When changing or clearing a replacement's base ingredient, return blank replacement
    if (
      (replacementIngredientSelected && replacementIngredientState.originalIngredient === false) ||
      !replacementIngredientSelected
    ) {
      setReplacementIngredientState((currentReplacementIngredientAssignmentState) => {
        return {
          ...currentReplacementIngredientAssignmentState,
          ingredientId: replacementIngredientSelected?.id,
          ingredientPackagingId: replacementIngredientSelected?.packaging,
          labelRequired: replacementIngredientSelected?.labelRequired,
          largeIngredient: replacementIngredientSelected?.largeIngredient,
          measure: replacementIngredientSelected?.standardUnit,
          mustCook: replacementIngredientSelected?.mustCook,
          name: replacementIngredientSelected?.displayName,
          opsCategory: replacementIngredientSelected?.opsCategory,
          pickable: replacementIngredientSelected?.pickable,
          portionMethod: replacementIngredientSelected?.portionMethod,
          productionSheetNotes: '',
          usageYield: replacementIngredientSelected?.usageYield,
          readyToEat: false,
        };
      });
    }

    if (!isFirstRun && replacementIngredientState.ingredientId) {
      resetTolerances();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replacementIngredientSelected]);

  useEffect(() => {
    if (!isFirstRun) {
      resetTolerances();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replacementIngredientState.quantity]);

  /**
   * Sets the replacementIngredientSelected to the ingredient selected by the user.
   *
   * Autocomplete onChange takes in two params: event and values.
   * In this case values is the value of the selected ingredient.
   * @function onIngredientChange
   */
  const onIngredientChange = (_event, values) => {
    setReplacementIngredientSelected(values);
  };

  return (
    <>
      <hr className={classes.hr} />
      <Grid container>
        <SectionHeader text={`Replacement for: ${originalIngredientAssignmentName}`} />
        <Grid container direction="row">
          <PackingFacility
            classes={classes}
            formOptions={formOptions}
            replacementIngredientState={replacementIngredientState}
            onAttributeChange={handleReplacementIngredientAssignmentState}
          />
        </Grid>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <IngredientSearch
              classes={classes}
              autocompleteOnChange={onIngredientChange}
              ingredientSelected={replacementIngredientSelected}
              includeShortsAndReplacements
              name="ingredient"
              label="Ingredient"
              rthProductType={rthProductType}
              isReplacement
            />
          </Grid>
        </Grid>
        <IngredientFormInputs
          ingredientAssignmentState={replacementIngredientState}
          ingredientSelected={replacementIngredientSelected}
          onAttributeChange={onAttributeChange}
        />
        <IngredientDropdowns
          ingredientAssignmentState={replacementIngredientState}
          ingredientSelected={replacementIngredientSelected}
          handleIngredientAssignmentState={handleReplacementIngredientAssignmentState}
          onAttributeChange={onAttributeChange}
          formOptions={formOptions}
        />
        <Grid container direction="row" className={classes.replacementBottomRow}>
          <Grid item>
            <IngredientCheckboxes
              classes={classes}
              ingredientAssignmentState={replacementIngredientState}
              handleCheckboxChange={handleCheckboxChange}
              formOptions={formOptions}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              className={classes.replacmentDeleteButton}
              startIcon={<DeleteIcon />}
              onClick={handleDelete}
            >
              Remove Replacement Ingredient
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ReplacementIngredientCard;
