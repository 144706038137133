// Dependencies
import React from 'react';
import clsx from 'clsx';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const ClassificationRow = ({ viewableMeals, classes }) => {
  return (
    <TableRow>
      <TableCell className={classes.iconCell} />
      <TableCell className={classes.rowLabel}>Classification</TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`classification.${meal.versionId}`}
          className={clsx(meal.inactive && classes.greyed)}
        >
          <div>
            <span className={classes.listLabel}>Channel</span>
            <span className={classes.listContent}>{meal.classification.channel}</span>
          </div>
          <div>
            <span className={classes.listLabel}>Plan</span>
            <span className={classes.listContent}>{meal.classification.plan}</span>
          </div>
          <div>
            <span className={classes.listLabel}>Meal Plan</span>
            <span className={classes.listContent}>{meal.classification.mealPlan}</span>
          </div>
          <div>
            <span className={classes.listLabel}>Status</span>
            <span className={classes.listContent}>{meal.classification.status}</span>
          </div>
          <div>
            <span className={classes.listLabel}>Servings</span>
            <span className={classes.listContent}>{meal.classification.servings}</span>
          </div>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default ClassificationRow;
