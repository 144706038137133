import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { LIGHT_BLACK } from 'lib/constants';
import { useListContext } from 'react-admin';

const StateCountCard = ({ title, value, count }) => {
  const { setFilters } = useListContext();

  return (
    <Card>
      <CardContent>
        <Typography
          sx={{ fontSize: 16, fontWeight: 600, textTransform: 'uppercase' }}
          color={LIGHT_BLACK}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography sx={{ fontSize: 64, fontWeight: 700 }} color={LIGHT_BLACK}>
          {count}
        </Typography>
        <CardActions>
          <Button
            sx={{ fontSize: 14, padding: 0 }}
            size="small"
            onClick={() => setFilters({ state: value })}
          >
            Show {title}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default StateCountCard;
