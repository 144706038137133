import React from 'react';
import { Link } from 'react-router-dom';
import { format, fromUnixTime } from 'date-fns';

const MenuHeaderItem = ({ displayGroup, menu, classes }) => {
  return (
    <Link to={`/menugrid/${displayGroup}/${menu.id}`}>
      <span className={classes.menuId}>{menu.id}</span>
      <span className={classes.endsAtDate}>
        {format(fromUnixTime(menu.endsAt), 'LLL')} {format(fromUnixTime(menu.endsAt), 'd')}
      </span>
    </Link>
  );
};

export default MenuHeaderItem;
