// Dependencies
import React, { useState } from 'react';
import { mostRecentMenu, extraMenus, noMenus, formattedMenu } from 'lib/helpers/comparisonTool';
import clsx from 'clsx';

// Material
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

const MenusRow = ({ viewableMeals, classes }) => {
  // State methods
  const [openCollapsible, setOpenCollapsible] = useState(false);

  const handleCollapsibleClick = () => {
    setOpenCollapsible(!openCollapsible);
  };

  // Render helpers
  const extraMenusSpan = (meal) => {
    const menusLength = extraMenus(meal).length;

    if (menusLength > 0) {
      return <span className={classes.extraMenusLabel}>+ {menusLength} more</span>;
    }

    return null;
  };

  const collapsibleMenus = (meal) => {
    return (
      <Collapse in={openCollapsible} unmountOnExit className={classes.collapse}>
        {extraMenus(meal).map((menu) => (
          <div key={`${menu.type}-${menu.id}`} className={classes.collapsibleMenuInfo}>
            <div>{menu.displayGroup}</div>
            <div>{formattedMenu(menu)}</div>
          </div>
        ))}
      </Collapse>
    );
  };

  const showMenus = (meal) => {
    return (
      <div>
        <div className={classes.firstMenuInfo}>
          <div>{mostRecentMenu(meal).displayGroup}</div>
          <div>{formattedMenu(mostRecentMenu(meal))}</div>
        </div>
        {extraMenusSpan(meal)}
        {collapsibleMenus(meal)}
      </div>
    );
  };

  return (
    <TableRow>
      <TableCell className={classes.iconCell} onClick={handleCollapsibleClick}>
        {openCollapsible ? (
          <ExpandMore className={classes.icon} />
        ) : (
          <ChevronRightIcon className={classes.icon} />
        )}
      </TableCell>
      <TableCell className={classes.rowLabel} onClick={handleCollapsibleClick}>
        Menu
      </TableCell>
      {viewableMeals.map((meal) => (
        <TableCell
          key={`menus.${meal.versionId}`}
          className={clsx(meal.inactive && classes.greyed)}
        >
          {noMenus(meal) ? <div>- - -</div> : showMenus(meal)}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default MenusRow;
