// Dependencies
import React from 'react';

// Components
import SearchField from './SearchField';
import SearchResults from './SearchResults';
import AssignmentFields from './AssignmentFields';
import Error from './Error';

const SwapInIngredientSelector = ({ swapInIngredient = null, swapInIngredientError = false }) => {
  return (
    <>
      <SearchField />
      {swapInIngredient.loaded && <SearchResults swapInIngredient={swapInIngredient} />}
      {swapInIngredient.loaded && <AssignmentFields swapInIngredient={swapInIngredient} />}
      {swapInIngredientError && <Error />}
    </>
  );
};

export default SwapInIngredientSelector;
