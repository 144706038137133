import React from 'react';
import { useSelector } from 'react-redux';
import { DISABLED_CHECKBOXES } from 'lib/constants';

// Material Components
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';

const IngredientCheckboxes = ({
  ingredientAssignmentState,
  handleCheckboxChange,
  classes = null,
  disabledState = false,
}) => {
  const { formOptions } = useSelector((state) => state.mealEditForm.formOptions);
  const renderCheckboxes = (checkbox) => {
    return (
      <Grid item key={checkbox.value}>
        <Tooltip
          title={`${checkbox.label} is controlled by the ingredient and cannot be changed here`}
          className={classes.tooltip}
          PopperProps={{
            sx: DISABLED_CHECKBOXES.includes(checkbox.value) || { display: 'none' },
          }}
          arrow
        >
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                checked={ingredientAssignmentState[checkbox.value] || false}
                disableRipple
                name={checkbox.value}
                onChange={handleCheckboxChange}
                // These values are always disabled because they come from the ingredient and thus cannot be changed here
                disabled={disabledState || DISABLED_CHECKBOXES.includes(checkbox.value)}
              />
            }
            label={<span className={classes.inputLabel}>{checkbox.label}</span>}
          />
        </Tooltip>
      </Grid>
    );
  };

  return (
    <Grid container direction="row" spacing={1}>
      {formOptions.ingredientCheckboxes.map((obj) => renderCheckboxes(obj))}
    </Grid>
  );
};

export default IngredientCheckboxes;
