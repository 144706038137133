import React from 'react';
import { Chip } from '@mui/material';

import { MEDIUM_GREY } from 'lib/constants';

const CustomFilterInput = ({ labelText }) => {
  return (
    <Chip
      label={labelText}
      variant="filled"
      sx={{
        width: '80px',
        height: '25px',
        paddingBottom: '12px',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        color: MEDIUM_GREY,
        fontSize: '16px',
        '& .MuiChip-label': {
          display: 'block',
          whiteSpace: 'normal',
        },
      }}
    />
  );
};

export default CustomFilterInput;
