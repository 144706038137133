import React, { useState } from 'react';

import NetsuiteAutocomplete from 'components/shared/NetsuiteAutocomplete';
import { APP_API_URI, NETSUITE_TYPE_LINE_OF_BUSINESS } from 'lib/constants';

import { Button, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { POST } from 'lib/api';

const AddCategoryForm = ({ classes, getData, handleClose, setJobId, setSnackState }) => {
  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const [categoryInternalId, setCategoryInternalId] = useState(null);

  const closeDrawer = () => {
    setCategoryName('');
    setCategoryDescription('');
    setCategoryInternalId(null);
    handleClose();
  };

  const submitForm = async () => {
    await POST(`${APP_API_URI}/internal/retail/category/save_category`, {
      name: categoryName,
      description: categoryDescription,
      netsuite_internal_id: categoryInternalId,
    })
      .then((response) => {
        setJobId(response.data.retailCategory.updateAssortmentsJobId);
        setSnackState({
          message: `Creating ${categoryName} and related assortments - Check back later!`,
          severity: 'success',
        });
        closeDrawer();
        getData();
      })
      .catch(() =>
        setSnackState({
          message: `Error creating ${categoryName} - try again or contact tech for help`,
          severity: 'error',
        })
      );
  };

  return (
    <Stack justifyContent="space-between" spacing={3}>
      <Typography variant="h5" classes={classes.titleBar}>
        Add New Product Line
        <Button onClick={closeDrawer}>
          <CloseIcon />
        </Button>
      </Typography>
      <TextField
        label="Product Line Info"
        variant="outlined"
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
      />
      <TextField
        label="Description (optional)"
        variant="outlined"
        value={categoryDescription}
        onChange={(e) => setCategoryDescription(e.target.value)}
      />
      <NetsuiteAutocomplete
        type={NETSUITE_TYPE_LINE_OF_BUSINESS}
        value={categoryInternalId}
        onChange={(id) => setCategoryInternalId(id)}
      />
      <Button
        variant="contained"
        onClick={submitForm}
        disabled={categoryInternalId === null || categoryName.length === 0}
      >
        Save
      </Button>
    </Stack>
  );
};

export default AddCategoryForm;
