import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import isEmpty from 'lodash/isEmpty';
import AddIcon from '@mui/icons-material/Add';

const MealAllergensValidationDialog = ({
  allergens,
  handleNewlyIntroducedAllergens,
  handleReplacementIngredientSelected,
  handleReplacementState,
}) => {
  const openDialog = !isEmpty(allergens);

  const handleCancelClick = () => {
    handleReplacementIngredientSelected(null);
    handleReplacementState(null);
    handleNewlyIntroducedAllergens([]);
  };

  const handleProceedClick = () => {
    handleNewlyIntroducedAllergens([]);
  };

  return (
    <Dialog open={openDialog} onClose={handleCancelClick}>
      <DialogTitle>
        The replacement you are trying to create introduces the following allergens to the meal:
      </DialogTitle>
      <DialogContent>
        <ul>
          {allergens.map((allergenName) => (
            <li key={allergenName}>{allergenName}</li>
          ))}
        </ul>
        <DialogContentText>*Please validate with FSQA if this is allowed.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>Cancel</Button>
        <Button
          onClick={handleProceedClick}
          variant="contained"
          color="warning"
          startIcon={<AddIcon />}
        >
          Proceed with Replacement
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MealAllergensValidationDialog;
