import React, { useContext } from 'react';
import useAllowed from 'lib/useAllowed';

// Material Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import CompactCirasContainer from './CompactCirasContainer';
import { ACTIONS, ReplacementsTabContext } from '../reducer';

const CompactReplacementCiraCard = ({
  classes,
  cira,
  ciraIndex,
  replacementCira,
  replacementCiraIndex,
  render,
}) => {
  const { dispatch } = useContext(ReplacementsTabContext);

  const canEditReplacements = useAllowed('editReplacements');
  const replacementIsCompound = replacementCira.ingredient.compoundIngredientRecipeId != null;

  const handleEditReplacement = () => {
    dispatch({
      type: ACTIONS.HANDLE_EDIT_REPLACEMENT,
      payload: {
        selectedState: cira,
        selectedIndex: ciraIndex,
        replacementState: replacementCira,
        replacementIndex: replacementCiraIndex,
      },
    });
    dispatch({
      type: ACTIONS.UPDATE_EXPANDED_CARDS,
      payload: {
        ingredientId: cira.ingredient.id,
      },
    });
  };

  return (
    <Box elevation={3} className={classes.replacementCiraCardWrapper}>
      <Grid item xs={12} className={classes.replacementCiraCardContainer}>
        <div className={classes.replacementCardInfo}>
          <span className={classes.smallCaps}>REPLACEMENT</span>
          <br />
          <span className={classes.text}>
            <strong>{replacementCira.ingredient?.id}</strong> {replacementCira?.name} -{' '}
            {replacementCira.quantity} {replacementCira.measure}
          </span>
          {replacementIsCompound && <Chip label="COMPOUND" className={classes.compoundPill} />}
          <p className={classes.text}>
            {replacementCira.packingFacilityNames?.length > 0 && (
              <span>{replacementCira.packingFacilityNames.join(', ')}</span>
            )}
          </p>
        </div>
        {canEditReplacements && (
          <Button
            variant="outlined"
            className={classes.editReplacementCiraButton}
            onClick={() => handleEditReplacement()}
          >
            Edit
          </Button>
        )}
      </Grid>
      {replacementIsCompound && <CompactCirasContainer classes={classes} render={() => render()} />}
    </Box>
  );
};

export default CompactReplacementCiraCard;
