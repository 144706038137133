import useAllowed from 'lib/useAllowed';

const IfAllowed = ({ ability, children }) => {
  if (useAllowed(ability)) {
    return children;
  }
  return null;
};

export default IfAllowed;
