import React, { useContext, useEffect, useState } from 'react';
import useAllowed from 'lib/useAllowed';

// Material Components
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import CompactReplacementCard from './CompactReplacementCard';
import CompactCirasContainer from './CompactCirasContainer';

import { ACTIONS, ReplacementsTabContext } from '../reducer';
import CompactCiraCard from './CompactCiraCard';

const CompactIngredientCard = ({
  classes,
  ingredientAssignment,
  ingredientAssignmentIndex = 0,
}) => {
  const { state, dispatch } = useContext(ReplacementsTabContext);
  const { allCardsExpanded, expandedCards, collapsedCards } = state;
  const [expanded, setExpanded] = useState(
    allCardsExpanded
      ? !collapsedCards.includes(ingredientAssignment.ingredient.id)
      : expandedCards.includes(ingredientAssignment.ingredient.id)
  );

  const handleExpandCard = () => {
    dispatch({
      type: ACTIONS.UPDATE_EXPANDED_CARDS,
      payload: {
        expandUpdate: ingredientAssignment.ingredient.id,
      },
    });
  };

  useEffect(() => {
    setExpanded(
      allCardsExpanded
        ? !collapsedCards.includes(ingredientAssignment.ingredient.id)
        : expandedCards.includes(ingredientAssignment.ingredient.id)
    );
  }, [allCardsExpanded, expandedCards, collapsedCards, ingredientAssignment.ingredient.id]);

  const isCompound = ingredientAssignment.ingredient.compoundIngredientRecipeId != null;

  const numberOfReplacements = ingredientAssignment.replacementIngredients.filter(
    (replacement) => !('deleted' in replacement)
  ).length;

  const canEditReplacements = useAllowed('editReplacements');

  const handleAddReplacement = () => {
    dispatch({
      type: ACTIONS.HANDLE_ADD_REPLACEMENT,
      payload: {
        selectedState: ingredientAssignment,
        selectedIndex: ingredientAssignmentIndex,
      },
    });
    if (!expandedCards.includes(ingredientAssignment.ingredient.id)) {
      handleExpandCard();
    }
  };

  const renderReplacementCards = () =>
    ingredientAssignment.replacementIngredients.map((replacementIngredient, replacementIndex) => {
      const key = `replacement-ingredient-${replacementIndex}`;
      return replacementIngredient.deleted ? null : (
        <CompactReplacementCard
          key={key}
          classes={classes}
          ingredientAssignment={ingredientAssignment}
          ingredientAssignmentIndex={ingredientAssignmentIndex}
          replacementIngredientAssignment={replacementIngredient}
          replacementIndex={replacementIndex}
        />
      );
    });

  return (
    <Paper elevation={3} className={classes.ingredientCardContainer}>
      <div className={classes.ingredientInfo}>
        <div>
          <div className={classes.ingredientInfoTopRow}>
            <span className={classes.text}>
              <strong>{ingredientAssignment.ingredient.id}</strong> {ingredientAssignment.name}
              {' - '}
              {ingredientAssignment.quantity} {ingredientAssignment.measure}
            </span>
            {isCompound && <Chip label="COMPOUND" className={classes.compoundPill} />}
          </div>
          {numberOfReplacements > 0 && (
            <p className={classes.text}>
              <span className={classes.ingredientReplacementInfo}>
                + {numberOfReplacements} Replacements
              </span>
            </p>
          )}
        </div>
        <div>
          {canEditReplacements && (
            <Button
              variant="outlined"
              className={classes.replacementIngredientButton}
              onClick={handleAddReplacement}
              startIcon={<AddIcon />}
            >
              Add Replacement
            </Button>
          )}
          {(numberOfReplacements > 0 || isCompound) && (
            <Button onClick={() => handleExpandCard()}>
              {expanded ? <ExpandLess /> : <ChevronRightIcon />}
            </Button>
          )}
        </div>
      </div>
      {numberOfReplacements > 0 && (
        <Collapse in={expanded}>
          <Grid item xs={12} className={classes.replacementsContainer}>
            {renderReplacementCards()}
          </Grid>
        </Collapse>
      )}
      {isCompound && (
        <Collapse in={expanded}>
          <CompactCirasContainer
            classes={classes}
            render={() => {
              return ingredientAssignment.compoundIngredientRecipeAssignments?.map(
                (cira, ciraIndex) => {
                  const key = `cira-ia-${ciraIndex}`;
                  return cira.deleted ? null : (
                    <CompactCiraCard
                      classes={classes}
                      cira={cira}
                      ciraIndex={ciraIndex}
                      key={key}
                    />
                  );
                }
              );
            }}
          />
        </Collapse>
      )}
    </Paper>
  );
};

export default CompactIngredientCard;
