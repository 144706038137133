import * as Sentry from '@sentry/react';
import { pick } from 'lodash';

class Logger {
  static info(infoMsg) {
    console.log(infoMsg);
  }

  static error(err) {
    const error = new Error(
      JSON.stringify({
        ...pick(err, ['message', 'code']),
        ...pick(err.response, ['data', 'status', 'statusText']),
      })
    );

    if (
      (process === undefined || process.env.JEST_WORKER_ID === undefined) &&
      process.env.REACT_APP_ENABLE_LOGGING === 'false'
    ) {
      // Error log, unless we're running unit tests
      console.error(error);
    }

    // send the data to sentry
    if (process.env.REACT_APP_ENABLE_LOGGING === 'true') {
      const res = err?.response?.data;
      if (res?.errorType === 'validationError') return;

      Sentry.captureException(err, (scope) => {
        scope.setContext('Error Details', error);
      });
    }
  }
}

export default Logger;
