import React from 'react';

import Menu from './Menu';
import MenuSelector from './MenuSelector';
import AppHeader from '../shared/AppHeader';
import Snackbar from './Snackbar';

const MenuManager = ({ classes }) => {
  return (
    <>
      <AppHeader text="Retail Menus" />
      <div className={classes.root}>
        <MenuSelector />
        <Menu />
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} />
      </div>
    </>
  );
};

export default MenuManager;
