// Dependencies
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Material Components
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// Components
import { Loading } from 'components/shared';
import AppHeader from '../shared/AppHeader';
import BackToAdmin from '../shared/BackToAdmin';
import Header from './Header';
import SwapInIngredientSelector from './SwapInIngredientSelector';
import SwapOutIngredientMeals from './SwapOutIngredientMeals';
import SwapOutIdInput from './SwapOutIdInput';

const IngredientSwapper = ({
  onGetSwapOutIngredient,
  onGetSwapInIngredient,
  classes,
  swapInIngredient = null,
  swapOutIngredient = null,
}) => {
  // Helper methods
  const { swapOutIngredientId, swapInIngredientId } = useParams();

  const [formState, setFormState] = useState({
    showLiveMeals: false,
    showUnassignedMeals: false,
  });

  const handleFormState = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  // Lifecycle methods
  useEffect(() => {
    if (swapOutIngredientId !== undefined) {
      onGetSwapOutIngredient(swapOutIngredientId);
    }
  }, [onGetSwapOutIngredient, swapOutIngredientId]);

  useEffect(() => {
    if (swapInIngredientId !== undefined) {
      onGetSwapInIngredient(swapInIngredientId);
    }
  }, [onGetSwapInIngredient, swapInIngredientId]);

  // Rendering
  if (swapOutIngredient.fetching || swapInIngredient.fetching) {
    return <Loading />;
  }

  // `swapOutIngredientId === undefined` is equal to accessing the `ingredient-swapper/` url
  // without entering a `:swapOutIngredientId` param
  if (swapOutIngredientId === undefined || swapOutIngredient.error) {
    return (
      <>
        <AppHeader text="Swap Ingredients" />
        <SwapOutIdInput />;
      </>
    );
  }

  return (
    <>
      <AppHeader text="Swap Ingredients" />
      <div className={classes.root}>
        {swapOutIngredient.loaded && (
          <Container className={classes.container}>
            <BackToAdmin url={swapOutIngredient.adminIngredientLink} text="Back to Admin" />
            <Header swapOutIngredient={swapOutIngredient} />
          </Container>
        )}
      </div>
      <Paper variant="outlined" className={classes.paper}>
        <Container className={classes.container}>
          <Grid container>
            <Grid item xs={3} className={classes.ingredientSelectorBox}>
              {swapInIngredient.loaded ? (
                <SwapInIngredientSelector preloadedSwapInIngredient={swapInIngredient} />
              ) : (
                <SwapInIngredientSelector />
              )}
            </Grid>
            <Grid item xs={9} className={classes.ingredientMealsBox}>
              {swapOutIngredient.loaded && (
                <SwapOutIngredientMeals handleFormState={handleFormState} formState={formState} />
              )}
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};

export default IngredientSwapper;
