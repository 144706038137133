import { useQuery } from '@tanstack/react-query';
import { GET } from 'lib/api';
import { APP_API_URI } from 'lib/constants';

const getStateCountData = async () => {
  const response = await GET(`${APP_API_URI}/internal/hub/ingredients-state-count-data`);
  return response.data;
};

export const useStateCountData = () =>
  useQuery({ queryKey: ['stateCountData'], queryFn: getStateCountData });
