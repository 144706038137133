import React from 'react';
import { Layout } from 'react-admin';
import { HubAppBar } from './AppBar';
import { HubMenu } from './HubMenu';

const HubLayout = (props) => (
  <Layout menu={HubMenu} {...props} sx={{ marginTop: '12px' }} appBar={HubAppBar} />
);

export default HubLayout;
