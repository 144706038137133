import React, { useState } from 'react';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';

const IngredientCheckboxes = ({
  classes,
  handleFormState,
  ingredientAssignments,
  otherIngredient = null,
  required = false,
}) => {
  const [chosen, setChosen] = useState('');

  const handleChange = (event) => {
    const newChosen = chosen !== event.target.name ? event.target.name : '';

    setChosen(newChosen);
    if (newChosen === 'other') {
      handleFormState({ target: { name: 'ingredientId', value: otherIngredient.id.toString() } });
      handleFormState({ target: { name: 'ingredientAssignmentId', value: '' } });
    } else {
      handleFormState({ target: { name: 'ingredientId', value: '' } });
      handleFormState({ target: { name: 'ingredientAssignmentId', value: newChosen } });
    }
  };

  const error = required && !chosen;

  return (
    <FormControl
      required={required}
      error={error}
      component="fieldset"
      sx={{ m: 3 }}
      variant="standard"
    >
      <FormLabel className={classes.inputLabel}>
        {required ? 'Ingredient (required)' : 'Ingredient (optional)'}
      </FormLabel>
      <FormGroup>
        <Grid className={classes.inner} container spacing={0}>
          {ingredientAssignments.map((ingredient) => {
            const ingredientAssignmentId = ingredient.id.toString();
            return (
              <Grid item key={ingredient.id} xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={chosen === ingredientAssignmentId}
                      onChange={handleChange}
                      name={ingredientAssignmentId}
                    />
                  }
                  label={ingredient.name}
                />
              </Grid>
            );
          })}
          {otherIngredient && (
            <Grid item key={otherIngredient.id} xs={6}>
              <FormControlLabel
                control={
                  <Checkbox checked={chosen === 'other'} onChange={handleChange} name="other" />
                }
                label={otherIngredient.name}
              />
            </Grid>
          )}
        </Grid>
      </FormGroup>
    </FormControl>
  );
};

export default IngredientCheckboxes;
