import React from 'react';
import { replace, upperFirst } from 'lodash';
import { DialogContentText, Link, Typography } from '@mui/material';
import styles from '../styles';
import Collapse from './Collapse';
import RelatedMealTabs from './RelatedMealTabs';

const OtherAssignableVersion = ({ otherAssignableVersion, success, compoundIngredientId }) => {
  return (
    <DialogContentText
      sx={styles.accordionDetails}
      key={otherAssignableVersion.id}
      component="span"
    >
      <Typography sx={styles.otherAssignableVersionHeader} component="span">
        <>
          {otherAssignableVersion.displayName}{' '}
          {success
            ? null
            : `| State: ${upperFirst(replace(otherAssignableVersion.state, '_', ' '))} |
          ${new Date(otherAssignableVersion.updatedAt).toDateString()}`}
        </>
        {success && (
          <Link
            href={`/ingredient-swapper/${otherAssignableVersion.id}/${compoundIngredientId}`}
            target="_blank"
            underline="none"
            sx={styles.otherAssignableVersionHeaderLink}
          >
            Swap Out
          </Link>
        )}
      </Typography>
      <Collapse collapseHeader="Related Meals" isParent={false}>
        <RelatedMealTabs relatedMeals={otherAssignableVersion.relatedMeals} />
      </Collapse>
    </DialogContentText>
  );
};

export default OtherAssignableVersion;
