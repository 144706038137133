import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removePlaceholderAndUpdate } from 'redux/menugrid/menus/actions';

import IconButton from '@mui/material/IconButton';

const PlaceholderInfo = ({ placeholder, displayGroup, classes }) => {
  const { dragging } = useSelector((state) => state.menugrid.menus);

  const dispatch = useDispatch();

  const handleRemovePlaceholder = (props) => {
    dispatch(removePlaceholderAndUpdate(props));
  };

  return (
    <div className={classes.root}>
      <span className={classes.order}>{placeholder.title}</span>
      {!dragging && (
        <IconButton
          aria-label="Close"
          className={classes.close}
          onClick={() =>
            handleRemovePlaceholder({
              id: placeholder.id,
              menuId: placeholder.menuId,
              menuType: placeholder.menuType,
              displayGroup,
            })
          }
          size="large"
        >
          x
        </IconButton>
      )}
    </div>
  );
};

export default PlaceholderInfo;
