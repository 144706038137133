import React from 'react';
import { Admin, Resource, memoryStore } from 'react-admin';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { APP_API_URI } from 'lib/constants';
import CategoryIcon from '@mui/icons-material/Category';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HubLayout from './admin/Layout';
import dataProvider from './admin/dataProvider';
import { hubAppTheme } from './admin/HubAppTheme';

// Resources
import CompoundIngredientDashboard from './admin/Resources/CompoundIngredients/CompoundIngredients';
import IngredientPantry from './admin/Resources/Ingredients/IngredientPantry';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // The time in milliseconds after data is considered stale.
      staleTime: 120000, // 2 minutes
      refetchOnWindowFocus: false,
    },
  },
});

const HubApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Admin
        basename="/hub"
        dataProvider={dataProvider(`${APP_API_URI}/internal/hub`)}
        layout={HubLayout}
        store={memoryStore()}
        theme={hubAppTheme}
      >
        <Resource
          name="compound-ingredients"
          list={CompoundIngredientDashboard}
          recordRepresentation="name"
          options={{ label: 'Compound Ingredients' }}
          icon={CategoryIcon}
        />
        <Resource
          name="ingredients"
          list={IngredientPantry}
          recordRepresentation="name"
          options={{ label: 'Ingredient Pantry' }}
          icon={ShoppingCartIcon}
        />
      </Admin>
    </QueryClientProvider>
  );
};

export default HubApp;
