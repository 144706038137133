// Dependencies
import React from 'react';

// Material
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import withStyles from '@mui/styles/withStyles';
import styles from './styles';

// Components
import MealListItem from './MealListItem';

const MealList = (props) => {
  const { handleCheck, handleToggle, meals, classes } = props;

  return (
    <List className={classes.list}>
      {meals.map((meal) => {
        const labelId = `checkbox-list-label-${meal.id}`;

        return (
          <ListItem className={classes.listItem} key={meal.id} disablePadding>
            <ListItemButton className={classes.listItemButton} onClick={handleToggle(meal)} dense>
              <Checkbox checked={handleCheck(meal)} disableRipple />
              <ListItemText id={labelId} primary={<MealListItem meal={meal} />} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default withStyles(styles)(MealList);
