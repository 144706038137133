import React, { createContext, useContext } from 'react';

import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';

export const AlertManagerContext = createContext(() => {
  throw new Error('Called addAlert outside of AlertManager component.');
});

export const useAddAlert = () => useContext(AlertManagerContext);

const AlertManager = ({ classes, children = null }) => {
  const addAlert = (alert) => {
    enqueueSnackbar(alert.message ? alert.message : alert, {
      variant: alert.severity ? alert.severity : null,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };

  return (
    <AlertManagerContext.Provider value={addAlert}>
      <SnackbarProvider classes={{ containerRoot: classes.container }} />
      {children}
    </AlertManagerContext.Provider>
  );
};

export default withStyles(styles)(AlertManager);
